import cn from 'classnames';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { memo, useEffect, useRef, useState } from 'react';
import { commonActions, commonSelectors, TabModel, Tabs, Text } from '@spotrisk/common';
import {
  CUSTOMER_STATE_TABS,
  CustomerListSummaryCard,
  CustomerListSummaryEmptyCard,
  customerResource,
  customerActions,
  customerSelectors,
  CustomerBlockedState,
} from '@spotrisk/customer';
import { merchantSelectors } from '@spotrisk/merchant';
import { orderActions } from '@spotrisk/order';

type Props = {
  className?: string;
};

export const CustomerList = memo(({ className }: Props) => {
  const classes = cn(className, 'w-1/2 mb-12');
  const dispatch = useDispatch();
  const ui = useSelector(commonSelectors.getUi);
  const merchant = useSelector(merchantSelectors.getMerchant);
  const merchantRef = useRef(merchant);
  const customers = useSelector(customerSelectors.getCustomers);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const queryCustomers = async (isBlocked?: boolean) => {
    if (!merchantRef.current) {
      setIsError(true);
      return;
    }

    setIsError(false);
    setIsLoading(true);

    const customers = await customerResource.queryCustomers({ merchant: merchantRef.current, isBlocked });
    dispatch(customerActions.setCustomers(customers));
  };

  useEffect(() => {
    merchantRef.current = merchant;
  }, [merchant]);

  useEffect(() => {
    (async () => {
      try {
        await queryCustomers(false);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  const handleTabChange = async ({ id: state }: TabModel<CustomerBlockedState>) => {
    try {
      dispatch(commonActions.setUi('saving'));
      await queryCustomers(state === 'BLOCKED');
    } catch {
      setIsError(true);
    } finally {
      dispatch(commonActions.setUi('ready'));
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isError) {
      return <Text>Something went wrong</Text>;
    }

    if (isLoading) {
      return null;
    }

    return (
      <>
        {customers.length ? (
          map(customers, (customer) => <CustomerListSummaryCard key={customer.id} customer={customer} isDisabled={ui === 'saving'} />)
        ) : (
          <CustomerListSummaryEmptyCard isDisabled={ui === 'saving'} />
        )}
      </>
    );
  };

  return (
    <div className={classes}>
      <Tabs<CustomerBlockedState> className="mb-6" tabs={CUSTOMER_STATE_TABS} onChange={handleTabChange} isDisabled={ui === 'saving'} />
      {renderContent()}
    </div>
  );
});
