import { billingSelectors } from '@spotrisk/billing';
import { Loader, Text } from '@spotrisk/common';
import { CustomRuleModel, customRuleSelectors, customRuleResource, customRuleActions } from '@spotrisk/custom-rule';
import { merchantSelectors } from '@spotrisk/merchant';
import { CustomRules, CustomRuleLists, settingsSelectors } from '@spotrisk/settings';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const CustomRulesPage = memo(() => {
  const dispatch = useDispatch();

  const billing = useSelector(billingSelectors.getBilling);
  const customRules = useSelector(customRuleSelectors.getCustomRules);
  const customRuleLists = useSelector(customRuleSelectors.getCustomRuleLists);
  const customRuleStatus = useSelector(customRuleSelectors.getStatus);
  const merchant = useSelector(merchantSelectors.getMerchant);
  const settings = useSelector(settingsSelectors.getSettings);

  useEffect(() => {
    if (customRules.length || !merchant) {
      return;
    }

    (async () => {
      dispatch(customRuleActions.getCustomRules());

      const getRulesPromise = customRuleResource.getCustomRules({ merchantId: merchant.id });
      const getRuleListsPromise = customRuleResource.getCustomRuleLists({ merchantId: merchant.id });

      dispatch(customRuleActions.setCustomRuleLists(await getRuleListsPromise));
      dispatch(customRuleActions.setCustomRules(await getRulesPromise));
    })();
  }, [customRules]);

  if (!merchant || !settings || !billing) {
    return null;
  }

  const updateCustomRule = async (customRule: CustomRuleModel) => {
    dispatch(customRuleActions.updateCustomRule(customRule));
    await customRuleResource.updateCustomRule({ merchantId: merchant.id, customRule });
  };

  return (
    <div className="w-full">
      <div>
        <div>
          <Text className="text-xl text-header font-bold mr-2 antialiased block tracking-wide">Advanced Rules</Text>
          <Text className="text-xs font-header text-header font-light block mt-2 mb-5">
            Configure the advanced rules that apply when orders are processed.
          </Text>
        </div>
        {customRuleStatus === 'loading' ? (
          <Loader />
        ) : (
          <CustomRules customRules={customRules} updateCustomRule={updateCustomRule} billing={billing} />
        )}
      </div>
      <div className="mt-12">
        <div>
          <Text className="text-2xl text-header font-bold mr-2 antialiased block tracking-wide">Block Lists</Text>
          <Text className="text-xs font-header text-header font-light block mt-2 mb-5">Configure block lists when processing orders.</Text>
        </div>
        {customRuleStatus === 'loading' ? <Loader /> : <CustomRuleLists customRuleLists={customRuleLists} merchant={merchant} billing={billing} />}
      </div>
      <div className="mt-6">
        <Text className="text-sm text-header text-header font-light">
          Read more about how Spotrisk&apos;s Advanced Rules work{' '}
          <a className="tulip" href="https://intercom.help/spotrisk/en/articles/6783589-custom-rules" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Text>
      </div>
    </div>
  );
});
