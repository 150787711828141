import React, { memo } from 'react';
import { Page, PageContent } from '@spotrisk/common';
import { Text } from '../components';
import { analytics } from '@spotrisk/common';

export const MobileErrorPage = memo(() => {
  return (
    <Page>
      <PageContent className="min-h-screen">
        <div className="flex max-w-screen-xl mx-auto">
          <div className="w-full">
            <Text className="text-5xl font-header mb-8" variant="primary" isBlock>
              Please log in using a desktop or tablet device.
            </Text>

            <Text className="text-2xl mt-5" variant="primary" isBlock>
              Where you can manage your account settings, integrations and more.
            </Text>
          </div>
        </div>
      </PageContent>
    </Page>
  );
});
