import { commonActions, commonSelectors, featureService, Loader, TabModel, Tabs, Text, useInterval } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import {
  orderActions,
  OrderListSummaryCard,
  OrderListSummaryEmptyCard,
  OrderReducerSort,
  orderResource,
  orderSelectors,
  OrderState,
  ORDER_STATE_TABS,
} from '@spotrisk/order';
import cn from 'classnames';
import { map } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Option, SortDropdown } from '../../common/components/dropdown/sort-dropdown.component';

// 10 seconds
const ORDER_REFRESH_INTERVAL = 10 * 3000;
const TAB_BASE_CLASSES = ['relative', 'flex', 'items-center', 'mb-6'];

export const OrderList = memo(() => {
  const tabClasses = cn(TAB_BASE_CLASSES);
  const dispatch = useDispatch();
  const ui = useSelector(commonSelectors.getUi);
  const orders = useSelector(orderSelectors.orders);
  const orderSearch = useSelector(orderSelectors.search);
  const orderState = useSelector(orderSelectors.state) || 'FLAGGED';
  const isLoadingOrders = useSelector(orderSelectors.isLoading);
  const merchant = useSelector(merchantSelectors.getMerchant);
  const merchantRef = useRef(merchant);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useInterval(() => longPollQueryOrders(orderState), ORDER_REFRESH_INTERVAL);

  const longPollQueryOrders = async (state: OrderState) => {
    if (orderSearch || !featureService.isEnabled('ff-application-long-poll')) {
      return;
    }

    await queryOrders(state, true);
  };

  const queryOrders = async (state: OrderState, isBackgroundRefresh?: boolean) => {
    if (!merchantRef.current) {
      setIsError(true);
      return;
    }

    setIsError(false);

    if (!isBackgroundRefresh) {
      dispatch(orderActions.getOrders());
    }

    const orderResponse = await orderResource.listOrders({ merchant: merchantRef.current, state });
    dispatch(orderActions.setOrders(orderResponse));
  };

  useEffect(() => {
    merchantRef.current = merchant;
  }, [merchant]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await queryOrders(orderState);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  const handleTabChange = async ({ id: state }: TabModel<OrderState>) => {
    dispatch(orderActions.setState(state));
    try {
      setIsLoading(true);
      dispatch(commonActions.setUi('saving'));
      await queryOrders(state);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
      dispatch(commonActions.setUi('ready'));
    }
  };

  const renderContent = () => {
    if (isError) {
      return <Text>Something went wrong</Text>;
    }

    if (isLoading || isLoadingOrders) {
      return <Loader className="h-48" />;
    }

    return (
      <>
        {orders.length ? (
          map(orders, (order) => <OrderListSummaryCard key={order.id} order={order} isDisabled={ui === 'saving'} />)
        ) : (
          <OrderListSummaryEmptyCard isDisabled={ui === 'saving'} />
        )}
      </>
    );
  };

  return (
    <>
      <div className={tabClasses}>
        <Tabs<OrderState> className="w-full" tabs={ORDER_STATE_TABS} onChange={handleTabChange} isDisabled={ui === 'saving'} />
        <SortDropdown
          onSelect={(option: Option) => dispatch(orderActions.setSort(option.code as OrderReducerSort))}
          options={[
            {
              label: 'Latest',
              code: 'latest',
            },
            {
              label: 'Oldest',
              code: 'oldest',
            },
          ]}
        />
      </div>
      {renderContent()}
      <div>
        <Text className="text-xs font-header font-light opacity-40 italic text-center block mt-5">
          Something doesn&apos;t look right? Get in touch with us at help@spotriskhq.com.
        </Text>
      </div>
    </>
  );
});
