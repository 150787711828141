import React, { ReactNode, useRef, MutableRefObject, memo, useCallback, useEffect } from 'react';
import cn from 'classnames';

type Props = {
  children: ReactNode;
  className?: string;
  id?: string;
  isOpen?: boolean;
  onClickOutside?: () => void;
  onClose?: () => void;
  toggleRef?: MutableRefObject<any>;
};

const BASE_CLASSES = ['bg-white', 'z-dropdown', 'absolute', 'rounded-md', 'shadow-lg', 'border', 'border-gray-300'];

export const Dropdown = memo(({ id, children, className, isOpen, onClickOutside, onClose, toggleRef }: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const classes = cn(className, BASE_CLASSES, {
    visible: isOpen,
    hidden: !isOpen,
  });

  const handleClickOutside = useCallback(({ target }: MouseEvent | TouchEvent) => {
    if (!dropdownRef.current || dropdownRef.current.contains(target as any) || (toggleRef?.current && toggleRef.current.contains(target))) {
      return;
    }

    if (onClickOutside) {
      onClickOutside();
    }
  }, []);

  const handleKeydown = useCallback(({ code }: KeyboardEvent) => {
    if (code === 'Escape' && onClose) {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    window.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [dropdownRef, onClickOutside, toggleRef]);

  return (
    <div id={id} className={classes} ref={dropdownRef} hidden={!isOpen}>
      {children}
    </div>
  );
});
