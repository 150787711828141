import { sumBy } from 'lodash';
import { useSelector } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { merchantSelectors } from '@spotrisk/merchant';
import { OrderListModel, orderResource } from '@spotrisk/order';
import { Text, TextSkeleton, utilService } from '@spotrisk/common';

export const OrderListSummary = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [isLoading, setIsLoading] = useState(true);
  const [resolvedOrders, setResolvedOrders] = useState<OrderListModel[]>([]);
  const [passedOrders, setPassedOrders] = useState<OrderListModel[]>([]);
  useEffect(() => {
    (async () => {
      try {
        if (!merchant) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const resolvedOrders = await orderResource.listOrders({ merchant, state: 'RESOLVED' });
        const passedOrders = await orderResource.listOrders({ merchant, state: 'PASSED' });

        setResolvedOrders(resolvedOrders);
        setPassedOrders(passedOrders);

        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  return (
    <div id="order-list-summary" className="mt-16">
      <Text className="text-xs font-header  uppercase opacity-40" variant="primary" isBlock>
        Today
      </Text>
      <div className="flex mt-8">
        <div className="w-1/2 mr-8">
          <div className="mt-0">
            {isLoading ? (
              <TextSkeleton className="h-6 w-5" variant="primary" />
            ) : (
              <Text className="text-2xl font-semibold" variant="primary" isBlock>
                {resolvedOrders.length}
              </Text>
            )}
            <Text className="font-medium opacity-40 mt-2" variant="primary" isBlock>
              orders resolved
            </Text>
          </div>
          <div className="mt-8">
            {isLoading ? (
              <TextSkeleton className="h-6 w-5" variant="primary" />
            ) : (
              <Text className="text-2xl font-semibold" variant="primary" isBlock>
                {passedOrders.length}
              </Text>
            )}
            <Text className="font-medium opacity-40 mt-2" variant="primary" isBlock>
              orders analysed
            </Text>
          </div>
        </div>
        <div className="w-1/2">
          {isLoading ? (
            <TextSkeleton className="h-6 w-14" variant="primary" />
          ) : (
            <Text className="text-2xl font-semibold" variant="primary" isBlock>
              {utilService.formatCurrencyAmount(sumBy(resolvedOrders, ({ totalPrice }) => totalPrice))}
            </Text>
          )}
          <Text className="font-medium opacity-40 mt-2" variant="primary" isBlock>
            potential fraud
          </Text>
          <Text className="font-medium opacity-40" variant="primary" isBlock>
            prevented
          </Text>
        </div>
      </div>
    </div>
  );
});
