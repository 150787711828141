import React, { memo } from 'react';
import { Page, PageContent } from '@spotrisk/common';
import { Link } from 'react-router-dom';

export const FourOhFourPage = memo(() => (
  <Page>
    <PageContent className="min-h-screen" hasHeader={false} isCentered>
      <img
        src="https://cdn.spotriskhq.com/cdn/assets/public/img/illustration-Spot-badge-friendly.png"
        className="w-15 mb-10 mx-auto"
        alt="Error illustration"
      />
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <p>If you're expecting something here, let our support team know:</p>
      <a href="mailto:support@spotriskhq.com">support@spotriskhq.com</a>
      <Link to="/">Go back to the homepage</Link>
    </PageContent>
  </Page>
));
