import React, { memo } from 'react';
import { UsersView } from '@spotrisk/settings';

export const UsersPage = memo(() => {
  return (
    <>
      <div className="w-full">
        <UsersView />
      </div>
    </>
  );
});
