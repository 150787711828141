import { MerchantModel } from '@spotrisk/merchant';

export type MerchantReducerState = {
  merchants: MerchantModel[];
  merchant: MerchantModel | null;
};

export const SET_MERCHANTS = 'merchant|SET_MERCHANTS' as const;
export const SET_MERCHANT = 'merchant|SET_MERCHANT' as const;

type SetMerchantsAction = {
  type: typeof SET_MERCHANTS;
  merchants: MerchantModel[];
};

type SetMerchantAction = {
  type: typeof SET_MERCHANT;
  merchant: MerchantModel | null;
};

export type MerchantActionTypes = SetMerchantsAction | SetMerchantAction;
