import { replace } from 'lodash';

type GetDisplayAddressParam = {
  city?: string;
  country?: string;
};

class UtilService {
  getDisplayAddress = ({ city, country }: GetDisplayAddressParam = {}) => {
    if (city && country) {
      return `${city}, ${country}`;
    } else if (country) {
      return country;
    } else {
      return '–';
    }
  };

  getDisplayDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(date);
  };

  formatCurrencyAmount = (amount: number, currency?: string | null) => {
    const formattedAmount = Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'USD' }).format(amount);

    if (currency === 'NZD') {
      return replace(formattedAmount, 'NZ', '');
    }

    if (currency === 'AUD') {
      return replace(formattedAmount, 'A', '');
    }

    return formattedAmount;
  };
}

export const utilService = new UtilService();
