import { SettingsModel } from '@spotrisk/settings';
import { SettingsActionTypes, SET_SETTINGS } from './settings.types';

class SettingsActions {
  setSettings = (settings: SettingsModel): SettingsActionTypes => ({
    type: SET_SETTINGS,
    settings,
  });
}

export const settingsActions = new SettingsActions();
