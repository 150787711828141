import ApiLogo from '@assets/svgs/api-logo.svg';
import { Button, Text, useModal, useToast } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import {
  CustomIntegrationAPIKeyModal,
  IntegrationWebhookModal,
  MerchantIntegrationModel,
  MerchantWebhookConfigurationModal,
  settingsResource,
} from '@spotrisk/settings';
import React, { HTMLProps, memo, MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type AnchorClickEvent = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled' | 'state'> & {
  name?: string;
  onClick?: AnchorClickEvent | ButtonClickEvent;
  ctaCopy?: string;
  subTitle?: string;
  isDisabled?: boolean;
  integration: MerchantIntegrationModel;
};

export const IntegrationCard = memo((props: Props) => {
  const { title, subTitle, integration } = props;
  const { openModal } = useModal();
  const { addToast } = useToast();
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [webhookConfigs, setWebhookConfigs] = useState<MerchantWebhookConfigurationModal[]>([]);

  useEffect(() => {
    getWebhookConfigs();
  }, []);

  if (!merchant) {
    return null;
  }

  if (!integration) {
    return null;
  }

  const getWebhookConfigs = async () => {
    const configs = await settingsResource.getIntegrationWebhooksByIntegrationId({ merchantId: merchant.id, integrationId: integration.id });
    setWebhookConfigs(configs);
  };

  const handleDelete = async () => {
    if (!integration.id) {
      return null;
    }

    await settingsResource.deleteMerchantIntegration({ merchantId: merchant.id, integrationId: integration.id });
    addToast(<Text>Integration deleted successfully!</Text>);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  if (!merchant) {
    return null;
  }

  const renderActions = () => {
    return (
      <>
        <div className="mb-4">
          <Button variant="blue" onClick={() => openModal(<CustomIntegrationAPIKeyModal integration={integration} />)}>
            Reveal API Key
          </Button>
        </div>
        <div className="mb-4">
          <Button
            variant="light"
            onClick={() => openModal(<IntegrationWebhookModal webhookConfig={webhookConfigs[0]} integrationId={integration.id} />)}
          >
            Configure Webhooks
          </Button>
        </div>

        <Text className="block text-green-500 text-sm mt-5 mb-5">{subTitle}</Text>
        <div>
          <Button variant="danger" onClick={handleDelete}>
            Delete Integration
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="integration-card flex-1 card text-center shadow-sm">
      <Text className="block text-header font-semibold mt-5 mb-5">{title}</Text>
      {renderActions()}
      {webhookConfigs && webhookConfigs.length > 0 && webhookConfigs[0].enabled && (
        <Text className="block text-green-500 text-sm mt-5">Webhooks are enabled</Text>
      )}
    </div>
  );
});
