import TickImage from '@assets/svgs/tick.svg';
import { Button, Text, useModal } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { ConnectorDisconnectModal } from '@spotrisk/settings';
import React, { HTMLProps, memo, MouseEvent as ReactMouseEvent } from 'react';
import { useSelector } from 'react-redux';

type AnchorClickEvent = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled' | 'state'> & {
  href?: string;
  title?: string;
  logo?: JSX.Element;
  onClick?: AnchorClickEvent | ButtonClickEvent;
  ctaCopy?: string;
  subTitle?: string;
  connected?: boolean;
  isDisabled?: boolean;
};

export const ConnectorCard = memo((props: Props) => {
  const { logo, title, onClick, subTitle, connected = false, ctaCopy = 'Connect' } = props;
  const { openModal } = useModal();
  const merchant = useSelector(merchantSelectors.getMerchant);
  if (!merchant) {
    return null;
  }

  const renderActions = () => {
    if (connected) {
      return (
        <>
          <Text className=" text-md text-gray-550 relative block mb-5">
            Connected <TickImage className="tick" />
          </Text>
          <Button variant="danger" onClick={() => openModal(<ConnectorDisconnectModal merchant={merchant} />)}>
            Disconnect
          </Button>
        </>
      );
    } else {
      return (
        <Button variant="blue" onClick={onClick}>
          {ctaCopy}
        </Button>
      );
    }
  };

  return (
    <div className="integration-card flex-1 card text-center shadow-sm">
      <div className="integration-image text-center">{logo}</div>
      <Text className="block text-header font-semibold mt-5 mb-5">{title}</Text>
      {renderActions()}
      <Text className="block text-gray-500 text-sm mt-5">{subTitle}</Text>
    </div>
  );
});
