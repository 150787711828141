import { TabsVariant, useTabs } from '@spotrisk/common';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { findIndex } from 'lodash';
import React, { MutableRefObject, useEffect, useState } from 'react';

type Props = {
  tabButtonRefs: MutableRefObject<HTMLButtonElement[]>;
  variant: TabsVariant;
  isAnimated: boolean;
};

const BASE_CLASSES = ['absolute', 'w-full'];
const BASE_CONTENT_CLASSES = ['absolute', 'h-full'];

export function TabsActiveIndicator({ tabButtonRefs, variant, isAnimated }: Props) {
  const { activeTab, tabs } = useTabs();
  const [style, setStyle] = useState<{ left: string; width: string }>();
  const classes = cn(BASE_CLASSES, {
    'bottom-0 h-1': activeTab && variant === 'underline',
  });
  const contentClasses = cn(BASE_CONTENT_CLASSES, {
    'bg-blue-800': activeTab && variant === 'underline',
  });

  useEffect(() => {
    if (!activeTab) {
      return;
    }
    const activeIndex = findIndex(tabs, ({ id }) => id === activeTab.id);
    const activeTabButtonRef = tabButtonRefs.current[activeIndex];

    if (!activeTabButtonRef) {
      return;
    }

    let left = 0;

    for (let i = 0; i < activeIndex; i += 1) {
      const current = tabButtonRefs.current[i]?.getBoundingClientRect().left || 0;
      const next = tabButtonRefs.current[i + 1]?.getBoundingClientRect().left || 0;
      left += next - current;
    }

    let width = 0;
    if (variant === 'underline') {
      // With the underline variant, we want the width of the border bottom to be the same as the text. So we use the bounding client rectangle of the
      // first child.
      width = activeTabButtonRef.children[0].getBoundingClientRect().width || 0;
    }

    setStyle({ left: `${left}px`, width: `${width}px` });
  }, [tabButtonRefs, activeTab, tabs]);

  if (!style) {
    return null;
  }

  return (
    <div className={classes}>
      {isAnimated && <motion.div className={contentClasses} style={style} layout />}
      {!isAnimated && <div className={contentClasses} style={style} />}
    </div>
  );
}
