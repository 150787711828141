import { apiService } from '@spotrisk/common';
import { MerchantModel } from '@spotrisk/merchant';
import { CustomRuleListModel, CustomRuleListResponse, CustomRuleModel, CustomRuleResponse, customRuleService } from '@spotrisk/custom-rule';

type GetCustomRulesParam = {
  merchantId: MerchantModel['id'];
};

type UpdateCustomRuleParam = {
  merchantId: MerchantModel['id'];
  customRule: CustomRuleModel;
};

type GetCustomRuleListsParam = {
  merchantId: MerchantModel['id'];
};

type UpdateCustomRuleListParam = {
  merchantId: MerchantModel['id'];
  customRuleList: CustomRuleListModel;
};

class CustomRuleResource {
  getCustomRules = async ({ merchantId }: GetCustomRulesParam): Promise<CustomRuleModel[]> => {
    const customRules = await apiService.fetch<CustomRuleResponse[]>(`/ecommerce/merchant/${merchantId}/custom_rule`);

    return customRules.map(customRuleService.serialiseCustomRule);
  };

  updateCustomRule = async ({ merchantId, customRule }: UpdateCustomRuleParam): Promise<void> => {
    await apiService.fetch(`/ecommerce/merchant/${merchantId}/custom_rule/${customRule.id}`, {
      method: 'PUT',
      data: customRuleService.serialiseCustomRuleRequest(customRule),
    });
  };

  getCustomRuleLists = async ({ merchantId }: GetCustomRuleListsParam): Promise<CustomRuleListModel[]> => {
    const customRuleLists = await apiService.fetch<CustomRuleListResponse[]>(`/ecommerce/merchant/${merchantId}/custom_rule_list`);

    return customRuleLists.map(customRuleService.serialiseCustomRuleList);
  };

  updateCustomRuleList = async ({ merchantId, customRuleList }: UpdateCustomRuleListParam): Promise<void> => {
    await apiService.fetch(`/ecommerce/merchant/${merchantId}/custom_rule_list/${customRuleList.id}`, {
      method: 'PUT',
      data: customRuleService.serialiseCustomRuleListRequest(customRuleList),
    });
  };
}

export const customRuleResource = new CustomRuleResource();
