import { apiService } from '@spotrisk/common';
import {
  ConfigurationModel,
  ConfigurationResponse,
  IntegrationProvisionModel,
  IntegrationProvisionResponse,
  IntegrationName,
  integrationService,
  ShopifyOrderViewRequest,
  ShopifyOrderViewEmbeddedResponse,
} from '@spotrisk/integration';
import { CreateUserRequest, UserModel, UserResponse, userService } from '@spotrisk/user';

type PostIntegrationProvisionParam = {
  integrationName: IntegrationName;
  provision: IntegrationProvisionModel;
};

type GetIntegrationOrderViewEmbeddedParam = {
  integrationName: IntegrationName;
  orderViewRequest: ShopifyOrderViewRequest;
};

type CreateUserParam = {
  integrationName: IntegrationName;
  signature: string;
  user: CreateUserRequest;
};

class IntegrationResource {
  getConfigurations = async (): Promise<ConfigurationModel[]> => {
    const configurationResponses = await apiService.fetchPublic<ConfigurationResponse[]>(`/auth/integration/configurations`, {
      method: 'GET',
    });

    return configurationResponses.map((response) => integrationService.serialiseConfigurationResponse(response));
  };

  createUser = async ({ integrationName, signature, user }: CreateUserParam): Promise<UserModel> => {
    const userResponse = await apiService.fetch<UserResponse>(`/integration/${integrationName}/user?signature=${signature}`, {
      method: 'POST',
      data: user,
    });

    if (!userResponse) {
      throw new Error('Failed to create user');
    }

    return userService.serialiseUser(userResponse);
  };

  postIntegrationProvision = async ({ integrationName, provision }: PostIntegrationProvisionParam): Promise<IntegrationProvisionResponse> => {
    const response = await apiService.fetch<IntegrationProvisionResponse>(`/integration/${integrationName}/provision`, {
      method: 'POST',
      data: integrationService.serialiseIntegrationProvisionRequest(provision),
    });

    if (!response) {
      throw new Error('Failed to provision integration');
    }

    return response;
  };

  getIntegrationOrderView = async ({
    integrationName,
    orderViewRequest,
  }: GetIntegrationOrderViewEmbeddedParam): Promise<ShopifyOrderViewEmbeddedResponse> => {
    const params = new URLSearchParams({ ...orderViewRequest }).toString();
    const response = await apiService.fetch<ShopifyOrderViewEmbeddedResponse>(`/integration/${integrationName}/public/order_view?${params}`, {
      method: 'GET',
    });

    if (!response) {
      throw new Error('Failed to fetch order view');
    }

    return response;
  };
}

export const integrationResource = new IntegrationResource();
