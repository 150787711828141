import {
  AcceptUserInviteRequest,
  AcceptUserInviteResponse,
  ChangePasswordRequest,
  LoginRequest,
  LoginResponse,
  MasqueradeLoginResponse,
  MasqueradeRequest,
  ProvisionRequest,
  ProvisionResponse,
  RefreshRequest,
  RefreshResponse,
  ResetPasswordRequest,
  UserInviteRequest,
} from '@spotrisk/auth';
import { apiService } from '@spotrisk/common';
import { UserResponse, userService } from '@spotrisk/user';

class AuthResource {
  provisionUser = async (request: ProvisionRequest) => {
    const provisionResponse = await apiService.fetchPublic<ProvisionResponse>(`/auth/provision.user`, {
      method: 'POST',
      data: request,
    });

    return provisionResponse;
  };

  masquerade = async (request: MasqueradeRequest) => {
    const response = await apiService.fetchPublic<MasqueradeLoginResponse>(`/auth/masquerade.user.session`, {
      method: 'POST',
      data: request,
    });

    return response;
  };

  login = async ({ email, password }: LoginRequest) => {
    const response = await apiService.fetchPublic<LoginResponse>(`/auth/login`, {
      method: 'POST',
      data: {
        email,
        password,
      },
    });

    return response;
  };

  refreshToken = async ({ refreshToken }: RefreshRequest) => {
    const response = await apiService.fetchPublic<RefreshResponse>(`/auth/refresh`, {
      method: 'POST',
      data: {
        refreshToken,
      },
    });

    return response;
  };

  resetPassword = async ({ email }: ResetPasswordRequest) => {
    return await apiService.fetchPublic(`/auth/password.reset?email=${email}`, {
      method: 'PUT',
      data: {
        email,
      },
    });
  };

  changePassword = async ({ password, token }: ChangePasswordRequest) => {
    return await apiService.fetchPublic(`/auth/user.change.password`, {
      method: 'POST',
      data: {
        password,
        token,
      },
    });
  };

  userInvite = async ({ token }: UserInviteRequest) => {
    return await apiService.fetchPublic<AcceptUserInviteResponse>(`/auth/user.invite`, {
      method: 'POST',
      data: {
        token,
      },
    });
  };

  userInviteAccept = async ({ token, name, password }: AcceptUserInviteRequest) => {
    const response = await apiService.fetchPublic<UserResponse>(`/auth/user.invite.accept`, {
      method: 'POST',
      data: {
        token,
        name,
        password,
      },
    });

    return userService.serialiseUser(response);
  };
}

export const authResource = new AuthResource();
