import { BillingModel } from '@spotrisk/billing';

export type BillingReducerState = {
  billing?: BillingModel;
};

export const SET_BILLING = 'billing|SET_BILLING' as const;

type SetBillingAction = {
  type: typeof SET_BILLING;
  billing: BillingModel;
};

export type BillingActionTypes = SetBillingAction;
