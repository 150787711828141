import { BillingModel, CustomRulesFeatureModal } from '@spotrisk/billing';
import { Button, Text, useModal } from '@spotrisk/common';
import { AddressBlockListModal, CustomRuleListModel, CustomRuleListUpdateModal, IpBlockListModal, EmailBlockListModal } from '@spotrisk/custom-rule';
import { CountryBlockListModal } from '@spotrisk/custom-rule/components/country-block-list-modal.component';
import { MerchantModel } from '@spotrisk/merchant';
import React from 'react';

export interface ICustomRuleListsProps {
  billing: BillingModel;
  customRuleLists: CustomRuleListModel[];
  merchant: MerchantModel;
}

const getRuleName = (name: string) => {
  switch (name.toLocaleLowerCase()) {
    case 'ip_block_list':
      return 'IP block list';
    default:
      return name.charAt(0).toUpperCase() + name.toLowerCase().replaceAll('_', ' ').slice(1);
  }
};
const getRuleSubTitle = (ruleName: string): string => {
  switch (ruleName.toLowerCase()) {
    case 'country_block_list':
      return 'Please enter a comma-separated list of two letter country codes to block when processing orders. e.g. US,NZ,FR';
    case 'finger_print_block_list':
      return 'Please enter a comma-separated list of digital fingerprints to block when processing orders. e.g. abc,def,ghi';
    default:
      return 'Please enter a comma-separated list.';
  }
};

export const CustomRuleLists = ({ billing, customRuleLists, merchant }: ICustomRuleListsProps) => {
  const { openModal } = useModal();

  const getModal = (ruleList: CustomRuleListModel): JSX.Element => {
    if (!billing?.plan?.features?.customRules) {
      return <CustomRulesFeatureModal />;
    }

    switch (ruleList.name.toLowerCase()) {
      case 'address_block_list':
        return <AddressBlockListModal merchant={merchant} ruleList={ruleList} />;
      case 'country_block_list':
        return <CountryBlockListModal merchant={merchant} ruleList={ruleList} />;
      case 'ip_block_list':
        return <IpBlockListModal merchant={merchant} ruleList={ruleList} />;
      case 'email_block_list':
        return <EmailBlockListModal merchant={merchant} ruleList={ruleList} />;
      default:
        return (
          <CustomRuleListUpdateModal
            customRuleList={ruleList}
            merchant={merchant}
            subTitle={getRuleSubTitle(ruleList.name)}
            title={getRuleName(ruleList.name)}
          />
        );
    }
  };

  return (
    <>
      {customRuleLists.map((ruleList) => (
        <div key={ruleList.name} className="flex items-center settings-panel p-4 my-2">
          <Text className="font-semibold block text-header text-sm w-10/12">{getRuleName(ruleList.name)}</Text>
          <div className="flex justify-center w-2/12">
            <Button variant="blue" onClick={() => openModal(getModal(ruleList))}>
              Edit
            </Button>
          </div>
        </div>
      ))}
    </>
  );
};

export default CustomRuleLists;
