import { useSelector } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { Text, TextSkeleton } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { CustomerModel, customerResource } from '@spotrisk/customer';

export const CustomerListSummary = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [isLoading, setIsLoading] = useState(true);
  const [blockedCustomers, setBlockedCustomers] = useState<CustomerModel[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (!merchant) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const blockedCustomers = await customerResource.queryCustomers({ merchant, isBlocked: true });

        setBlockedCustomers(blockedCustomers);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  return (
    <div className="mt-16">
      <Text className="text-xs font-header  uppercase opacity-40" variant="primary" isBlock>
        All time
      </Text>
      <div className="flex mt-8">
        <div className="w-1/2 mr-8">
          {isLoading ? (
            <TextSkeleton className="h-6 w-5" variant="primary" />
          ) : (
            <Text className="text-2xl font-semibold" variant="primary" isBlock>
              {blockedCustomers.length}
            </Text>
          )}
          <Text className="font-medium opacity-40 mt-2" variant="primary" isBlock>
            customers blocked
          </Text>
        </div>
      </div>
    </div>
  );
});
