import React from 'react';

type Props = {
  checked: boolean;
  className?: string;
  isDisabled?: boolean;
  onToggle: () => void;
};

export const Switch = ({ className, onToggle, checked, isDisabled }: Props) => (
  <div className={className}>
    <label className="cursor-pointer relative">
      <input disabled={isDisabled} type="checkbox" className="sr-only" onChange={onToggle} checked={checked} />
      <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-5 w-9 rounded-full"></div>
    </label>
  </div>
);
