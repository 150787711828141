import React, { useState } from 'react';
import { merchantSelectors } from '@spotrisk/merchant';
import { useSelector } from 'react-redux';
import { Switch, Text, useToast, featureService } from '@spotrisk/common';
import { settingsResource, SettingsModel } from '@spotrisk/settings';
import SpotAccessBlocked from '@assets/images/spot/spot-access-blocked.png';

export interface IBlockyProps {
  settings?: SettingsModel;
  onUpdate: (settings: Partial<SettingsModel>) => void;
}

export const Blocky = (props: IBlockyProps) => {
  const { addToast } = useToast();
  const [blockStatus, setBlockStatus] = useState(props.settings?.blockyEnabled || false);

  const merchant = useSelector(merchantSelectors.getMerchant);
  if (!merchant) {
    return <div></div>;
  }

  // disabled for other platforms for now
  const isShopify = merchant.platform === 'SHOPIFY' ? true : false;

  const handleBlockStatusToggle = async () => {
    try {
      await settingsResource.toggleBlocky(!blockStatus, { merchant });
      setBlockStatus(!blockStatus);
      // reload the page
      window.location.reload();
    } catch (e) {
      addToast(<Text>Something went wrong, failed to update Block-e status. Our support team has been notified.</Text>);
    }
  };

  const renderContent = () => {
    return (
      <div>
        <Text className="text-xl text-header font-bold mr-2 antialiased block my-5">Block-e</Text>
        <Text className="text-xs font-header text-header font-light block mt-2 mb-7 antialiased">
          Block suspicious visitors from accessing your e-commerce store.
        </Text>
        <div className="flex flex-row justify-start">
          <div className="blocky-info">
            <div className="inner flex">
              <div>
                <Text className="font-semibold block text-header text-sm w-10/12 antialiased">
                  Restrict previously blocked buyers from being able to access your store. Currently <strong>{blockStatus ? 'On' : 'Off'}</strong>
                </Text>
              </div>
              <Switch
                isDisabled={!isShopify}
                className="flex justify-center w-2/12"
                onToggle={handleBlockStatusToggle}
                checked={blockStatus || false}
              />
            </div>
            {!isShopify && (
              <Text className="text-xs font-header text-header font-light block mt-2 mb-7 antialiased">
                <strong>
                  Block-e is currently only available for Shopify stores. Please contact support@spotriskhq.com to enable if you are using another
                  platform.
                </strong>
              </Text>
            )}
          </div>

          <div className="blocky-image text-center">
            <img className="my-3" src={SpotAccessBlocked} alt="Block-e" />
            <a href="https://support.spotriskhq.com/en/articles/7981444-using-block-e" className="text-xs font-header text-header">
              Find out more in our help centre guide
            </a>
          </div>
        </div>
      </div>
    );
  };
  if (featureService.isEnabled('blocky-enabled-globally')) {
    return <div className="flex gap-4 justify-between">{renderContent()}</div>;
  }

  return <div></div>;
};
export default Blocky;
