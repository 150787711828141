import ShopifyLogo from '@assets/svgs/shopify-logo.svg';
import BigcommerceLogo from '@assets/svgs/bigcommerce-logo.svg';
import { merchantSelectors } from '@spotrisk/merchant';
import { ConnectorCard } from '@spotrisk/settings';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

const integrations = [
  {
    platform: 'SHOPIFY',
    title: 'Connect to Shopify',
    url: 'https://apps.shopify.com/spotrisk',
    logo: <ShopifyLogo />,
  },
  {
    platform: 'BIG_COMMERCE',
    title: 'Connect to Bigcommerce',
    url: 'https://www.bigcommerce.com/apps/spotrisk',
    logo: <BigcommerceLogo />,
  },
];

export const ConnectorCards = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);

  if (!merchant) {
    return null;
  }

  const platform = merchant.platform?.toUpperCase();

  // Don't show competitor integrations if another is connected
  const content = integrations.some((integration) => integration.platform === platform)
    ? [...integrations.filter((integration) => integration.platform === platform)]
    : [...integrations];

  // content.push(customIntegration);

  const renderContent = content.map((integration) => {
    return (
      <ConnectorCard
        key={integration.platform}
        logo={integration.logo}
        title={integration.title}
        onClick={() => {
          window.location.href = integration.url;
        }}
        connected={platform === integration.platform}
      />
    );
  });

  return <>{renderContent}</>;
});
