import { AssessmentReducerState, AssessmentActionTypes, SET_ASSESSMENT, SET_ASSESSMENTS } from './assessment.types';

const initialState: AssessmentReducerState = {
  assessments: [],
  assessment: null,
};

export const assessmentReducer = (state = initialState, action: AssessmentActionTypes) => {
  switch (action.type) {
    case SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.assessments,
      };
    case SET_ASSESSMENT:
      return {
        ...state,
        assessment: action.assessment,
      };
    default:
      return state;
  }
};
