import React, { memo, useEffect } from 'react';
import { Page, PageContent, Text } from '@spotrisk/common';
import { authService } from '@spotrisk/auth';

export const LogoutPage = memo(() => {
  useEffect(() => {
    authService.logout();
    window.location.href = window.location.hostname.includes('local') ? 'http://localhost:3000/login' : 'https://app.spotriskhq.com/login';
  }, []);

  return (
    <Page>
      <PageContent className="min-h-screen" hasHeader={false} isCentered>
        <div>
          <Text className="text-center" isBlock>
            Logging you out...
          </Text>
        </div>
      </PageContent>
    </Page>
  );
});
