import { MerchantReducerState, MerchantActionTypes, SET_MERCHANT, SET_MERCHANTS } from './merchant.types';

const initialState: MerchantReducerState = {
  merchants: [],
  merchant: null,
};

export const merchantReducer = (state = initialState, action: MerchantActionTypes) => {
  switch (action.type) {
    case SET_MERCHANTS:
      return {
        ...state,
        merchants: action.merchants,
      };
    case SET_MERCHANT:
      return {
        ...state,
        merchant: action.merchant,
      };
    default:
      return state;
  }
};
