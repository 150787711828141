import SpotRateLogoSvg from '@assets/svgs/spotrisk-logo.svg';
import { BLOCKY_ROUTES } from '@spotrisk/blocky';
import { HeaderNavLink } from '@spotrisk/common';
import { CUSTOMER_ROUTES } from '@spotrisk/customer';
import { ORDER_ROUTES } from '@spotrisk/order';
import { SETTINGS_ROUTES, settingsSelectors } from '@spotrisk/settings';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

export const AppHeaderNavLinks = memo(() => {
  const merchantSettings = useSelector(settingsSelectors.getSettings);
  const blockyEnabled = merchantSettings?.blockyEnabled ?? false;

  return (
    <section className="flex flex-row justify-start w-full pr-2 md:h-12 ">
      <HeaderNavLink to="/" hasActiveState={false}>
        <SpotRateLogoSvg />
      </HeaderNavLink>
      <HeaderNavLink className="ml-6 md:ml-12" to={ORDER_ROUTES.root}>
        Orders
      </HeaderNavLink>
      <HeaderNavLink className="ml-6 md:ml-12" to={CUSTOMER_ROUTES.root}>
        Customers
      </HeaderNavLink>
      <HeaderNavLink className="ml-6 md:ml-12" to={BLOCKY_ROUTES.root} isHidden={blockyEnabled ? false : true}>
        Activity
      </HeaderNavLink>
      <HeaderNavLink className="ml-6 md:ml-12" to={SETTINGS_ROUTES.root}>
        Settings
      </HeaderNavLink>
    </section>
  );
});
