import { AppFooter, AppHeader } from '@spotrisk/app';
import { billingSelectors, OrderLimitReachedModal } from '@spotrisk/billing';
import { featureService, FourOhFourPage, MobileErrorPage, useModal } from '@spotrisk/common';
import { CustomerLayout, CUSTOMER_ROUTES } from '@spotrisk/customer';
import { OrderLayout, ORDER_ROUTES } from '@spotrisk/order';
import { SettingsPage, SETTINGS_ROUTES } from '@spotrisk/settings';
import React, { memo, useEffect } from 'react';
import { isDesktop, isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BLOCKY_ROUTES, BlockyLayout } from '@spotrisk/blocky';

export const AppLayout = memo(() => {
  const { openModal, setClosable } = useModal();
  const billing = useSelector(billingSelectors.getBilling);

  useEffect(() => {
    if (featureService.isEnabled('order-limit') && !window.location.pathname.startsWith(SETTINGS_ROUTES.root) && billing?.usage?.orderLimitReached) {
      setClosable(false);
      openModal(<OrderLimitReachedModal />);
    }
  }, [billing, window.location.pathname]);

  return (
    <>
      <AppHeader />
      <Switch>
        <Route path={ORDER_ROUTES.root} component={OrderLayout} />
        <Route path={CUSTOMER_ROUTES.root} component={CustomerLayout} />
        <Route path={SETTINGS_ROUTES.root} component={!isDesktop && !isTablet ? MobileErrorPage : SettingsPage} />
        <Route path={BLOCKY_ROUTES.root} component={BlockyLayout} />
        <Redirect from="/" to={ORDER_ROUTES.root} exact />

        {/* @TODO: This is a workaround to get Auth0 working. */}
        <Redirect from="/dashboard" to={ORDER_ROUTES.root} exact />
        <Redirect to={SETTINGS_ROUTES.root} />

        <Route component={FourOhFourPage} />
      </Switch>
      <AppFooter />
    </>
  );
});
