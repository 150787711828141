import React from 'react';
import SpotStatus from './spot-status.component';
import { SettingsModel, SpotProgressBar } from '@spotrisk/settings';

export interface ISpotAlertnessProps {
  orderCount: number;
  settings: SettingsModel;
  onUpdate: (settings: Partial<SettingsModel>) => void;
}

export const SpotAlertness = ({ settings, orderCount, onUpdate }: ISpotAlertnessProps) => {
  const threatLevels = [
    {
      level: 5,
      title: 'PASSIVE',
    },
    {
      level: 3,
      title: 'ALERT',
    },
    {
      level: 1,
      title: 'ACTIVE',
    },
  ];

  const handleThreatLevelChangeParent = (level: number) => {
    onUpdate({ riskThreshold: level });
  };

  const renderContent = () => {
    const list = threatLevels.map((level) => (
      <SpotStatus
        onClick={handleThreatLevelChangeParent}
        active={settings.riskThreshold === level.level}
        key={'threat-level' + level.level}
        level={level.level}
        title={level.title}
      />
    ));

    return <>{list}</>;
  };

  return (
    <div>
      <div className="flex gap-4 justify-between">{renderContent()}</div>
      <div className="flex justify-between ">
        <SpotProgressBar count={orderCount} />
      </div>
    </div>
  );
};
export default SpotAlertness;
