import { commonSelectors, localStorageService, Select } from '@spotrisk/common';
import { merchantActions, merchantSelectors } from '@spotrisk/merchant';
import { find, map } from 'lodash';
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const AppHeaderMerchantSwitcher = memo(() => {
  const dispatch = useDispatch();
  const ui = useSelector(commonSelectors.getUi);
  const merchant = useSelector(merchantSelectors.getMerchant);
  const merchants = useSelector(merchantSelectors.getMerchants);
  const merchantOptions = useMemo(() => map(merchants, ({ id, domain }) => ({ label: domain, value: id })), [merchants]);

  if (!merchant) {
    return null;
  }

  const handleMerchantChange = (id: string) => {
    const newMerchant = find(merchants, { id });

    if (!newMerchant) {
      return;
    }

    localStorageService.setItem('selected-merchant-id', id);
    dispatch(merchantActions.setMerchant(newMerchant));
    // TODO: refresh page because this is buggy on settings page etc
    window.location.reload();
  };

  return merchants.length > 1 ? (
    <section className="relative flex items-center w-full px-0 md:px-2">
      <Select options={merchantOptions} onChange={handleMerchantChange} value={merchant.id} isDisabled={ui === 'saving'} />
    </section>
  ) : null;
});
