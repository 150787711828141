import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Hr, Text } from '@spotrisk/common';

type Props = {
  className?: string;
  children: ReactNode;
  hasHeader?: boolean;
  heading?: ReactNode;
  isCentered?: boolean;
};

const CLASS_NAMES = ['px-6', 'pb-6'];

export const PageContent = ({ children, className, heading, hasHeader = true, isCentered }: Props) => {
  const classes = cn(className, CLASS_NAMES, {
    'absolute top-0 w-full': !isCentered,
    'flex items-center justify-center': isCentered,
    'pt-36 md:pt-26': hasHeader,
  });

  return (
    <div className={classes}>
      {heading && (
        <div>
          <div className="flex max-w-screen-xl mx-auto">
            <Text className="text-2xl font-header font-thin mt-2 decoration-4" variant="primary" isBlock>
              {heading}
            </Text>
          </div>
          <Hr className="mb-5" />
        </div>
      )}
      {children}
    </div>
  );
};
