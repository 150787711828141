import React from 'react';

export interface ISpotProgressProps {
  count: number;
}

export const SpotProgressBar = ({ count }: ISpotProgressProps) => {
  // Calculate percentage of progress
  const progress = (count / 1000) * 100;

  // Set color of progress bar and status message based on percentage of progress
  let color = 'bg-blue-500';
  let statusMessage = 'Spot is currently learning how to best protect your store against fraud.';
  if (count > 1000) {
    statusMessage = 'Spot is currently learning how to best protect your store against fraud.';
  } else if (count < 50) {
    color = 'bg-blue-500';
    statusMessage = 'Spot is currently learning how to best protect your store against fraud.';
  } else if (count < 80) {
    color = 'bg-blue-500';
    statusMessage = 'Spot is currently learning how to best protect your store against fraud...';
  }

  if (count > 1001) {
    return null;
  }

  return (
    <div className="relative w-full h-8 bg-gray-200 rounded-md overflow-hidden mt-5">
      <div className={`absolute top-0 bottom-0 left-0 ${color}`} style={{ width: `${progress}%` }}></div>
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-sm font-medium text-gray-500">
        {count > 1000 ? <span className="text-black">{statusMessage}</span> : statusMessage}
      </div>
    </div>
  );
};
