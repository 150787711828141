import { BillingModel, CustomRulesFeatureModal } from '@spotrisk/billing';
import { featureService, Switch, Text, useModal } from '@spotrisk/common';
import { CustomRuleConfirmationModal, CustomRuleModel } from '@spotrisk/custom-rule';
import React from 'react';

export interface ICustomRulesProps {
  billing: BillingModel;
  customRules: CustomRuleModel[];
  updateCustomRule: (customRule: CustomRuleModel) => void;
}

const getRuleName = (name: string) => {
  switch (name.toLocaleLowerCase()) {
    case 'automatically_capture_passed_payments':
      return 'Automatically capture payments for passed orders with low risk.';
    case 'automatically_verify_buyer':
      return 'Automatically verify the buyer against medium risk orders.';
    case 'automatically_cancel_high_risk_orders':
      return 'Automatically cancel high risk orders before payment capture.';
    case 'automatically_tag_orders':
      return 'Tag orders with risk outcome when Spotrisk processes an order.';
    case 'automatically_tag_hold_risky_orders':
      return 'Tag orders with `HOLD` when Spotrisk detects an risky order.';
    case 'automatically_cancel_failed_verification_orders':
      return 'Automatically cancel order when buyer verification is failed.';
    default:
      return name.charAt(0).toLocaleUpperCase() + name.toLowerCase().replaceAll('_', ' ').slice(1);
  }
};

const getBody = (name: string, enabled: boolean) => {
  switch (name.toLocaleLowerCase()) {
    case 'automatically_capture_passed_payments':
      return enabled ? (
        <>
          <p>
            By turning off this rule, Spotrisk will not automatically capture payments against orders detected with either no or a low level of risk.
          </p>
          <br />
          <p>Using this rule means that you will need to manually accept payments against each order.</p>
        </>
      ) : (
        <>
          <p>
            By turning on this rule, Spotrisk will automatically capture payments against any order detected with either no or a low level of risk.
          </p>
          <br />
          <p>Using this rule means that you will not need to manually accept payments one by one for genuine orders.</p>
        </>
      );
    case 'automatically_verify_buyer':
      return enabled ? (
        <>
          <p>By turning off this rule, Spotrisk will no longer automatically trigger the buyer verification flow for medium risk orders.</p>
          <br />
          <p>
            Read more about our Buyer Verification process{' '}
            <a className="tulip" href="https://intercom.help/spotrisk/en/articles/6783589-custom-rules" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>
        </>
      ) : (
        <>
          <p>
            By turning on this rule, Spotrisk will automatically trigger the buyer verification flow for any order detected with a medium level of
            risk.
          </p>
          <br />
          <p>Using this rule is good to ensure you don&apos;t decline genuine customers or accept orders from bad actors.</p>
          <br />
          <p>
            Read more about our Buyer Verification process{' '}
            <a className="tulip" href="https://intercom.help/spotrisk/en/articles/6783589-custom-rules" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>
        </>
      );
    case 'automatically_cancel_high_risk_orders':
      return enabled ? (
        <>
          <p>
            By turning off this rule, Spotrisk will still capture payment against orders flagged as high risk before capturing payment in your payment
            gateway.
          </p>
          <br />
          <p>Using this rule means that you will not incur fees or risk suspension of your gateway due to excessive refunds.</p>
        </>
      ) : (
        <>
          <p>
            By turning on this rule, Spotrisk will automatically cancel any order flagged as high risk before capturing payment in your payment
            gateway.
          </p>
          <br />
          <p>Using this rule means that you will not incur fees or risk suspension of your gateway due to excessive refunds.</p>
        </>
      );
    case 'automatically_tag_orders':
      return 'Spotrisk will tag all orders with the outcome of the fraud analysis.';
    case 'automatically_tag_hold_risky_orders':
      return 'Spotrisk will tag orders with `HOLD` when Spotrisk detects an risky order.';
    case 'automatically_cancel_failed_verification_orders':
      return 'Spotrisk will automatically cancel order when buyer verification is failed.';
    default:
      return 'Please confirm you would like to on/off this advanced rule.';
  }
};

export const CustomRules = ({ billing, customRules, updateCustomRule }: ICustomRulesProps) => {
  const { openModal } = useModal();

  const handleRuleToggle = (ruleId: string) => {
    if (!billing?.plan?.features?.customRules) {
      openModal(<CustomRulesFeatureModal />);
      return;
    }

    const customRule = customRules.find((customRule) => customRule.id === ruleId);
    if (!customRule) {
      return;
    }

    customRule.enabled = !customRule.enabled;

    updateCustomRule(customRule);
  };

  return (
    <>
      {customRules.map((customRule) => (
        <div key={customRule.name} className="flex items-center settings-panel p-7 my-2">
          <Text className="font-semibold block text-header text-sm w-10/12">{getRuleName(customRule.name)}</Text>
          <Switch
            className="flex justify-center w-2/12"
            onToggle={() =>
              billing.plan?.features?.customRules
                ? featureService.isEnabled('custom-rules-confirmation')
                  ? openModal(
                      <CustomRuleConfirmationModal
                        body={getBody(customRule.name, customRule.enabled)}
                        ruleId={customRule.id}
                        toggleRule={handleRuleToggle}
                      />
                    )
                  : handleRuleToggle(customRule.id)
                : openModal(<CustomRulesFeatureModal />)
            }
            checked={customRule.enabled}
          />
        </div>
      ))}
    </>
  );
};

export default CustomRules;
