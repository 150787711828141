import { Button, ModalContent, ModalFooter, Text, useModal, useToast } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import React, { useState } from 'react';
import { settingsResource } from '../settings.resource';
import { useSelector } from 'react-redux';

export const ConnectorCreateModal = () => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const { closeModal } = useModal();
  const { addToast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [integrationName, setIntegrationName] = useState('');

  if (!merchant) {
    return null;
  }

  const postMerchantIntegration = async () => {
    try {
      setIsSaving(true);
      const data = {
        merchantId: merchant.id,
        integrationName: integrationName,
      };

      await settingsResource.postMerchantIntegration(data);

      closeModal();
      setIsSaving(false);
      addToast(<Text>Integration created successfully</Text>);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      addToast(<Text>There was an error creating the integration. Our support team has been notified.</Text>);
      setIsSaving(false);
    }
  };

  return (
    <div className="max-w-lg">
      <ModalContent>
        <Text className="text-xl text-header text-left font-semibold mb-3" isBlock>
          Create API Integration
        </Text>
        <Text className="text-sm text-header text-left mt-5 mb-5" isBlock>
          Once you create your integration you will be given an API Key to access. You can also configure webhooks after the application has been
          created.
        </Text>
        <Text className="text-sm text-header text-left  mb-5" isBlock>
          Please supply the name of the integration:
        </Text>
        <input
          type="text"
          value={integrationName}
          onChange={(e) => setIntegrationName(e.target.value)}
          className="border border-gray-300 p-2 w-full mb-5"
          placeholder="Integration name"
        />
      </ModalContent>
      <ModalFooter className="text-right">
        <Button className="mr-2" onClick={closeModal} variant="gray" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button variant="blue" onClick={postMerchantIntegration} isDisabled={isSaving || !integrationName}>
          Create
        </Button>
      </ModalFooter>
    </div>
  );
};
