import { Button, Input, ModalContent, ModalFooter, ModalHeader, Text, useModal } from '@spotrisk/common';
import { customRuleActions, CustomRuleListModel, customRuleResource } from '@spotrisk/custom-rule';
import { MerchantModel } from '@spotrisk/merchant';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  ruleList: CustomRuleListModel;
  merchant: MerchantModel;
};

const ipCheck = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

export const IpBlockListModal = ({ ruleList, merchant }: Props) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const [isSaving, setIsSaving] = useState(false);
  const [input, setInput] = useState('');
  const [list, setList] = useState(ruleList.list);

  const handleAdd = (ip: string) => {
    if (ipCheck.test(ip)) {
      if (!list.includes(ip)) {
        setList([ip, ...list]);
      }
      setInput('');
    }
  };

  const handleRemove = (index: number) => {
    const update = [...list];
    update.splice(index, 1);
    setList(update);
  };

  const handleSubmit = async () => {
    setIsSaving(true);

    const update = {
      ...ruleList,
      list,
    };

    await customRuleResource.updateCustomRuleList({ merchantId: merchant.id, customRuleList: update });
    dispatch(customRuleActions.updateCustomRuleList(update));

    closeModal();
  };

  const addButton = ipCheck.test(input) ? (
    <Button className="absolute right-4 inset-y-0" variant="blue" state="text" onClick={() => handleAdd(input)}>
      + Add
    </Button>
  ) : null;

  return (
    <div className="max-w-lg" style={{ minWidth: '550px' }}>
      <ModalHeader>
        <Text className="text-xl text-header font-semibold mb-3" isBlock>
          IP block list
        </Text>
        <Text className="text-sm text-header" isBlock>
          Spotrisk allows you to block a specific IP Address.
        </Text>
      </ModalHeader>
      <ModalContent className="pt-0">
        <div className="flex relative">
          <Input
            className="bg-gray-100 h-12"
            value={input}
            placeholder="Enter a valid IP address"
            onChange={({ target }) => setInput(target.value)}
            autoFocus
            onKeyDown={({ key }) => {
              if (key === 'Enter') {
                handleAdd(input);
              }
            }}
          />
          {addButton}
        </div>
        {list.length ? (
          <div className="mt-6">
            Blocked IP Addresses
            <div className="mt-6 max-h-60 overflow-y-auto">
              {list.map((ip, index) => (
                <div key={index} className="flex justify-between items-center w-full my-2.5 p-4 block-list-item">
                  <div>{ip}</div>
                  <Button variant="danger" state="text" onClick={() => handleRemove(index)}>
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-4" />
        )}
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} variant="gray" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button className="ml-2" variant="blue" type="submit" onClick={handleSubmit} isDisabled={isSaving}>
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};
