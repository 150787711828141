import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { merchantSelectors } from '@spotrisk/merchant';
import { Button, Text } from '@spotrisk/common';
import { analytics } from '@spotrisk/common';
import { SETTINGS_ROUTES } from '@spotrisk/settings';
import { billingSelectors } from '@spotrisk/billing';

// Global trial days
const TrialDays = 30;

type Props = {
  className?: string;
};

const BASE_CLASSES = ['fixed', 'bottom-0', 'left-0', 'w-full', 'bg-banner'];

export const AppFooter = memo(({ className }: Props) => {
  const classes = cn(className, BASE_CLASSES);
  const billing = useSelector(billingSelectors.getBilling);
  const merchant = useSelector(merchantSelectors.getMerchant);

  if (!merchant) {
    return <></>;
  }

  if (billing?.subscription?.status === 'ACTIVE') {
    return <></>;
  }

  const expireAt = billing?.subscription?.trialStartDate ? new Date(billing?.subscription?.trialStartDate) : merchant.createdAt;
  expireAt.setDate(expireAt.getDate() + TrialDays);

  const today = new Date();
  const daysLeft = Math.ceil((expireAt.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

  return (
    <footer className={classes}>
      <div className="text-center p-2">
        <Text className="text-header  text-white">
          {daysLeft > 0 ? `You have ${daysLeft} day(s) left on your trial. ` : 'Your trial has ended. '}
        </Text>

        {billing?.subscription?.platform === 'UNKNOWN' && today < expireAt ? (
          <>
            <Text className="text-header  text-white mt-2">
              To see your orders, connect Spotrisk with your online store. It takes less than 30 seconds.
            </Text>
            <Button className="mx-5 mt-2 md:mt-0" variant="light" state="outline" href={SETTINGS_ROUTES.integration} isLink>
              <Text className="text-header text-white">Connect now</Text>
            </Button>
          </>
        ) : (
          <>
            <Text className="text-header  text-white mt-2">To continue using Spotrisk with your online store.</Text>
            <Button className="mx-5 mt-2 md:mt-0" variant="light" state="outline" href={SETTINGS_ROUTES.plans} isLink>
              <Text className="text-header text-white">Select A Plan That Suits your Business</Text>
            </Button>
          </>
        )}
      </div>
    </footer>
  );
});
