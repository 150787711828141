import { merchantSelectors } from '@spotrisk/merchant';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { blockyResource } from '../blocky.resource';
import { ActivityModel } from '../blocky.model';
import { useInterval, Text } from '@spotrisk/common';
import { ActivityListCard } from './activity-list-card.component';

export const ActivityList = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [activityLogs, setActivityLogs] = useState<ActivityModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getActivityLogs() {
    if (!merchant) {
      return;
    }

    const response = await blockyResource.ActivityLog({ merchantId: merchant.id });
    if (!response) {
      return;
    }

    // order response by date
    response.logs.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    setActivityLogs(response.logs);
  }

  useEffect(() => {
    (async () => {
      await getActivityLogs();
      setIsLoading(false);
    })();
  }, []);

  useInterval(() => getActivityLogs(), 10 * 2000);

  if (activityLogs.length === 0 && !isLoading) {
    return (
      <div>
        <Text className="text-3xl font-header text-center mt-2" variant="primary" isBlock>
          Attaching to live feed.. and waiting for some suspicious visitors...
        </Text>
      </div>
    );
  }

  return (
    <div>
      {!isLoading &&
        activityLogs.map((log, index) => (
          <div key={index}>
            <ActivityListCard item={log} />
          </div>
        ))}
    </div>
  );
});
