import { AssessmentModel } from '@spotrisk/assessment';
import { AssessmentActionTypes, SET_ASSESSMENTS, SET_ASSESSMENT } from './assessment.types';

class AssessmentActions {
  setAssessments = (assessments: AssessmentModel[]): AssessmentActionTypes => ({
    type: SET_ASSESSMENTS,
    assessments,
  });

  setAssessment = (assessment: AssessmentModel | null): AssessmentActionTypes => ({
    type: SET_ASSESSMENT,
    assessment,
  });
}

export const assessmentActions = new AssessmentActions();
