import React from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  marginSmall?: boolean;
};

const BASE_CLASSES = ['relative', 'border-b', 'border-gray-200'];

export const Hr = ({ className, marginSmall = false }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    'my-5': !marginSmall,
    'my-2': marginSmall,
  });

  return <div className={classes} />;
};
