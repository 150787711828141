import cn from 'classnames';
import React, { memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

const BASE_CLASSES = ['fixed', 'top-0', 'left-0', 'w-full', 'bg-white', 'z-header'];

export const Header = memo(({ className, children }: Props) => {
  const classes = cn(className, BASE_CLASSES);

  return (
    <header className={classes}>
      <nav className="flex items-center p-6 z-header">{children}</nav>
    </header>
  );
});
