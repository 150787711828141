import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { BigCommerceAuth, BIG_COMMERCE_PUBLIC_ROUTES } from '@spotrisk/integration';
import React from 'react';

export const BigCommerceLayout = memo(() => (
  <Switch>
    <Route path={BIG_COMMERCE_PUBLIC_ROUTES.auth} component={BigCommerceAuth} exact />
  </Switch>
));
