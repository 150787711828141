import React, { memo, useEffect, useState } from 'react';
import { merchantSelectors } from '@spotrisk/merchant';
import { useSelector } from 'react-redux';
import { Button, Text, useModal, Loader, useToast } from '@spotrisk/common';
import { AddUserModal, settingsResource } from '@spotrisk/settings';
import EllipsisSvg from '@assets/svgs/icon-ellipsis.svg';
import { UserInvitationModel, UserModel, userResource } from '@spotrisk/user';

export const UsersView = memo(() => {
  const { openModal } = useModal();
  const { addToast } = useToast();

  const merchant = useSelector(merchantSelectors.getMerchant);

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<UserInvitationModel[]>([]);

  useEffect(() => {
    getUsers();
  }, []);

  if (!merchant) {
    return null;
  }

  const getUsers = async () => {
    const { users, invitedUsers } = await userResource.getUsers({ merchant });
    setUsers(users);
    setInvitedUsers(invitedUsers);
    setIsLoading(false);
  };

  const handleSuccess = async () => {
    setIsLoading(true);
    await getUsers();
  };

  const deleteUser = async (userId: string) => {
    setIsLoading(true);
    try {
      await userResource.deleteUser(userId, { merchant });
    } catch (e) {
      addToast(<Text>Something went wrong, failed to delete user. Our support team has been notified.</Text>);
    }
    await getUsers();
  };

  const toggleEmailNotification = async (userId: string) => {
    setIsLoading(true);
    try {
      await settingsResource.toggleUserEmailNotification(userId, { merchant });
    } catch (e) {
      addToast(<Text>Something went wrong, failed to update user. Our support team has been notified.</Text>);
    }
    await getUsers();
  };

  const resendInvite = async (userId: string) => {
    setIsLoading(true);
    try {
      await userResource.resendInvite(userId, { merchant });
      addToast(<Text>Invite resent to user.</Text>);
    } catch (e) {
      addToast(<Text>Something went wrong, failed to resend invite. Our support team has been notified.</Text>);
    }

    await getUsers();
  };

  const openUserOptions = (id: string) => {
    const optionDiv = document.getElementById(id) as HTMLElement;

    if (optionDiv) {
      optionDiv.style.display = 'block';
    }
  };

  document.addEventListener('click', function (event) {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('user-ellipsis')) {
      return;
    }

    const userActions = Array.from(document.getElementsByClassName('user-table-actions') as HTMLCollectionOf<HTMLElement>);
    userActions.forEach((element) => {
      element.style.display = 'none';
    });
  });

  const userActions = (user: UserModel) => {
    return (
      <>
        <div className="user-ellipsis" onClick={() => openUserOptions(`${user.id}-options`)}>
          <EllipsisSvg />
        </div>
        <div id={`${user.id}-options`} className="user-table-actions">
          <ul hidden={user.roles.includes('owner')}>
            <li onClick={() => deleteUser(user.id)}>Delete user</li>
          </ul>
          <ul>
            <li onClick={() => toggleEmailNotification(user.id)}>{user.emailNotification ? 'Disable Notifications' : 'Enable Notifications'}</li>
          </ul>
        </div>
      </>
    );
  };

  const userRows = () => {
    const userRows = users.map((user) => {
      return (
        <tr key={`user-${user.id}`}>
          <td>{user.email}</td>
          <td>{user.name}</td>
          <td className="role-cell">{user.roles[0]}</td>
          <td>{user.emailNotification ? 'All' : 'None'}</td>
          <td>{userActions(user)}</td>
        </tr>
      );
    });

    const invitedUserRows = invitedUsers.map((user: any) => {
      return (
        <tr key={`user-${user.id}`}>
          <td>
            {user.email}
            <span className="ml-3 pill">Invited</span>
          </td>
          <td>
            <span className="italic">{user.email}</span>
          </td>
          <td>User</td>
          <td></td>
          <td>
            <div className="user-ellipsis" onClick={() => openUserOptions(`${user.id}-options`)}>
              <EllipsisSvg />
            </div>
            <div id={`${user.id}-options`} className="user-table-actions">
              <ul>
                <li onClick={() => resendInvite(user.id)}>Resend Invite</li>
              </ul>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <>
        {invitedUserRows}
        {userRows}
      </>
    );
  };

  const userTable = () => {
    if (isLoading) {
      return <Loader className="h-48" />;
    }

    if (users.length === 0) {
      return <Text>No users found</Text>;
    }

    return (
      <table className="users-table">
        <thead>
          <tr>
            <th>
              <Text>Email</Text>
            </th>
            <th>
              <Text>Name</Text>
            </th>
            <th>
              <Text>Role</Text>
            </th>
            <th>
              <Text>Notifications</Text>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{userRows()}</tbody>
      </table>
    );
  };

  const renderContent = () => {
    return (
      <div>
        <div className="flex space-between">
          <div>
            <Text className="text-xl text-header font-bold mr-2 antialiased block tracking-wide">Users</Text>
            <Text className="text-xs font-header text-header font-light block mt-2 mb-7">Invite users to your Spotrisk account</Text>
          </div>
          <Button className="btn-invite-users" variant="blue" onClick={() => openModal(<AddUserModal onSuccess={handleSuccess} />)}>
            <Text>Invite User</Text>
          </Button>
        </div>
        {userTable()}
      </div>
    );
  };

  return renderContent();
});
