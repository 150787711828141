import { Button, Icon, useModal } from '@spotrisk/common';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef } from 'react';

type Props = {
  canClose: boolean;
};

export const Modal = ({ canClose }: Props) => {
  const { closeModal, modal } = useModal();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleKeydown = useCallback(
    ({ code }: KeyboardEvent) => {
      if (canClose && code === 'Escape') {
        closeModal();
      }
    },
    [canClose]
  );

  const handleClickOutside = useCallback(
    ({ target }: MouseEvent | TouchEvent) => {
      if (!canClose || !modalRef.current || modalRef.current.contains(target as any)) {
        return;
      }

      closeModal();
    },
    [canClose]
  );

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
      window.scroll(0, 0);
      window.addEventListener('keydown', handleKeydown);
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }
  }, [modal]);

  const backgroundClasses = cn(['fixed', 'top-0', 'bottom-0', 'left-0', 'right-0', 'z-modal'], {
    'bg-black opacity-60': canClose,
    'bg-blur': !canClose,
  });

  return (
    <AnimatePresence>
      {Boolean(modal) && (
        <div className="flex items-center justify-center absolute top-0 right-0 left-0 bottom-0 p-4 z-modal">
          <motion.div className={backgroundClasses} />
          <motion.div
            className="relative bg-white rounded-lg shadow-xl z-modal"
            initial={{ y: -5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ y: -5, opacity: 0 }}
            ref={modalRef}
          >
            {canClose && (
              <Button className="absolute" onClick={closeModal} variant="gray" shape="circle" style={{ top: '-15px', right: '-15px' }}>
                <Icon icon="close" />
              </Button>
            )}
            {modal}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
