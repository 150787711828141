import { ModalContent, ModalFooter, useModal, Text, Button, useToast } from '@spotrisk/common';
import { MerchantIntegrationModel } from '../settings.models';
import React, { useState } from 'react';

type Props = {
  integration: MerchantIntegrationModel;
};

export const CustomIntegrationAPIKeyModal = ({ integration }: Props) => {
  const { closeModal } = useModal();
  const { addToast } = useToast();
  const [isRevealed, setIsRevealed] = useState(false);

  const handleAction = () => {
    if (isRevealed) {
      navigator.clipboard.writeText(integration.apiKey ? integration.apiKey : '');
      addToast(<Text>API Key copied to clipboard</Text>);
    } else {
      setIsRevealed(true);
    }
  };

  return (
    <div className="max-w-2xl">
      <ModalContent>
        <Text className="text-xl text-header text-left font-semibold mb-3" isBlock>
          {integration.name}
        </Text>
        <Text className="text-sm text-header text-left mt-5 mb-1" isBlock>
          Below is your API Key for API Access.
        </Text>
        <Text className="text-sm text-header text-left mb-5" isBlock>
          You can come back here and retrieve it at any time.
        </Text>
        <Text className="text-sm text-header text-left mb-1" isBlock>
          Merchant ID
        </Text>

        <input type="text" value={integration.merchantId} readOnly className="border border-gray-300 p-2 w-full mb-4" />
        <Text className="text-sm text-header text-left mb-1" isBlock>
          API Key
        </Text>
        <input type={isRevealed ? 'text' : 'password'} value={integration.apiKey} readOnly className="border border-gray-300 p-2 w-full" />
        <Text className="text-sm text-header text-left mt-5 mb-5" isBlock>
          You can read more about how to integrate with Spotrisk by visiting our{' '}
          <a href="https://docs.spotriskhq.com/" target="_blank" rel="noopener noreferrer">
            <u>API Documentation</u>.
          </a>
        </Text>
      </ModalContent>
      <ModalFooter className="text-right">
        <Button className="mr-2" onClick={closeModal} variant="gray">
          Close
        </Button>
        <Button className="mr-2" onClick={handleAction} variant="blue">
          {isRevealed ? 'Copy' : 'Reveal'}
        </Button>
      </ModalFooter>
    </div>
  );
};
