import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader, Page, PageContent, Text } from '@spotrisk/common';
import { integrationResource } from '@spotrisk/integration';
import { userService } from '@spotrisk/user';
import { appResource } from '@spotrisk/app';
import { AUTH_ROUTES } from '@spotrisk/auth';
import { useAsyncEffect } from '@spotrisk/common/hooks';

export const ShopifyAuth = memo(() => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const dashboardUrl = window.location.hostname.includes('local') ? 'http://localhost:3000/dashboard' : 'https://app.spotriskhq.com/dashboard';

  const urlParams = new URLSearchParams(window.location.search);
  const shop = urlParams.get('shop');
  const code = urlParams.get('code');

  useAsyncEffect(async () => {
    try {
      if (!shop || !code) {
        setError('Missing shop or code in the URL');
        setIsLoading(false);
        return;
      }

      if (!userService.hasAccessTokens()) {
        history.push({
          pathname: AUTH_ROUTES.signup,
          search: `?redirect=${encodeURIComponent(window.location.href)}`,
        });
        return;
      }

      const { merchants } = await appResource.getBootstrap();
      if (!merchants) {
        setError('Unable to find account');
        setIsLoading(false);
        return;
      }

      const merchant = merchants[0];
      if (!merchant) {
        setError('Unexpected issue with installation');
        setIsLoading(false);
        return;
      }

      const response = await integrationResource.postIntegrationProvision({
        integrationName: 'shopify',
        provision: { shop, code, merchantId: merchant.id },
      });

      if (response) {
        window.location.href = dashboardUrl;
        return;
      }
    } catch (error) {
      setError('Shopify error has occurred');
      setIsLoading(false);
    }

    setIsLoading(false);
  }, []);

  return (
    <Page>
      <PageContent className="min-h-screen flex items-center justify-center">
        {isLoading ? (
          <div className="text-center">
            <Loader size="lg" />
            <h2 className="block text-5xl font-header text-header mt-5">Hang tight, we&apos;re connecting your Shopify account to Spotrisk!</h2>
          </div>
        ) : (
          <div className="text-center mb-4">
            <img src="https://cdn.spotriskhq.com/cdn/assets/public/img/logo-long.svg" className="w-18 mx-auto" alt="Spotrisk Logo" />
            <h1 className="mt-5" hidden={!!error}>
              Completing your setup...
            </h1>
            {error && (
              <div id="error-message" className="text-center mb-5 mt-5">
                <Text variant="danger">{error}. Please try again, our support team has been notified.</Text>
              </div>
            )}
          </div>
        )}
      </PageContent>
    </Page>
  );
});
