import React, { memo } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { merchantSelectors } from '@spotrisk/merchant';

type Props = {
  isDisabled?: boolean;
};

const BASE_CLASSES = [
  'flex',
  'items-center',
  'justify-center',
  'bg-white',
  'text-header',
  'border',
  'border-blue-100',
  'border-dashed',
  'rounded-md',
  'w-full',
  'p-6',
  'mt-2',
];

export const OrderListSummaryEmptyCard = memo(({ isDisabled }: Props) => {
  const classes = cn(BASE_CLASSES, {
    'opacity-50': isDisabled,
  });

  const merchant = useSelector(merchantSelectors.getMerchant);
  const message =
    !merchant || merchant.updatedAt.getTime() + 5 * 1000 * 60 > new Date().getTime() ? (
      <div className="text-center">
        <div>There are no orders with this status.</div>
        <div>We see you have just connected, orders may take a few minutes to process.</div>
      </div>
    ) : (
      'There are no orders with this status.'
    );

  return <div className={classes}>{message}</div>;
});
