import { ConfigurationModel, ConfigurationResponse, IntegrationProvisionModel, IntegrationProvisionRequest } from '@spotrisk/integration';

class IntegrationService {
  serialiseConfigurationResponse = (response: ConfigurationResponse): ConfigurationModel => ({
    name: response.name,
    clientId: response.client_id,
    scopes: response.scopes,
    redirectURL: response.redirect_url,
  });

  serialiseIntegrationProvisionRequest = (provision: IntegrationProvisionModel): IntegrationProvisionRequest => ({
    merchant_id: provision.merchantId,
    code: provision.code,
    shop: provision.shop,
  });
}

export const integrationService = new IntegrationService();
