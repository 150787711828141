import { Text, TextSkeleton } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { blockyResource } from '../blocky.resource';

export const ActivityListHeader = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [isLoading, setIsLoading] = useState(true);
  const [activityCount, setActivityCount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        if (!merchant) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const activityCount = await blockyResource.ActivityLogBlockCount({ merchantId: merchant.id });
        setActivityCount(activityCount);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  return (
    <>
      <Text className="text-5xl font-header mt-2" variant="primary" isBlock>
        Block-e has blocked
      </Text>

      <Text className="text-5xl font-header mt-2" variant="danger" isBlock>
        {isLoading ? <TextSkeleton className="h-10 w-8" variant="danger" /> : activityCount} suspicious visitors
      </Text>

      <Text className="text-5xl font-header mt-2" variant="primary" isBlock>
        from browsing
      </Text>
    </>
  );
});
