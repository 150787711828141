import { UserModel } from '@spotrisk/user';

export type UserReducerState = {
  user: UserModel | null;
};

export const SET_USER = 'user|SET_USER' as const;

type SetUserAction = {
  type: typeof SET_USER;
  user: UserModel | null;
};

export type UserActionTypes = SetUserAction;
