import { parseISO } from 'date-fns';
import { MerchantModel, MerchantResponse } from '@spotrisk/merchant';

class MerchantService {
  serialiseMerchant = (merchant: MerchantResponse): MerchantModel => ({
    id: merchant.id,
    tenantId: merchant.tenant_id,
    domain: merchant.domain,
    platform: merchant.platform,
    createdAt: parseISO(merchant.created_at),
    updatedAt: parseISO(merchant.updated_at),
    deletedAt: merchant.deleted_at ? parseISO(merchant.deleted_at) : null,
  });
}

export const merchantService = new MerchantService();
