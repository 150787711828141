import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SpotDownSvg from '@assets/svgs/spot/spot-down.svg';
import { billingSelectors } from '@spotrisk/billing';
import cn from 'classnames';

export const OrderListFooter = memo(() => {
  const billing = useSelector(billingSelectors.getBilling);

  const [sticky, setSticky] = useState(true);
  const [bottom, setBottom] = useState(0);

  useEffect(() => {
    const observer = new MutationObserver((mutations, obs) => {
      const listSummary = document.getElementById('order-list-summary');
      if (listSummary) {
        const listSummaryBottom = listSummary.getBoundingClientRect().bottom + 204;
        setBottom(listSummaryBottom);
        setSticky(window.innerHeight + window.scrollY > listSummaryBottom);
        obs.disconnect();
        return;
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  }, []);

  useEffect(() => {
    const onScroll = () => {
      setSticky(window.innerHeight + window.scrollY > bottom);
    };
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [bottom]);

  if (!bottom) {
    return <></>;
  }

  const classes = sticky
    ? cn('fixed', {
        'bottom-20': billing?.subscription?.status === 'TRIAL',
        'bottom-12': billing?.subscription?.status !== 'TRIAL',
      })
    : cn('mt-12');

  return (
    <div>
      <div className={classes}>
        <SpotDownSvg />
      </div>
    </div>
  );
});
