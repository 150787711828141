import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AUTH_ROUTES, LogoutPage, LoginPage, SignupPage, ForgotPasswordPage, UserInvitePage, MasqueradeRedirectPage } from '@spotrisk/auth';
import { AnimatePresence } from 'framer-motion';
import { ResetPasswordPage } from './pages/reset-password.page';

export const AuthLayout = () => (
  <AnimatePresence>
    <Switch>
      <Route path={AUTH_ROUTES.logout} component={LogoutPage} exact />
      <Route path={AUTH_ROUTES.login} component={LoginPage} exact />
      <Route path={AUTH_ROUTES.signup} component={SignupPage} exact />
      <Route path={AUTH_ROUTES.forgotPassword} component={ForgotPasswordPage} exact />
      <Route path={AUTH_ROUTES.resetPassword} component={ResetPasswordPage} exact />
      <Route path={AUTH_ROUTES.userInvite} component={UserInvitePage} exact />
      <Route path={AUTH_ROUTES.masqueradeRedirect} component={MasqueradeRedirectPage} exact />
    </Switch>
  </AnimatePresence>
);
