import {
  GET_ORDERS,
  OrderActionTypes,
  OrderListModel,
  OrderReducerSort,
  OrderState,
  REMOVE_ORDER,
  SET_ORDER,
  SET_ORDERS,
  SET_ORDER_SEARCH,
  SET_ORDER_SORT,
  SET_ORDER_STATE,
} from '@spotrisk/order';

class OrderActions {
  setOrders = (orders: OrderListModel[]): OrderActionTypes => ({
    type: SET_ORDERS,
    orders,
  });

  setOrder = (order: OrderListModel | null): OrderActionTypes => ({
    type: SET_ORDER,
    order,
  });

  removeOrder = (id: string): OrderActionTypes => ({
    type: REMOVE_ORDER,
    id,
  });

  getOrders = (): OrderActionTypes => ({
    type: GET_ORDERS,
  });

  setSearch = (search?: string): OrderActionTypes => ({
    type: SET_ORDER_SEARCH,
    search,
  });

  setSort = (sort: OrderReducerSort): OrderActionTypes => ({
    type: SET_ORDER_SORT,
    sort,
  });

  setState = (state: OrderState): OrderActionTypes => ({
    type: SET_ORDER_STATE,
    orderState: state,
  });
}

export const orderActions = new OrderActions();
