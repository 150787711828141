import React, { memo } from 'react';
import { ConnectorCards, ConnectorCreateModal } from '@spotrisk/settings';
import { Button, Text, useModal } from '@spotrisk/common';
import { CustomIntegrationCards } from '@spotrisk/settings';

export const SettingsIntegrationPage = memo(() => {
  const { openModal } = useModal();

  return (
    <>
      <div className="w-full">
        <div className="mb-10">
          <div>
            <Text className="text-xl text-header font-bold mr-2 antialiased block tracking-wide">Connectors</Text>
            <Text className="text-xs font-header text-header font-light block mt-2 mb-7">
              Connect and manage your ecommerce platform and Spotrisk using our platform connector.
            </Text>
          </div>
          <div className="w-full flex flex-1 gap-4 justify-around">
            <ConnectorCards />
          </div>
        </div>
        <div className="mt-10">
          <div>
            <Text className="text-xl text-header font-bold mr-2 antialiased block tracking-wide">Integrations</Text>
            <Text className="text-xs font-header text-header font-light block mt-2 mb-7">
              Connect and manage your ecommerce platform and Spotrisk using our API.
            </Text>
          </div>
          <div className="w-full flex flex-1 gap-4 justify-around mb-5">
            <CustomIntegrationCards />
          </div>
          <div className="w-full flex flex-1 gap-4 justify-around mb-5">
            <Button className="mt-5" variant="blue" onClick={() => openModal(<ConnectorCreateModal />)}>
              Add Custom Integration
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});
