import { TenantReducerState, TenantActionTypes, SET_TENANT } from './tenant.types';

const initialState: TenantReducerState = {};

export const tenantReducer = (state = initialState, action: TenantActionTypes) => {
  switch (action.type) {
    case SET_TENANT:
      return {
        ...state,
        tenant: action.tenant,
      };
    default:
      return state;
  }
};
