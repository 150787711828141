import SpotDownImage from '@assets/images/spot/spot-down.png';
import SpotSitImage from '@assets/images/spot/spot-sit.png';
import SpotStandImage from '@assets/images/spot/spot-stand.png';
import { Text } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import React from 'react';
import { useSelector } from 'react-redux';

export interface ISpotStatusProps {
  onClick: (level: number) => void;
  active: boolean;
  key: string;
  level: number;
  title: string;
}

export const SpotStatus = (props: ISpotStatusProps) => {
  const merchant = useSelector(merchantSelectors.getMerchant);

  if (!merchant) {
    return null;
  }

  const summaryCopy = {
    '1': 'Flag all risky orders for review',
    '3': 'Flag medium and high-risk orders for review',
    '5': 'Flag high-risk orders for review',
  };

  const descriptionCopy = {
    '1': 'For merchants who want to review orders that have been flagged with even a low level of risk.',
    '3': 'For merchants who want to review orders flagged with either a medium or high risk.',
    '5': 'For merchants who only want to review orders flagged as high risk.',
  };

  const spotImages = {
    '1': SpotStandImage,
    '3': SpotSitImage,
    '5': SpotDownImage,
  };

  const handleThreatLevelChange = async () => {
    props.onClick(props.level);
  };

  return (
    <div className={`spot-alertness flex-1 card ${props.active ? 'active' : ''}`} onClick={handleThreatLevelChange}>
      <img className="mb-3" src={spotImages[props.level]} alt="" />
      <Text className="font-header text-header text-sm uppercase antialiased block mb-2">{props.title}</Text>
      <Text className="font-semibold block mb-1 text-header text-sm">{summaryCopy[props.level]}</Text>
      <Text className="block mb-1 text-xs text-header opacity-40 font-medium antialiased">{descriptionCopy[props.level]}</Text>
    </div>
  );
};

export default SpotStatus;
