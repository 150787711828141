import { forEach } from 'lodash';
import { apiService } from '@spotrisk/common';
import { UserModel, UserResponse, userService } from '@spotrisk/user';
import { TenantModel, TenantResponse, tenantService } from '@spotrisk/tenant';
import { SettingsModel, SettingsResponse, settingsService } from '@spotrisk/settings';
import { BillingModel, BillingResponse, billingService } from '@spotrisk/billing';
import { MerchantModel, MerchantResponse, merchantService } from '@spotrisk/merchant';

type GetBootstrapResult = {
  merchants: MerchantModel[];
  settings: SettingsModel[];
  billings: BillingModel[];
  tenant: TenantModel;
  user: UserModel;
};

type BootstrapMerchantsResponse = {
  merchant: MerchantResponse;
  settings: SettingsResponse;
  billing: BillingResponse;
};

type BootstrapResponse = {
  merchants: BootstrapMerchantsResponse[];
  tenant: TenantResponse;
  user: UserResponse;
};

class AppResource {
  getBootstrap = async (): Promise<GetBootstrapResult> => {
    const bootstrapResponse = await apiService.fetch<BootstrapResponse>('/ecommerce/bootstrap');
    if (!bootstrapResponse) {
      throw new Error('Bootstrap response is undefined.');
    }

    const { merchants: merchantResponses, tenant, user } = bootstrapResponse;

    const serialisedMerchants: MerchantModel[] = [];
    const serialisedSettings: SettingsModel[] = [];
    const serialisedBillings: BillingModel[] = [];

    forEach(merchantResponses, ({ merchant, settings, billing }) => {
      serialisedMerchants.push(merchantService.serialiseMerchant(merchant));
      serialisedSettings.push(settingsService.serialiseSettingsResponse(settings));
      serialisedBillings.push(billingService.serialiseBilling(billing));
    });

    return {
      merchants: serialisedMerchants,
      settings: serialisedSettings,
      billings: serialisedBillings,
      tenant: tenantService.serialiseTenant(tenant),
      user: userService.serialiseUser(user),
    };
  };
}

export const appResource = new AppResource();
