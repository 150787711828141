import { Button, ModalContent, ModalFooter, ModalHeader, Text, TextArea, useModal } from '@spotrisk/common';
import { customRuleActions, CustomRuleListModel, customRuleResource } from '@spotrisk/custom-rule';
import { MerchantModel } from '@spotrisk/merchant';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  title: string;
  subTitle: string;
  customRuleList: CustomRuleListModel;
  merchant: MerchantModel;
};

export const CustomRuleListUpdateModal = ({ customRuleList, merchant, subTitle, title }: Props) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const [isSaving, setIsSaving] = useState(false);
  const [input, setInput] = useState(customRuleList.list.join(','));

  const handleSubmit = async () => {
    setIsSaving(true);

    const update = {
      ...customRuleList,
      list: input.split(','),
    };

    await customRuleResource.updateCustomRuleList({ merchantId: merchant.id, customRuleList: update });
    dispatch(customRuleActions.updateCustomRuleList(update));

    closeModal();
  };

  return (
    <div className="max-w-lg">
      <ModalHeader>
        <Text className="text-xl text-header font-semibold mb-3" isBlock>
          {title}
        </Text>
        <Text className="text-sm text-header" isBlock>
          {subTitle}
        </Text>
      </ModalHeader>
      <ModalContent>
        <TextArea value={input} onChange={({ target }) => setInput(target.value)} rows={10} isDisabled={isSaving} autoFocus />
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} variant="gray" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button className="ml-2" variant="blue" type="submit" onClick={handleSubmit} isDisabled={isSaving}>
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};
