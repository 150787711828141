import { TenantModel } from '@spotrisk/tenant';

export type TenantReducerState = {
  tenant?: TenantModel;
};

export const SET_TENANT = 'tenant|SET_TENANT' as const;

type SetTenantAction = {
  type: typeof SET_TENANT;
  tenant?: TenantModel;
};

export type TenantActionTypes = SetTenantAction;
