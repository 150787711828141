import { parseISO } from 'date-fns';
import { UserInvitationModel, UserInvitationResponse, UserModel, UserResponse, UsersModel, UsersResponse, userResource } from '@spotrisk/user';
import { localStorageService } from '@spotrisk/common';
class UserService {
  serialiseUser = (user: UserResponse): UserModel => ({
    id: user.id,
    name: user.name,
    email: user.email,
    roles: user.roles,
    tenantId: user.tenant_id,
    emailNotification: user.email_notification,
    createdAt: parseISO(user.created_at),
    updatedAt: parseISO(user.updated_at),
    deletedAt: user.deleted_at ? parseISO(user.deleted_at) : null,
  });

  serialiseUserInvitation = (user: UserInvitationResponse): UserInvitationModel => ({
    id: user.id,
    token: user.token,
    email: user.email,
    invitedBy: user.invited_by,
    status: user.status,
    tenantId: user.tenant_id,
    createdAt: parseISO(user.created_at),
    updatedAt: parseISO(user.updated_at),
    deletedAt: user.deleted_at ? parseISO(user.deleted_at) : null,
  });

  serialiseUsers = (response: UsersResponse): UsersModel => ({
    users: response.users.map(this.serialiseUser),
    invitedUsers: response.invited_users.map(this.serialiseUserInvitation),
  });

  getLoginUser = async () => {
    return await userResource.getLoginUser();
  };

  async isAuthenticated() {
    try {
      const user = await this.getLoginUser();
      if (!user) return false;
    } catch (error) {
      return false;
    }

    return true;
  }

  hasAccessTokens() {
    return localStorageService.getItem('spotrisk-access-token');
  }
}

export const userService = new UserService();
