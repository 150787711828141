import React, { forwardRef, ReactNode } from 'react';
import sha256 from 'crypto-js/sha256';
import cn from 'classnames';

type Props = {
  children: ReactNode;
  email: string;
  className?: string;
  onClick?: () => void;
};

const BASE_CLASSES = [
  'inline-flex',
  'flex-shrink-0',
  'items-center',
  'justify-center',
  'text-white',
  'h-10',
  'w-10',
  'rounded-full',
  'border-2',
  'border-transparent',
  'transition:border',
  'duration-200',
  'ease-in-out',
  'outline-none',
];

export const Gravatar = forwardRef<HTMLButtonElement, Props>(({ children, className, email, onClick }, ref) => {
  const classes = cn(className, BASE_CLASSES, {
    'cursor-pointer focus:outline-none hover:border-gray-400': onClick,
    'bg-blue-900 p-2': !email,
  });

  const renderContent = () => {
    const hashedEmail = sha256(email);
    const gravatarUrl = `https://www.gravatar.com/avatar/${hashedEmail}?default=mp`;
    return gravatarUrl ? <img className="block w-full h-full rounded-full" src={gravatarUrl} alt="Avatar" /> : <>{children}</>;
  };

  if (onClick) {
    return (
      <button className={classes} onClick={onClick} ref={ref}>
        {renderContent()}
      </button>
    );
  }

  return <div className={classes}>{renderContent()}</div>;
});
