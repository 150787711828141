import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { BLOCKY_ROUTES } from './blocky.routes';
import { BlockyListActivityPage } from './pages';

export const BlockyLayout = memo(() => (
  <Switch>
    <Route path={BLOCKY_ROUTES.root} component={BlockyListActivityPage} exact />
  </Switch>
));
