import { useSelector } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { Text, TextSkeleton } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { CustomerModel, customerResource } from '@spotrisk/customer';

export const CustomerListHeading = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [isLoading, setIsLoading] = useState(true);
  const [blockedCustomers, setBlockedCustomers] = useState<CustomerModel[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (!merchant) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const blockedCustomers = await customerResource.queryCustomers({ merchant, isBlocked: true });

        setBlockedCustomers(blockedCustomers);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  return (
    <>
      <Text className="text-5xl font-header mt-2" variant="primary" isBlock>
        Spot is blocking
      </Text>

      <Text className="text-5xl font-header mt-2" variant="danger" isBlock>
        {isLoading ? <TextSkeleton className="h-10 w-8" variant="danger" /> : blockedCustomers.length} suspicious customers
      </Text>

      <Text className="text-5xl font-header mt-2" variant="primary" isBlock>
        from your store
      </Text>
    </>
  );
});
