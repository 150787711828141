import { map, trim } from 'lodash';
import { parseISO } from 'date-fns';
import { assessmentService } from '@spotrisk/assessment';
import {
  OrderResponse,
  OrderModel,
  OrderAddressResponse,
  OrderAddressModel,
  OrderLineItemResponse,
  OrderLineItemModel,
  OrderShippingLineResponse,
  OrderShippingLineModel,
  OrderTokenModel,
  OrderTokenResponse,
  OrderTransactionResponse,
  OrderTransactionModel,
  OrderListModel,
  OrderListResponse,
} from '@spotrisk/order';
import { customerService } from '@spotrisk/customer';

class OrderService {
  getFullName = (order: OrderListModel) => trim(`${trim(order.firstName || '')} ${trim(order.lastName || '')}`);
  getAbbreviatedName = (order: OrderListModel) => {
    let lastInitial = trim(order.lastName || '')[0] || '';
    if (lastInitial) {
      lastInitial = `${lastInitial}.`;
    }

    return trim(`${trim(order.firstName || '')} ${lastInitial}`);
  };

  serialiseListOrder = (order: OrderListResponse): OrderListModel => ({
    id: order.id,
    orderName: order.order_name,
    firstName: order.first_name,
    lastName: order.last_name,
    email: order.email,
    totalPrice: order.total_price,
    currency: order.currency,
    state: order.state,
    status: order.status,
    failedCount: order.failed_count,
    successCount: order.success_count,
    riskScore: order.risk_score,
    orderDate: parseISO(order.order_date),
    createdAt: parseISO(order.created_at),
    updatedAt: parseISO(order.updated_at),
  });

  serialiseOrder = (order: OrderResponse): OrderModel => ({
    id: order.id,
    customerId: order.customer_id,
    merchantId: order.merchant_id,
    externalId: order.external_id,
    name: order.name,
    email: order.email,
    billingAddress: order.billing_address ? this.serialiseOrderAddress(order.billing_address) : undefined,
    shippingAddress: order.shipping_address ? this.serialiseOrderAddress(order.shipping_address) : undefined,
    currency: order.currency,
    lineItems: map(order.line_items, this.serialiseOrderLineItem),
    orderDate: parseISO(order.order_date),
    paymentGatewayName: order.payment_gateway_name,
    shippingLines: map(order.shipping_lines, this.serialiseOrderShippingLine),
    state: order.order_state.state,
    status: order.order_state.status,
    totalDiscount: order.total_discount,
    totalLineItemsPrice: order.total_line_items_price,
    totalPrice: order.total_price,
    transactions: map(order.transactions, this.serialiseOrderTransaction),
    assessments: map(order.assessments, assessmentService.serialiseAssessment),
    customer: order.customer ? customerService.serialiseCustomer(order.customer) : undefined,
    shippingDate: order.shipping_date ? parseISO(order.shipping_date) : undefined,
    token: order.token ? this.serialiseOrderToken(order.token) : undefined,
    createdAt: parseISO(order.created_at),
    updatedAt: parseISO(order.updated_at),
  });

  serialiseOrderToken = (orderToken: OrderTokenResponse): OrderTokenModel => ({
    id: orderToken.id,
    orderId: orderToken.order_id,
    merchantId: orderToken.merchant_id,
    status: orderToken.status,
    token: orderToken.token,
    createdAt: parseISO(orderToken.created_at),
    updatedAt: parseISO(orderToken.updated_at),
  });

  serialiseOrderAddress = (orderAddress: OrderAddressResponse): OrderAddressModel => ({
    id: orderAddress.id,
    merchantId: orderAddress.merchant_id,
    orderId: orderAddress.order_id,
    hash: orderAddress.hash,
    type: orderAddress.type,
    address1: orderAddress.address_1,
    address2: orderAddress.address_2,
    city: orderAddress.city,
    company: orderAddress.company,
    country: orderAddress.country,
    firstName: orderAddress.first_name,
    lastName: orderAddress.last_name,
    name: orderAddress.name,
    phone: orderAddress.phone,
    postalCode: orderAddress.postal_code,
    createdAt: parseISO(orderAddress.created_at),
    updatedAt: parseISO(orderAddress.updated_at),
    deletedAt: orderAddress.deleted_at ? parseISO(orderAddress.deleted_at) : null,
  });

  serialiseOrderLineItem = (orderLineItem: OrderLineItemResponse): OrderLineItemModel => ({
    id: orderLineItem.id,
    merchantId: orderLineItem.merchant_id,
    orderId: orderLineItem.order_id,
    quantity: orderLineItem.quantity,
    price: orderLineItem.price,
    name: orderLineItem.name,
    sku: orderLineItem.sku,
    createdAt: parseISO(orderLineItem.created_at),
    updatedAt: parseISO(orderLineItem.updated_at),
    deletedAt: orderLineItem.deleted_at ? parseISO(orderLineItem.deleted_at) : null,
  });

  serialiseOrderShippingLine = (orderShippingLine: OrderShippingLineResponse): OrderShippingLineModel => ({
    id: orderShippingLine.id,
    merchantId: orderShippingLine.merchant_id,
    orderId: orderShippingLine.order_id,
    title: orderShippingLine.title,
    price: orderShippingLine.price,
    source: orderShippingLine.source,
    phone: orderShippingLine.phone,
    deliveryCategory: orderShippingLine.delivery_category,
    carrierIdentifier: orderShippingLine.carrier_identifier,
    createdAt: parseISO(orderShippingLine.created_at),
    updatedAt: parseISO(orderShippingLine.updated_at),
    deletedAt: orderShippingLine.deleted_at ? parseISO(orderShippingLine.deleted_at) : null,
  });

  serialiseOrderTransaction = (orderTransaction: OrderTransactionResponse): OrderTransactionModel => ({
    id: orderTransaction.id,
    merchantId: orderTransaction.merchant_id,
    orderId: orderTransaction.order_id,
    amount: orderTransaction.amount,
    type: orderTransaction.type,
    creditCardCompany: orderTransaction.credit_card_company,
    source: orderTransaction.source,
    kind: orderTransaction.kind,
    createdAt: parseISO(orderTransaction.created_at),
    updatedAt: parseISO(orderTransaction.updated_at),
    deletedAt: orderTransaction.deleted_at ? parseISO(orderTransaction.deleted_at) : null,
  });

  serialiseOrderModelToOrderList = (order: OrderModel): OrderListModel => ({
    id: order.id,
    orderName: order.name,
    firstName: order.customer?.people[0]?.firstName || '',
    lastName: order.customer?.people[0]?.lastName || '',
    email: order.email,
    totalPrice: order.totalPrice,
    currency: order.currency,
    state: order.state,
    status: order.status,
    failedCount: 0,
    successCount: 0,
    riskScore: order.assessments[0].riskScore || 0,
    orderDate: order.orderDate,
    createdAt: order.createdAt,
    updatedAt: order.updatedAt,
  });
}

export const orderService = new OrderService();
