import React, { memo, useEffect, useState } from 'react';
import { Loader, Page, PageContent, Text } from '@spotrisk/common';
import { integrationResource } from '@spotrisk/integration';

const dashboardUrl = window.location.hostname.includes('local') ? 'http://localhost:3000/dashboard' : 'https://app.spotriskhq.com/dashboard';

export const ShopifyRedirect = memo(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const isEmbedded = urlParams.get('embedded');
  const shop = urlParams.get('shop');

  const [redirectUrl, setRedirectUrl] = useState('');
  const [error, setError] = useState(!shop ? 'Missing shop in url' : undefined);

  useEffect(() => {
    (async () => {
      if (isEmbedded || error) {
        return;
      }

      const configurations = await integrationResource.getConfigurations();
      const shopifyConfiguration = configurations.find((configuration) => configuration.name === 'shopify');

      if (!shopifyConfiguration) {
        setError('Oops, something went wrong.');
        return;
      }

      setRedirectUrl(
        `https://${shop}/admin/oauth/authorize?client_id=${shopifyConfiguration.clientId}&scope=${shopifyConfiguration.scopes}&redirect_uri=${shopifyConfiguration.redirectURL}`
      );
    })();
  });

  if (isEmbedded) {
    window.location.href = dashboardUrl;

    return <Loader className="mt-48" size="lg" />;
  }

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }

  return (
    <Page>
      <PageContent className="min-h-screen flex items-center justify-center">
        <div className="text-center mb-4">
          <img src="https://cdn.spotriskhq.com/cdn/assets/public/img/logo-long.svg" className="w-16 mx-auto" alt="logo" />
          {!error ? (
            <h1 className="mt-5">Redirecting you for install... </h1>
          ) : (
            <div id="error-message" className="text-center mb-5 mt-5">
              <Text variant="danger">{error}. Please try again, our support team has been notified.</Text>
            </div>
          )}
        </div>
      </PageContent>
    </Page>
  );
});
