import React from 'react';

import { AppHeaderActions, AppHeaderMerchantSwitcher, AppHeaderNavLinks, AppHeaderSearch } from '@spotrisk/app';
import { Header } from '@spotrisk/common';
import { ORDER_ROUTES } from '@spotrisk/order';

export const AppHeader = () => (
  <Header className="shadow-sm">
    <div className="flex flex-wrap md:flex-nowrap items-center w-full max-w-screen-xl mx-auto">
      <div className="flex w-11/12 md:w-full order-1 ">
        <AppHeaderNavLinks />
      </div>
      <div className="flex justify-end w-1/12 order-2 md:order-3">
        <AppHeaderActions />
      </div>
      <div className="flex flex-wrap-reverse md:flex-nowrap w-full md:w-11/12 order-3 md:order-2 mt-2 md:mt-0">
        {!window.location.pathname.startsWith(ORDER_ROUTES.list) || <AppHeaderSearch />}
        <AppHeaderMerchantSwitcher />
      </div>
    </div>
  </Header>
);
