import { useAsyncEffect } from '@spotrisk/common/hooks';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { authService } from '../auth.service';
import { Hr, Loader, Page, PageContent, Text } from '@spotrisk/common';
import React from 'react';

export const MasqueradeRedirectPage = memo(() => {
  const { token } = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useAsyncEffect(async () => {
    authService.logout();

    if (!token) {
      setError('Invalid token');
      return;
    }

    try {
      await authService.masquerade({ token });
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setError('An unexpected error occurred');
    }
  }, [token]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (isSuccess) {
      window.location.href = '/dashboard';
    }
  }, [isSuccess]);

  return (
    <Page>
      <PageContent isCentered>
        <div className="max-w-lg z-10">
          <div className="mb-4">
            <img className="w-20" src="https://cdn.spotriskhq.com/cdn/assets/public/img/logo-long.svg" alt="spotrisk-logo" />
          </div>
          <Hr />
          {isLoading && <Loader />}
          {error && <Text>{error}</Text>}
        </div>
      </PageContent>
      <footer className="fixed bottom-0 left-0 w-full h-10 flex items-center justify-center">
        <img className="w-full" src="https://cdn.spotriskhq.com/cdn/assets/public/img/testimonials-bottom.svg" alt="bottom-banner" />
      </footer>
    </Page>
  );

  return <Loader className="mt-48" size="lg" />;
});
