import { Text } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { EmailDigests, settingsActions, SettingsModel, settingsResource, settingsSelectors } from '@spotrisk/settings';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const NotificationPage = memo(() => {
  const dispatch = useDispatch();

  const merchant = useSelector(merchantSelectors.getMerchant);
  const settings = useSelector(settingsSelectors.getSettings);

  if (!merchant || !settings) {
    return null;
  }

  const updateSettings = async (settingsUpdate: Partial<SettingsModel>) => {
    const updatedSettings = await settingsResource.updateSettings({ merchant, settingsUpdate });
    dispatch(settingsActions.setSettings(updatedSettings));
  };

  return (
    <div className="w-full">
      <div>
        <Text className="text-xl text-header font-bold mr-2 antialiased block tracking-wide">Notifications</Text>
        <Text className="text-md text-header font-bold mr-2 antialiased block tracking-wide mt-5">Email Digests</Text>
        <Text className="text-xs font-header text-header font-light block mt-2 mb-7">
          Get a weekly or monthly risk summary for your store, delivered straight to your inbox.
        </Text>
      </div>
      <EmailDigests merchant={merchant} settings={settings} updateSettings={updateSettings} />
    </div>
  );
});
