export type UiType = 'ready' | 'loading' | 'saving' | 'error';

export type CommonReducerState = {
  ui: UiType;
};

export const SET_UI = 'common|SET_UI' as const;

type SetUiAction = {
  type: typeof SET_UI;
  ui: UiType;
};

export type CommonActionTypes = SetUiAction;
