import { CommonActionTypes, SET_UI, UiType } from './common.types';

class CommonActions {
  setUi = (ui: UiType): CommonActionTypes => ({
    type: SET_UI,
    ui,
  });
}

export const commonActions = new CommonActions();
