import countries, { Country } from '@assets/countries';
import { Button, Dropdown, DropdownContent, Icon, ModalContent, ModalFooter, ModalHeader, Text, useModal } from '@spotrisk/common';
import { customRuleActions, CustomRuleListModel, customRuleResource } from '@spotrisk/custom-rule';
import { MerchantModel } from '@spotrisk/merchant';
import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  ruleList: CustomRuleListModel;
  merchant: MerchantModel;
};

const removeSelectedCountries = (countries: Country[], selectedCountries: Country[]): Country[] =>
  countries.filter((country) => !selectedCountries.some((selectedCountry) => selectedCountry.code === country.code));

export const CountryBlockListModal = ({ ruleList, merchant }: Props) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedCountries, setSelectedCountries] = useState(
    (ruleList.list.map((code) => countries.find((country) => country.code === code)) as Country[]).sort((a: Country, b: Country) =>
      a.name > b.name ? 1 : -1
    )
  );
  const [filteredCountries, setFilteredCountries] = useState(removeSelectedCountries(countries, selectedCountries));

  const inputClasses = cn('midnight py-2 text-sm bg-gray-100 pl-11 h-12 w-full form-input', {
    'rounded-t-md rounded-b-none': isOpen,
    'rounded-md': !isOpen,
  });

  const handleFilterCountries = (filter: string) => {
    setFilteredCountries(
      removeSelectedCountries(
        countries.filter((country) => country.name.toLowerCase().includes(filter.toLowerCase())),
        selectedCountries
      )
    );
    setFilter(filter);
  };

  const handleAdd = (country: Country) => {
    const update = [country, ...selectedCountries].sort((a: Country, b: Country) => (a.name > b.name ? 1 : -1));
    setSelectedCountries(update);
    setFilteredCountries(removeSelectedCountries(countries, update));
    setIsOpen(false);
    setFilter('');
  };

  const handleRemove = (index: number) => {
    const update = [...selectedCountries];
    update.splice(index, 1);
    setSelectedCountries(update);
  };

  const handleSubmit = async () => {
    setIsSaving(true);

    const update = {
      ...ruleList,
      list: selectedCountries.map((country) => country.code),
    };

    await customRuleResource.updateCustomRuleList({ merchantId: merchant.id, customRuleList: update });
    dispatch(customRuleActions.updateCustomRuleList(update));

    closeModal();
  };

  return (
    <div className="max-w-lg" style={{ minWidth: '550px' }}>
      <ModalHeader>
        <Text className="text-xl text-header font-semibold mb-3" isBlock>
          Country block list
        </Text>
        <Text className="text-sm text-header" isBlock>
          Spotrisk allows you fully block a specific country.
        </Text>
      </ModalHeader>
      <ModalContent className="pt-0">
        <div className="relative">
          <div className="relative w-full">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <Icon icon="search" className="w-6 h-6 stroke-2 text-gray-400" />
            </span>
            <input
              className={inputClasses}
              placeholder="Search Country"
              value={filter}
              autoComplete="off"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterCountries(e.target.value)}
              onFocus={() => setIsOpen(true)}
              ref={inputRef}
            />
          </div>
          <Dropdown
            className="w-full max-h-96 overflow-y-auto rounded-b-md rounded-t-none dropdown-search"
            onClickOutside={() => setIsOpen(false)}
            onClose={() => setIsOpen(false)}
            toggleRef={inputRef}
            isOpen={isOpen}
          >
            {filteredCountries.map((country) => (
              <DropdownContent
                key={country.code}
                className="group flex justify-between hover:bg-gray-100"
                onClick={() => handleAdd(country)}
                hasBorder={false}
              >
                <Text className="font-medium text-gray-550">
                  {country.emoji} {country.name}
                </Text>
                <Text className="font-medium opacity-0 group-hover:opacity-100" variant="danger">
                  Block
                </Text>
              </DropdownContent>
            ))}
          </Dropdown>
        </div>
        {selectedCountries.length ? (
          <div className="mt-6">
            Blocked countries
            <div className="mt-6 max-h-60 overflow-y-auto">
              {selectedCountries.map((country, index) => (
                <div key={index} className="flex justify-between items-center w-full my-2.5 p-4 block-list-item">
                  <div>
                    {country.emoji} {country.name}
                  </div>
                  <Button variant="danger" state="text" onClick={() => handleRemove(index)}>
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-4" />
        )}
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} variant="gray" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button className="ml-2" variant="blue" type="submit" onClick={handleSubmit} isDisabled={isSaving}>
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};
