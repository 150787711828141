import React, { memo } from 'react';
import { Page, PageContent } from '@spotrisk/common';
import { ActivityList } from '../components';
import { ActivityListHeader } from '../components/activity-list-header.component';

export const BlockyListActivityPage = memo(() => (
  <Page>
    <PageContent className="min-h-screen">
      <div className="flex max-w-screen-xl mx-auto mb-12">
        <div className="hidden md:block md:w-1/2 md:pr-16">
          <ActivityListHeader />
        </div>
        <div className="w-full md:w-1/2">
          <ActivityList />
        </div>
      </div>
    </PageContent>
  </Page>
));
