import { apiService } from '@spotrisk/common';
import { MerchantModel } from '@spotrisk/merchant';
import {
  SettingsModel,
  SettingsResponse,
  settingsService,
  EnableBlockyResponse,
  MerchantIntegrationModel,
  MerchantIntegrationResponse,
  MerchantWebhookConfigurationModal,
  MerchantWebhookConfigurationResponse,
} from '@spotrisk/settings';

type GetSettingsParam = {
  merchant: MerchantModel;
};

type UpdateSettingsParam = {
  merchant: MerchantModel;
  settingsUpdate: Partial<SettingsModel>;
};

type GetMerchantIntegrationsParam = {
  merchantId: string;
};

class SettingsResource {
  getSettings = async ({ merchant }: GetSettingsParam): Promise<SettingsModel> => {
    const settings = await apiService.fetch<SettingsResponse>(`/ecommerce/merchant/${merchant.id}/setting`);
    if (!settings) {
      throw new Error('Failed to fetch settings');
    }

    return settingsService.serialiseSettingsResponse(settings);
  };

  updateSettings = async ({ merchant, settingsUpdate }: UpdateSettingsParam): Promise<SettingsModel> => {
    const settings = await apiService.fetch<SettingsResponse>(`/ecommerce/merchant/${merchant.id}/setting`, {
      method: 'PUT',
      data: settingsService.serialiseSettingsRequest(settingsUpdate),
    });

    if (!settings) {
      throw new Error('Failed to fetch settings');
    }

    return settingsService.serialiseSettingsResponse(settings);
  };

  toggleBlocky = async (enabled: boolean, { merchant }: GetSettingsParam): Promise<EnableBlockyResponse> => {
    const response = await apiService.fetch<EnableBlockyResponse>(`/ecommerce/merchant/${merchant.id}/blocky/enable`, {
      method: 'POST',
      data: { enabled },
    });

    if (!response) {
      throw new Error('Failed to enable blocky');
    }

    return response;
  };

  toggleUserEmailNotification = async (userId: string, { merchant }: GetSettingsParam): Promise<any> => {
    return await apiService.fetch(`/ecommerce/merchant/${merchant.id}/users/${userId}/email-notification`, {
      method: 'PUT',
    });
  };

  getMerchantIntegrations = async ({ merchantId }: GetMerchantIntegrationsParam): Promise<MerchantIntegrationModel[]> => {
    const integrations = await apiService.fetch<MerchantIntegrationResponse[]>(`/ecommerce/merchant/${merchantId}/integration`, {
      method: 'GET',
    });

    if (!integrations) {
      throw new Error('Failed to fetch integrations');
    }

    const response = integrations.map((integration) => settingsService.serialiseMerchantIntegrationsResponses(integration));

    return response;
  };

  postMerchantIntegration = async ({
    merchantId,
    integrationName,
  }: {
    merchantId: string;
    integrationName: string;
  }): Promise<MerchantIntegrationModel> => {
    const integration = await apiService.fetch<MerchantIntegrationResponse>(`/ecommerce/merchant/${merchantId}/integration`, {
      method: 'POST',
      data: {
        name: integrationName,
      },
    });

    if (!integration) {
      throw new Error('Failed to create integration');
    }

    const response = settingsService.serialiseMerchantIntegrationsResponses(integration);
    return response;
  };

  deleteMerchantIntegration = async ({ merchantId, integrationId }: { merchantId: string; integrationId: string }): Promise<any> => {
    return await apiService.fetch(`/ecommerce/merchant/${merchantId}/integration/${integrationId}`, {
      method: 'DELETE',
    });
  };

  getIntegrationWebhooksByIntegrationId = async ({ merchantId, integrationId }: { merchantId: string; integrationId: string }): Promise<any> => {
    const webhookConfigurations = await apiService.fetch<MerchantWebhookConfigurationResponse[]>(
      `/ecommerce/merchant/${merchantId}/integration/${integrationId}/webhook`,
      {
        method: 'GET',
      }
    );

    if (!webhookConfigurations) {
      throw new Error('Failed to fetch webhook configurations');
    }

    const response = webhookConfigurations.map((webhookConfiguration) =>
      settingsService.serialiseMerchantWebhookConfigurationResponses(webhookConfiguration)
    );

    return response;
  };

  postMerchantIntegrationWebhook = async ({
    merchantId,
    integrationId,
    webhookConfiguration,
  }: {
    merchantId: string;
    integrationId: string;
    webhookConfiguration: MerchantWebhookConfigurationModal;
  }): Promise<MerchantWebhookConfigurationModal> => {
    const webhookConfigurationResponse = await apiService.fetch<MerchantWebhookConfigurationResponse>(
      `/ecommerce/merchant/${merchantId}/integration/${integrationId}/webhook`,
      {
        method: 'POST',
        data: {
          events: webhookConfiguration.events,
          url: webhookConfiguration.url,
          enabled: webhookConfiguration.enabled,
        },
      }
    );

    if (!webhookConfigurationResponse) {
      throw new Error('Failed to create webhook configuration');
    }

    const response = settingsService.serialiseMerchantWebhookConfigurationResponses(webhookConfigurationResponse);
    return response;
  };

  putMerchantIntegrationWebhook = async ({
    merchantId,
    integrationId,
    webhookId,
    webhookConfiguration,
  }: {
    merchantId: string;
    integrationId: string;
    webhookId: string;
    webhookConfiguration: MerchantWebhookConfigurationModal;
  }): Promise<MerchantWebhookConfigurationModal> => {
    const webhookConfigurationResponse = await apiService.fetch<MerchantWebhookConfigurationResponse>(
      `/ecommerce/merchant/${merchantId}/integration/${integrationId}/webhook/${webhookId}`,
      {
        method: 'PUT',
        data: {
          events: webhookConfiguration.events,
          url: webhookConfiguration.url,
          enabled: webhookConfiguration.enabled,
        },
      }
    );

    if (!webhookConfigurationResponse) {
      throw new Error('Failed to update webhook configuration');
    }

    const response = settingsService.serialiseMerchantWebhookConfigurationResponses(webhookConfigurationResponse);
    return response;
  };

  deleteMerchantIntegrationWebhook = async ({
    merchantId,
    integrationId,
    webhookId,
  }: {
    merchantId: string;
    integrationId: string;
    webhookId: string;
  }): Promise<any> => {
    return await apiService.fetch(`/ecommerce/merchant/${merchantId}/integration/${integrationId}/webhook/${webhookId}`, {
      method: 'DELETE',
    });
  };
}

export const settingsResource = new SettingsResource();
