import React, { memo } from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  variant?: 'dark' | 'gray' | 'light' | 'success' | 'warning' | 'danger' | 'info' | 'primary';
  isLoading?: boolean;
  isBlock?: boolean;
};

const BASE_CLASSES = ['bg-gray-300', 'rounded-md'];

export const TextSkeleton = memo(({ className, variant, isLoading = true, isBlock }: Props) => {
  const classes = cn(BASE_CLASSES, className, {
    'inline-block': !isBlock,
    'animate-pulse': isLoading,
    'bg-gray-300': !variant,
    'bg-gray-900': variant === 'dark',
    'bg-gray-500': variant === 'gray',
    'bg-white': variant === 'light',
    'bg-green-500': variant === 'success',
    'bg-orange-500': variant === 'warning',
    'bg-red-600': variant === 'danger',
    'bg-blue-500': variant === 'info',
    'bg-blue-900': variant === 'primary',
  });

  return <div className={classes} />;
});
