import React, { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CUSTOMER_ROUTES, CustomerListPage } from '@spotrisk/customer';

export const CustomerLayout = memo(() => (
  <Switch>
    <Route path={CUSTOMER_ROUTES.list} component={CustomerListPage} exact />
    <Redirect to={CUSTOMER_ROUTES.list} />
  </Switch>
));
