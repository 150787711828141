import {
  CustomRuleActionTypes,
  CustomRuleReducerState,
  GET_CUSTOM_RULES,
  SET_CUSTOM_RULE_LISTS,
  SET_CUSTOM_RULES,
  UPDATE_CUSTOM_RULE,
  UPDATE_CUSTOM_RULE_LIST,
} from '@spotrisk/custom-rule';

const initialState: CustomRuleReducerState = {
  customRules: [],
  customRuleLists: [],
  status: 'ready',
};

export const customRuleReducer = (state = initialState, action: CustomRuleActionTypes): CustomRuleReducerState => {
  switch (action.type) {
    case GET_CUSTOM_RULES:
      return {
        ...state,
        status: 'loading',
      };
    case SET_CUSTOM_RULES:
      return {
        ...state,
        customRules: action.customRules,
        status: 'ready',
      };
    case UPDATE_CUSTOM_RULE:
      return {
        ...state,
        customRules: state.customRules.map((customRule) =>
          customRule.id === action.customRule.id
            ? {
                ...customRule,
                ...action.customRule,
              }
            : customRule
        ),
      };
    case SET_CUSTOM_RULE_LISTS:
      return {
        ...state,
        customRuleLists: action.customRuleLists,
        status: 'ready',
      };
    case UPDATE_CUSTOM_RULE_LIST:
      return {
        ...state,
        customRuleLists: state.customRuleLists.map((ruleList) =>
          ruleList.id === action.customRuleList.id
            ? {
                ...ruleList,
                ...action.customRuleList,
              }
            : ruleList
        ),
      };
    default:
      return state;
  }
};
