import React from 'react';
import cn from 'classnames';
import SpinnerIconSvg from '@assets/svgs/icon-spinner.svg';

type Props = {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary';
};

const BASE_CLASSES = ['animate-spin'];

export const Loader = ({ className, variant = 'primary', size = 'md' }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    ultramarine: variant === 'primary',
    white: variant === 'secondary',
    'h-6 w-6': size === 'sm',
    'h-8 w-8': size === 'md',
    'h-12 w-12': size === 'lg',
  });

  return (
    <div className="flex justify-center content-center">
      <SpinnerIconSvg className={classes} />
    </div>
  );
};
