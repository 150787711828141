import { parseISO } from 'date-fns';
import { filter, includes, map, toLower } from 'lodash';
import { IconType } from '@spotrisk/common';
import { AssessmentModel, AssessmentResponse, AssessmentResultModel, AssessmentResultResponse } from '@spotrisk/assessment';
import { AssessmentResultCheckResponse, AssessmentResultCheckModel } from './assessment.models';

class AssessmentService {
  getConcernCount = (assessment: AssessmentModel) => {
    return filter(assessment.results, ({ status }) => status === 'FAILED').length;
  };

  getBuyerResults = (assessment: AssessmentModel) => {
    return filter(assessment.results, ({ type }) => type === 'BUYER');
  };

  getOrderResults = (assessment: AssessmentModel) => {
    return filter(assessment.results, ({ type }) => type === 'ORDER');
  };

  getPaymentResults = (assessment: AssessmentModel) => {
    return filter(assessment.results, ({ type }) => type === 'PAYMENT');
  };

  getFailedResultCheckCount = (result: AssessmentResultModel) => {
    return filter(result.checks, ({ isSuccess }) => !isSuccess).length;
  };

  getSuccessResultCheckCount = (result: AssessmentResultModel) => {
    return filter(result.checks, ({ isSuccess }) => isSuccess).length;
  };

  getAssessmentResultIcon = (result: AssessmentResultModel): IconType => {
    const messageToCheck = toLower(result.message);

    if (includes(messageToCheck, 'ip address')) {
      return 'map-marker';
    } else if (includes(messageToCheck, 'email')) {
      return 'email';
    } else if (includes(messageToCheck, 'browsing')) {
      return 'web';
    } else if (includes(messageToCheck, 'ship')) {
      return 'truck';
    } else if (includes(messageToCheck, 'size')) {
      return 'basket';
    } else if (includes(messageToCheck, 'payment')) {
      return 'credit-card';
    }

    return 'grid';
  };

  serialiseAssessment = (assessment: AssessmentResponse): AssessmentModel => {
    return {
      id: assessment.id,
      merchantId: assessment.merchant_id,
      orderId: assessment.order_id,
      state: assessment.state,
      results: map(assessment.results, this.serialiseAssessmentResult),
      failedCount: assessment.failed_count,
      successCount: assessment.success_count,
      riskScore: assessment.risk_score,
      createdAt: parseISO(assessment.created_at),
      updatedAt: parseISO(assessment.updated_at),
      deletedAt: assessment.deleted_at ? parseISO(assessment.deleted_at) : null,
    };
  };

  serialiseAssessmentResult = (assessmentResult: AssessmentResultResponse): AssessmentResultModel => ({
    id: assessmentResult.id,
    assessmentId: assessmentResult.assessment_id,
    merchantId: assessmentResult.merchant_id,
    status: assessmentResult.status,
    type: assessmentResult.type,
    title: assessmentResult.title,
    message: assessmentResult.message,
    detail: assessmentResult.detail,
    checks: map(
      assessmentResult.checks,
      (check: AssessmentResultCheckResponse) =>
        ({
          id: check.id,
          assessmentResultId: check.assessment_result_id,
          merchantId: check.merchant_id,
          detail: check.detail,
          message: check.message,
          isSuccess: check.is_success,
          riskValue: check.risk_value,
          createdAt: parseISO(check.created_at),
          updatedAt: parseISO(check.updated_at),
          deletedAt: check.deleted_at ? parseISO(check.deleted_at) : null,
        } as AssessmentResultCheckModel)
    ),
    createdAt: parseISO(assessmentResult.created_at),
    updatedAt: parseISO(assessmentResult.updated_at),
    deletedAt: assessmentResult.deleted_at ? parseISO(assessmentResult.deleted_at) : null,
  });
}

export const assessmentService = new AssessmentService();
