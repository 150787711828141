import { BillingReducerState, BillingActionTypes, SET_BILLING } from './billing.types';

const initialState: BillingReducerState = {};

export const billingReducer = (state = initialState, action: BillingActionTypes) => {
  switch (action.type) {
    case SET_BILLING:
      return {
        ...state,
        billing: action.billing,
      };
    default:
      return state;
  }
};
