import { localStorageService } from '@spotrisk/common';
import { SpotriskJWTModel } from '@spotrisk/user';
import { authResource } from '@spotrisk/auth';
import { Buffer } from 'buffer';
// @ts-ignore
window.Buffer = Buffer;

class AuthService {
  setAccessToken = (accessToken: string) => {
    localStorageService.setItem('spotrisk-access-token', accessToken);
  };

  setRefreshToken = (refreshToken: string) => {
    localStorageService.setItem('spotrisk-refresh-token', refreshToken);
  };

  isTokenValid = (token: string) => {
    const decodedToken = this.decodeToken(token);
    return decodedToken ? decodedToken.exp > Math.floor(Date.now() / 1000) : false;
  };

  setTokens = ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  };

  removeTokens = () => {
    localStorageService.removeItem('spotrisk-access-token');
    localStorageService.removeItem('spotrisk-refresh-token');
  };

  login = async ({ email, password }: { email: string; password: string }) => {
    const response = await authResource.login({ email, password });
    if (!this.isTokenValid(response.accessToken)) {
      throw new Error('Invalid access token');
    }

    this.setTokens(response);
  };

  logout = () => {
    this.removeTokens();
  };

  masquerade = async ({ token }: { token: string }) => {
    const response = await authResource.masquerade({ token });
    if (!this.isTokenValid(response.accessToken)) {
      throw new Error('Invalid access token');
    }

    this.setTokens(response);
  };

  refresh = async ({ refreshToken }: { refreshToken: string }) => {
    const response = await authResource.refreshToken({ refreshToken });
    if (!this.isTokenValid(response.accessToken)) {
      throw new Error('Invalid access token');
    }

    this.setTokens(response);
  };

  decodeToken = (token?: string) => {
    if (!token) {
      return null;
    }

    try {
      const base64Payload = token.split('.')[1];
      const payloadBuffer = Buffer.from(base64Payload, 'base64');
      return JSON.parse(payloadBuffer.toString()) as SpotriskJWTModel;
    } catch (error) {
      return null;
    }
  };
}

export const authService = new AuthService();
