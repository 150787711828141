import { Badge, Hr, Icon, ListSummaryCard, Text, utilService } from '@spotrisk/common';
import { orderActions, ORDER_ROUTES, OrderListModel, orderService } from '@spotrisk/order';
import { format } from 'date-fns';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';

export const OrderListSummaryCard = memo(({ order, isDisabled }: { order: OrderListModel; isDisabled?: boolean }) => {
  const dispatch = useDispatch();
  const isMedium = window.outerWidth >= 768;

  const handleOrderSelect = () => {
    dispatch(orderActions.setOrder(order));
  };

  const concernCount = order.failedCount;

  return (
    <ListSummaryCard onClick={handleOrderSelect} href={generatePath(ORDER_ROUTES.view, { orderId: order.id })} isDisabled={isDisabled}>
      <div className="md:flex">
        <Text className="text-lg text-header font-semibold mr-4 md:mr-10">Order {order.orderName}</Text>
        <div className="w-full mt-2 md:mt-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center mr-2">
              <Text className="text-lg text-header font-semibold mr-2">
                {isMedium ? orderService.getFullName(order) : orderService.getAbbreviatedName(order)}
              </Text>
              {order.state !== 'PASSED' ? (
                <Badge variant={concernCount > 3 ? 'danger' : 'info'} state={concernCount > 4 ? 'solid' : 'opaque'}>
                  <Icon
                    className={`mr-1 ${concernCount < 5 ? (concernCount < 4 ? 'text-blue-600' : 'text-red-500') : 'text-white'}`}
                    icon="error-octagon"
                  />
                  {concernCount}
                </Badge>
              ) : null}
            </div>
            <Text className="text-lg text-header font-semibold">{utilService.formatCurrencyAmount(order.totalPrice, order.currency)}</Text>
          </div>
          <Hr marginSmall />
          <div className="flex justify-between">
            <Text className="text-xs text-header opacity-40 font-medium mr-2" isBlock>
              {order.email}
            </Text>
            <Text className="text-xs text-header opacity-40 font-medium">{format(order.orderDate, isMedium ? 'dd MMM yyyy' : 'dd/MM/yy')}</Text>
          </div>
        </div>
      </div>
    </ListSummaryCard>
  );
});
