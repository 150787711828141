import React, { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ORDER_ROUTES, OrderListPage, OrderViewPage } from '@spotrisk/order';

export const OrderLayout = memo(() => (
  <Switch>
    <Route path={ORDER_ROUTES.list} component={OrderListPage} exact />
    <Route path={ORDER_ROUTES.view} component={OrderViewPage} exact />
    <Redirect to={ORDER_ROUTES.list} />
  </Switch>
));
