import { assessmentActions } from '@spotrisk/assessment';
import { Icon } from '@spotrisk/common';
import { customerActions, CustomerModel } from '@spotrisk/customer';
import { merchantSelectors } from '@spotrisk/merchant';
import { orderActions, orderResource } from '@spotrisk/order';
import { filter } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const AppHeaderSearch = memo(() => {
  const dispatch = useDispatch();
  const merchant = useSelector(merchantSelectors.getMerchant);

  const [query, setQuery] = useState('');

  useEffect(() => {
    return () => {
      dispatch(orderActions.setSearch());
    };
  }, []);

  if (!merchant) {
    return null;
  }

  const searchOrders = async () => {
    dispatch(orderActions.getOrders());
    dispatch(orderActions.setSearch(query));

    const orders = await orderResource.searchOrders({ merchant, query });
    dispatch(orderActions.setOrders(orders));
  };

  return (
    <section className="flex flex-row justify-start w-full mt-2 md:mt-0">
      <div className="relative w-full">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <button type="submit" className="p-1 focus:outline-none" onClick={() => searchOrders()}>
            <Icon icon="search" className="w-6 h-6 stroke-2 text-gray-400" />
          </button>
        </span>
        <input
          className="midnight py-2 text-sm bg-gray-100 rounded-md pl-11 h-12 w-full focus:outline-none"
          placeholder="Search Email or Order Number"
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              searchOrders();
            }
          }}
        />
      </div>
    </section>
  );
});
