import {
  CustomRuleActionTypes,
  CustomRuleListModel,
  CustomRuleModel,
  GET_CUSTOM_RULES,
  SET_CUSTOM_RULE_LISTS,
  SET_CUSTOM_RULES,
  UPDATE_CUSTOM_RULE,
  UPDATE_CUSTOM_RULE_LIST,
} from '@spotrisk/custom-rule';

class CustomRuleActions {
  getCustomRules = (): CustomRuleActionTypes => ({
    type: GET_CUSTOM_RULES,
  });

  setCustomRules = (customRules: CustomRuleModel[]): CustomRuleActionTypes => ({
    type: SET_CUSTOM_RULES,
    customRules,
  });

  updateCustomRule = (customRule: CustomRuleModel): CustomRuleActionTypes => ({
    type: UPDATE_CUSTOM_RULE,
    customRule,
  });

  setCustomRuleLists = (customRuleLists: CustomRuleListModel[]): CustomRuleActionTypes => ({
    type: SET_CUSTOM_RULE_LISTS,
    customRuleLists,
  });

  updateCustomRuleList = (customRuleList: CustomRuleListModel): CustomRuleActionTypes => ({
    type: UPDATE_CUSTOM_RULE_LIST,
    customRuleList,
  });
}

export const customRuleActions = new CustomRuleActions();
