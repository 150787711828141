import { Button, ModalFooter, ModalHeader, Text, useModal } from '@spotrisk/common';
import React from 'react';

type Props = {
  body: JSX.Element | string;
  ruleId: string;
  toggleRule: (ruleId: string) => void;
};

export const CustomRuleConfirmationModal = ({ body, ruleId, toggleRule }: Props) => {
  const { closeModal } = useModal();

  const handleRuleToggle = () => {
    toggleRule(ruleId);
    closeModal();
  };

  return (
    <div className="max-w-lg" style={{ minWidth: '550px' }}>
      <ModalHeader>
        <Text className="text-xl text-header text-center font-semibold mb-3" isBlock>
          Please confirm
        </Text>
        <Text className="text-sm text-header text-center" isBlock>
          {body}
        </Text>
      </ModalHeader>
      <ModalFooter className="flex justify-center">
        <Button className="mr-2.5" onClick={closeModal} variant="gray">
          Cancel
        </Button>
        <Button className="ml-2.5" variant="blue" type="submit" onClick={handleRuleToggle}>
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};
