import { billingService, SubscriptionChargeModel, SubscriptionChargeResponse } from '@spotrisk/billing';
import { apiService } from '@spotrisk/common';
import { MerchantModel } from '@spotrisk/merchant';

type UpdatePlanParam = {
  merchant: MerchantModel;
  plan: SubscriptionChargeModel;
};

type CancelPlanParam = {
  merchant: MerchantModel;
};

class BillingResource {
  updatePlan = async ({ merchant, plan }: UpdatePlanParam): Promise<SubscriptionChargeModel> => {
    const response = await apiService.fetch<SubscriptionChargeResponse>(`/ecommerce/merchant/${merchant.id}/subscription_charge`, {
      method: 'POST',
      data: billingService.serialiseSubscriptionChargeRequest(plan),
    });

    return billingService.serialiseSubscriptionChargeResponse(response);
  };

  cancelPlan = async ({ merchant }: CancelPlanParam): Promise<void> => {
    await apiService.fetch(`/ecommerce/merchant/${merchant.id}/subscription`, {
      method: 'DELETE',
    });
  };
}

export const billingResource = new BillingResource();
