import { Button, ModalContent, ModalFooter, Text, useModal, Input, useToast, ModalHeader } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { isAxiosError } from '@spotrisk/util/axios';
import { userResource } from '@spotrisk/user';

type AddUserModalProps = {
  onSuccess: () => void;
};

export const AddUserModal: React.FC<AddUserModalProps> = ({ onSuccess }) => {
  const { closeModal } = useModal();
  const { addToast } = useToast();
  const [email, setEmail] = useState('');
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [isError, setIsError] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  if (!merchant) {
    return null;
  }

  const inviteUser = async () => {
    try {
      setIsError(false);
      const userInvitation = await userResource.inviteUser(email, { merchant });
      if (userInvitation.id) {
        addToast(<Text>Invite sent to {email}</Text>);
        closeModal();
        setEmail('');
        onSuccess();
      }
    } catch (err) {
      if (isAxiosError(err) && err.response && err.response.data) {
        const { message } = err.response.data;
        setErrMessage(message);
        setIsError(true);
      } else {
        setIsError(true);
      }
    }
  };

  const errorMessage = () => {
    if (isError) {
      if (!errMessage) {
        return <Text className="text-red-500 mb-4 block">Something went wrong, check email and please try again.</Text>;
      }
      // errMessage capitlise first letter

      return <Text className="text-red-500 mb-4 block">{errMessage}</Text>;
    }
  };

  return (
    <div className="max-w-lg">
      <ModalHeader className="bg-gray-200 bg-opacity-50">
        <Text className="text-lg text-header font-semibold mr-2"> Invite user to Spotrisk</Text>
      </ModalHeader>
      <ModalContent>
        <Text className="text-sm text-header mb-4" isBlock>
          Please enter an email address for the user, and we will send them an invitation email to join Spotrisk.
        </Text>
        <div>
          <Text className="text-header font-semibold mb-1" isBlock>
            Email address
          </Text>
          <Input className="text-xl p-2 h-12 w-full" type="email" value={email} onChange={({ target }) => setEmail(target.value)} autoFocus />
        </div>
        <div>{errorMessage()}</div>
      </ModalContent>
      <ModalFooter className="flex justify-end">
        <Button className="mr-2" onClick={closeModal} variant="gray">
          Cancel
        </Button>
        <Button onClick={inviteUser} variant="blue">
          Invite user
        </Button>
      </ModalFooter>
    </div>
  );
};
