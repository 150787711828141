import React, { memo, useState } from 'react';
import cn from 'classnames';
import { Dropdown, DropdownContent, Text } from '@spotrisk/common';

export type Option = {
  label: string;
  code: string;
};

type Props = {
  onSelect: (option: Option) => void;
  options: Option[];
};

const BASE_CLASSES = ['flex', 'whitespace-nowrap', 'items-center', 'font-header', 'text-header', 'text-xs'];

export const SortDropdown = memo(({ options, onSelect }: Props) => {
  const classes = cn(BASE_CLASSES);
  const buttonClasses = cn('w-10 opacity-100', BASE_CLASSES);
  const labelClasses = cn('mr-2 uppercase opacity-40', BASE_CLASSES);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const handleSelect = (option: Option) => {
    setSelected(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <section className="relative flex items-center justify-end md:w-3/12 hidden md:block">
      <div className={classes}>
        <Text className={labelClasses}>SORT BY</Text>
        <button className={buttonClasses} onClick={() => setIsOpen(!isOpen)}>
          {selected.label}
        </button>
      </div>
      <Dropdown
        className="w-30 top-5 right-0 overflow-hidden"
        onClickOutside={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        {options.map((option) => (
          <DropdownContent key={option.code} onClick={() => handleSelect(option)}>
            <Text className={classes} isBlock>
              {option.label}
            </Text>
          </DropdownContent>
        ))}
      </Dropdown>
    </section>
  );
});
