import { UserModel } from '@spotrisk/user';
import { UserActionTypes, SET_USER } from './user.types';

class UserActions {
  setUser = (user: UserModel | null): UserActionTypes => ({
    type: SET_USER,
    user,
  });
}

export const userActions = new UserActions();
