import React, { FormEvent, memo, useEffect, useState } from 'react';
import { AUTH_ROUTES, authService } from '@spotrisk/auth';
import { Page, PageContent, Text, Input, Button, Loader, spotriskAppUrl, Hr } from '@spotrisk/common';
import { IntegrationName } from '@spotrisk/integration';
import { userService } from '@spotrisk/user';

export const LoginPage = memo(() => {
  const dashboardUrl = `${spotriskAppUrl}/dashboard`;
  const signUpURL = new URL(`${spotriskAppUrl}${AUTH_ROUTES.signup}`);

  const urlParams = new URLSearchParams(window.location.search);
  const redirectParam = urlParams.get('redirect');
  const redirect = redirectParam ? decodeURIComponent(redirectParam) : undefined;
  const signature = urlParams.get('signature') ?? undefined;
  const integrationName = urlParams.get('integration') as IntegrationName;

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({ email: '', password: '' });

  useEffect(() => {
    (async () => {
      try {
        if (userService.hasAccessTokens()) {
          window.location.href = redirect ?? dashboardUrl;
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  });

  const handleSignUp = () => {
    setIsLoading(true);
    if (redirect) {
      signUpURL.searchParams.append('redirect', redirect);
    }
    if (signature) {
      signUpURL.searchParams.append('signature', signature);
    }
    if (integrationName) {
      signUpURL.searchParams.append('integration', integrationName);
    }
    window.location.href = signUpURL.href;
  };

  const handlePasswordReset = () => {
    setIsLoading(true);
    window.location.href = `${spotriskAppUrl}/forgot-password`;
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!formData.email || !formData.password) {
      setIsLoading(false);
      setError('Please enter your email and password.');
      return;
    }

    try {
      await authService.login({ email: formData.email, password: formData.password });
      setIsLoading(false);
      window.location.href = redirect ?? dashboardUrl;
      return;
    } catch (error) {
      setIsLoading(false);
      setError('Invalid email or password. Please try again.');
    }
  };

  if (isLoading) {
    return <Loader className="mt-48" size="lg" />;
  }

  return (
    <Page>
      <PageContent isCentered>
        <div className="max-w-lg z-10">
          <div className="mb-4">
            <img className="w-20" src="https://cdn.spotriskhq.com/cdn/assets/public/img/logo-long.svg" alt="spotrisk-logo" />
          </div>
          <Hr />
          <div className="bg-white p-7 rounded-lg shadow-xl border-gray-300">
            <div className="text-left mb-8">
              <div>
                <Text className="text-2xl font-bold mt-8">Sign in to your Spotrisk account</Text>
              </div>
              <div className="mt-2">
                <Text className="text-sm font-thin break-words ">
                  Welcome back! Sign in to your Spotrisk account to manage your orders, customers, and more.
                </Text>
              </div>
            </div>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
                  Email
                </label>
                <Input
                  id="email"
                  type="email"
                  placeholder="john.smith@company.com"
                  onChange={({ target }) => setFormData({ ...formData, email: target.value })}
                  value={formData.email}
                  className="h-12 shadow appearance-none border rounded-lg w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-gray-700 text-sm font-semibold mb-2">
                    Password
                  </label>
                  <Button state="text" onClick={handlePasswordReset} className="text-sm mb-2" variant="blue">
                    Forgot your password?
                  </Button>
                </div>
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  onChange={({ target }) => setFormData({ ...formData, password: target.value })}
                  value={formData.password}
                  className="h-12 shadow appearance-none border rounded-lg w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              {error && (
                <div id="error-message" className="text-red text-center mb-5 mt-5">
                  <Text variant="danger">{error}</Text>
                </div>
              )}
              <div className="text-center">
                <Button type="submit" className="text-white w-full" variant="blue" isDisabled={isLoading}>
                  {isLoading ? 'Logging in..' : 'Sign in'}
                </Button>
                <Hr />
                <Text className="text-center text-sm mr-1">New to Spotrisk?</Text>
                <Button className="text-sm " state="text" variant="blue" onClick={handleSignUp}>
                  Create Account
                </Button>
              </div>
            </form>
          </div>
        </div>
      </PageContent>
      <footer className="fixed bottom-0 left-0 w-full h-10 flex items-center justify-center">
        <img className="w-full" src="https://cdn.spotriskhq.com/cdn/assets/public/img/testimonials-bottom.svg" alt="bottom-banner" />
      </footer>
    </Page>
  );
});
