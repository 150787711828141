import { parseISO } from 'date-fns';
import { map, trim } from 'lodash';
import {
  CustomerAddressModel,
  CustomerAddressResponse,
  CustomerModel,
  CustomerPersonModel,
  CustomerPersonResponse,
  CustomerResponse,
} from '@spotrisk/customer';
import { orderService } from '@spotrisk/order';

class CustomerService {
  getFullName = (customer: CustomerModel) => trim(`${trim(customer.firstName || '')} ${trim(customer.lastName || '')}`);
  getAbbreviatedName = (customer: CustomerModel) => {
    let lastInitial = trim(customer.lastName || '')[0] || '';
    if (lastInitial) {
      lastInitial = `${lastInitial}.`;
    }

    return trim(`${trim(customer.firstName || '')} ${lastInitial}`);
  };

  getDisplayAddress = (customerAddress: CustomerAddressModel) => trim(`${trim(customerAddress.city || '')}, ${trim(customerAddress.country || '')}`);

  serialiseCustomer = (customer: CustomerResponse): CustomerModel => ({
    id: customer.id,
    merchantId: customer.merchant_id,
    email: customer.email,
    gravatarUrl: customer.gravatar_url,
    firstName: customer.first_name,
    lastName: customer.last_name,
    ordersCount: customer.orders_count,
    totalSpent: customer.total_spent,
    threatLevel: customer.threat_level,
    addresses: map(customer.addresses, this.serialiseCustomerAddress),
    people: map(customer.people, this.serialiseCustomerPerson),
    orders: map(customer.orders, orderService.serialiseOrder),
    isBlocked: customer.is_blocked,
    createdAt: parseISO(customer.created_at),
    updatedAt: parseISO(customer.updated_at),
    deletedAt: customer.deleted_at ? parseISO(customer.deleted_at) : null,
  });

  serialiseCustomerAddress = (customerAddress: CustomerAddressResponse): CustomerAddressModel => ({
    id: customerAddress.id,
    merchantId: customerAddress.merchant_id,
    customerId: customerAddress.customer_id,
    address1: customerAddress.address_1,
    address2: customerAddress.address_2,
    city: customerAddress.city,
    company: customerAddress.company,
    country: customerAddress.country,
    countryCode: customerAddress.country_code,
    postalCode: customerAddress.postal_code,
    createdAt: parseISO(customerAddress.created_at),
    updatedAt: parseISO(customerAddress.updated_at),
    deletedAt: customerAddress.deleted_at ? parseISO(customerAddress.deleted_at) : null,
  });

  serialiseCustomerPerson = (customerPerson: CustomerPersonResponse): CustomerPersonModel => ({
    id: customerPerson.id,
    firstName: customerPerson.first_name,
    lastName: customerPerson.last_name,
    fullName: customerPerson.full_name,
    email: customerPerson.email,
    createdAt: parseISO(customerPerson.created_at),
    updatedAt: parseISO(customerPerson.updated_at),
    deletedAt: customerPerson.deleted_at ? parseISO(customerPerson.deleted_at) : null,
  });
}

export const customerService = new CustomerService();
