import React from 'react';
import { useSelector } from 'react-redux';
import { billingResource } from '@spotrisk/billing';
import { Button, Text, useModal, ModalHeader, ModalContent, ModalFooter } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';

export const PlanConfirmModal = () => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const { closeModal } = useModal();

  if (!merchant) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      await billingResource.cancelPlan({ merchant });
      closeModal();
      window.location.reload();
    } catch (error) {
      // TODO: display error of failed to cancel
      closeModal();
    }
  };

  return (
    <div className="max-w-lg">
      <ModalHeader>
        <Text className="text-xl text-header text-left font-semibold" isBlock>
          Canceling plan
        </Text>
      </ModalHeader>
      <ModalContent>
        <Text>You are about to cancel your Spotrisk plan. This will also disconnect your store integration. Are you sure you wish to continue?</Text>
      </ModalContent>
      <ModalFooter className="text-right">
        <Button className="mr-2" onClick={closeModal} variant="gray">
          Close
        </Button>
        <Button onClick={handleSubmit} variant="danger">
          Cancel plan
        </Button>
      </ModalFooter>
    </div>
  );
};
