import { SETTINGS_ROUTES } from '@spotrisk/settings';
// import { AUTH_ROUTES } from '@spotrisk/auth';
import { SHOPIFY_PUBLIC_ROUTES } from '../../integration/shopify/shopify.routes';
import { BIG_COMMERCE_PUBLIC_ROUTES } from '../../integration/big-commerce/big-commerce.routes';

export const COMMON_ROUTE = {
  error: '/error',
};

// commented out auth routes include due to some issue with:
// Uncaught TypeError: Cannot read properties of undefined (reading 'spotriskAppUrl') at Module.spotriskAppUrl

export const STANDARD_ACCESS_ROUTES = [
  COMMON_ROUTE.error,
  SETTINGS_ROUTES.plans,
  SETTINGS_ROUTES.integration,
  // AUTH_ROUTES.logout,
  // AUTH_ROUTES.login,
  // AUTH_ROUTES.signup,
  '/logout',
  '/login',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/user-invite',
  SHOPIFY_PUBLIC_ROUTES.auth,
  SHOPIFY_PUBLIC_ROUTES.redirect,
  BIG_COMMERCE_PUBLIC_ROUTES.auth,
];
