import { Switch, Text } from '@spotrisk/common';
import { MerchantModel } from '@spotrisk/merchant';
import { SettingsModel } from '@spotrisk/settings';
import React, { useEffect, useState } from 'react';

export interface IEmailDigestsProps {
  merchant: MerchantModel;
  settings: SettingsModel;
  updateSettings: (settings: Partial<SettingsModel>) => void;
}

export const EmailDigests = ({ merchant, settings, updateSettings }: IEmailDigestsProps) => {
  const [weeklyIsChecked, setWeeklyIsChecked] = useState(settings.digestSubscription === 'WEEKLY' || settings.digestSubscription === 'ALL');
  const [monthlyIsChecked, setMonthlyIsChecked] = useState(settings.digestSubscription === 'MONTHLY' || settings.digestSubscription === 'ALL');

  useEffect(() => {
    let digestSubscription = 'NONE' as SettingsModel['digestSubscription'];
    if (weeklyIsChecked && monthlyIsChecked) {
      digestSubscription = 'ALL';
    } else if (weeklyIsChecked) {
      digestSubscription = 'WEEKLY';
    } else if (monthlyIsChecked) {
      digestSubscription = 'MONTHLY';
    }

    if (digestSubscription === settings.digestSubscription) {
      return;
    }

    updateSettings({ digestSubscription });
  }, [weeklyIsChecked, monthlyIsChecked]);

  const handleWeeklyToggle = () => {
    setWeeklyIsChecked(!weeklyIsChecked);
  };
  const handleMontlyToggle = () => {
    setMonthlyIsChecked(!monthlyIsChecked);
  };

  return (
    <>
      <div className="flex items-center p-7">
        <Text className="font-semibold block text-header text-sm w-8/12">Domain</Text>
        <Text className="font-semibold block text-header text-center text-sm w-2/12">Weekly</Text>
        <Text className="font-semibold block text-header text-center text-sm w-2/12">Monthly</Text>
      </div>
      <div className="flex items-center settings-panel p-7">
        <Text className="font-semibold block text-header text-sm w-8/12">{merchant.domain}</Text>
        <Switch className="flex justify-center w-2/12" onToggle={handleWeeklyToggle} checked={weeklyIsChecked} />
        <Switch className="flex justify-center w-2/12" onToggle={handleMontlyToggle} checked={monthlyIsChecked} />
      </div>
    </>
  );
};

export default EmailDigests;
