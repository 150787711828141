import { BillingModel } from '@spotrisk/billing';
import { BillingActionTypes, SET_BILLING } from './billing.types';

class BillingActions {
  setBilling = (billing: BillingModel): BillingActionTypes => ({
    type: SET_BILLING,
    billing,
  });
}

export const billingActions = new BillingActions();
