import { parseISO } from 'date-fns';
import {
  BillingModel,
  BillingResponse,
  FeaturesModel,
  FeaturesResponse,
  LimitsModel,
  LimitsResponse,
  PlanModel,
  PlanResponse,
  SubscriptionChargeModel,
  SubscriptionChargeResponse,
  SubscriptionModel,
  SubscriptionResponse,
  UsageModel,
  UsageResponse,
} from '@spotrisk/billing';

class BillingService {
  serialiseBilling = (billing: BillingResponse): BillingModel => ({
    merchantId: billing.merchant_id,
    hasBilling: billing.has_billing,
    subscription: billing.subscription ? this.serialiseBillingSubscription(billing.subscription) : undefined,
    plan: billing.plan ? this.serialiseBillingPlan(billing.plan) : undefined,
    usage: billing.usage ? this.serialiseBillingUsage(billing.usage) : undefined,
  });

  serialiseBillingSubscription = (subscription: SubscriptionResponse): SubscriptionModel => ({
    id: subscription.id,
    price: subscription.price,
    planName: subscription.plan_name,
    planId: subscription.plan_id,
    platform: subscription.platform,
    interval: subscription.interval,
    status: subscription.status,
    trialStartDate: subscription.trial_start_date ? parseISO(subscription.trial_start_date) : undefined,
    merchantId: subscription.merchant_id,
    createdAt: parseISO(subscription.created_at),
    updatedAt: parseISO(subscription.updated_at),
  });

  serialiseBillingPlan = (plan: PlanResponse): PlanModel => ({
    displayName: plan.display_name,
    name: plan.name,
    interval: plan.interval,
    price: plan.price,
    priority: plan.priority,
    id: plan.id,
    version: plan.version,
    isCurrent: plan.is_current,
    limits: plan.limits ? this.serialisePlanLimits(plan.limits) : undefined,
    features: plan.features ? this.serialisePlanFeatures(plan.features) : undefined,
  });

  serialisePlanLimits = (limits: LimitsResponse): LimitsModel => ({
    buyerBlocks: limits.buyer_block_limit,
    customerIdentity: limits.customer_identity_limit,
    esculations: limits.escalation_limit,
    orderLimit: limits.order_limit,
  });

  serialisePlanFeatures = (features: FeaturesResponse): FeaturesModel => ({
    customRules: features.custom_rules,
  });

  serialiseBillingUsage = (usage: UsageResponse): UsageModel => ({
    merchantId: usage.merchant_id,
    orderCount: usage.order_count,
    orderLimitReached: usage.order_limit_reached,
    buyerBlockCount: usage.buyer_block_count,
    buyerBlockedLimitReached: usage.buyer_blocked_limit_reached,
    buyerVerificationCount: usage.buyer_verification_count,
    buyerVerificationLimitReached: usage.buyer_verification_limit_reached,
    EscalationLimitReached: usage.escalation_limit_reached,
    EscalationCount: usage.escalation_count,
  });

  serialiseSubscriptionChargeResponse = (plan: SubscriptionChargeResponse): SubscriptionChargeModel => ({
    planId: plan.plan_id,
    redirectUrl: plan.redirect_url,
    test: plan.test,
  });

  serialiseSubscriptionChargeRequest = (plan: SubscriptionChargeModel): SubscriptionChargeResponse => ({
    plan_id: plan.planId,
    redirect_url: plan.redirectUrl,
    test: plan.test,
  });
}

export const billingService = new BillingService();
