import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import visaLogoImg from '@assets/images/visa-logo.png';
import { assessmentActions } from '@spotrisk/assessment';
import { Button, Hr, Icon, Text, useModal, utilService } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { OrderModel, OrderListSummaryCard } from '@spotrisk/order';
import { CustomerModel, customerResource, customerService } from '@spotrisk/customer';

type Props = {
  customer: CustomerModel;
};

export const CustomerDetailsDrawer = ({ customer }: Props) => {
  const dispatch = useDispatch();
  const { closeDrawer } = useModal();
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<OrderModel[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (!merchant) {
          setIsError(true);
          return;
        }

        setIsLoading(true);
        const customerWithOrders = await customerResource.queryCustomerWithOrders({ merchant, id: customer.id });
        const orders = customerWithOrders?.orders ?? [];
        const assessments = orders.flatMap((order) => order?.assessments ?? []);

        dispatch(assessmentActions.setAssessments(assessments));

        setOrders(orders ?? []);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Icon className="text-header animate-spin w-5 h-5" icon="spinner" />
      </div>
    );
  }

  if (!customer || isError) {
    return <div>Something went wrong.</div>;
  }

  return (
    <div className="px-10 py-6">
      <div className="flex items-center justify-end">
        <Button onClick={closeDrawer} variant="gray" shape="circle">
          <Icon icon="close" />
        </Button>
      </div>
      <Hr />
      <div className="w-full">
        <div className="flex items-center justify-between">
          <Text className="text-lg text-header font-semibold mr-2">{customerService.getFullName(customer)}</Text>
          <Text className="text-lg text-header font-semibold ">{utilService.formatCurrencyAmount(customer.totalSpent)}</Text>
        </div>
        <div className="flex items-center justify-between">
          <Text className="text-xs text-header opacity-40 font-medium">{customer.email}</Text>
          <Text className="text-xs text-header opacity-40 font-medium">{utilService.getDisplayAddress(customer.addresses[0])}</Text>
        </div>
      </div>
      <Hr />
      <div>
        <div className="flex items-center">
          <Text className="text-header text-lg font-semibold">Orders</Text>
        </div>
        <div className="flex mt-3">
          <div className="w-7/12 mr-4">
            {map(orders, (order) => (
              <OrderListSummaryCard key={order.id} order={order} />
            ))}
          </div>
          <div className="w-5/12">
            <div className="flex items-center justify-center rounded-md bg-gray-50 p-6">
              <img className="h-10" src={visaLogoImg} alt="Visa Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
