import * as FullStory from '@fullstory/browser';
import { ModalProvider, store, ToastProvider } from '@spotrisk/common';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { App } from './app';

Sentry.init({
  dsn: 'https://cac5727779d64b488b0b7cf51711f7cd@o4504261822119936.ingest.sentry.io/4504261832343554',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: 'production',
  enabled: window.location.hostname.includes('localhost') ? false : true,
});

FullStory.init({ orgId: '165MP0' });

const jsx = (
  <Provider store={store}>
    <BrowserRouter>
      <ToastProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </ToastProvider>
    </BrowserRouter>
  </Provider>
);

render(jsx, document.getElementById('spotrisk-app'));
