import React, { FormEvent, memo, useState } from 'react';
import { Button, Form, Input, Select, Text } from '@spotrisk/common';
import countries from '@assets/countries';
import { SettingsModel } from '@spotrisk/settings';
import { map } from 'lodash';

export interface ISettingStoreUpdateProps {
  settings: SettingsModel;
  onUpdate: (settingsUpdate: Partial<SettingsModel>) => void;
}

type FormData = {
  store_email: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  post_code: string;
  country: string;
};

export const StoreSettings = memo(({ settings, onUpdate }: ISettingStoreUpdateProps) => {
  const [formData, setFormData] = useState<FormData>({
    store_email: settings.storeEmail ?? '',
    address_line_1: settings.addressLine2 ?? '',
    address_line_2: settings.addressLine2 ?? '',
    city: settings.city ?? '',
    post_code: settings.postCode ?? '',
    country: settings.country ?? '',
  });
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      onUpdate({
        addressLine1: formData.address_line_1,
        addressLine2: formData.address_line_2,
        city: formData.city,
        postCode: formData.post_code,
        country: formData.country,
        storeEmail: formData.store_email,
      });
    } catch (error) {
      setError('Failed to update store settings');
    }
  };

  return (
    <div>
      <Text className="text-xl text-header font-bold mr-2 antialiased block ">Store settings</Text>
      <Text className="text-xs font-header text-header font-light block mt-2 mb-8 antialiased">
        Update your store email address and store physical address here.
      </Text>
      <Form className="relative" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="text-sm text-header font-bold mr-2 antialiased block tracking-wide mb-2">
            Store Email Address
          </label>
          <Input
            type="email"
            className="w-full border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500"
            value={formData.store_email}
            onChange={({ target }) => setFormData({ ...formData, store_email: target.value })}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="text-sm text-header font-bold mr-2 antialiased block tracking-wide mb-2">
            Address Line 1
          </label>
          <Input
            type="text"
            className="w-full border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500"
            value={formData.address_line_1}
            onChange={({ target }) => setFormData({ ...formData, address_line_1: target.value })}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="text-sm text-header font-bold mr-2 antialiased block tracking-wide mb-2">
            Address Line 2
          </label>
          <Input
            type="text"
            className="w-full border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500"
            value={formData.address_line_2}
            onChange={({ target }) => setFormData({ ...formData, address_line_2: target.value })}
          />
        </div>
        <label htmlFor="name" className="text-sm text-header font-bold mr-2 antialiased block tracking-wide mb-2">
          City
        </label>
        <div className="mb-4">
          <Input
            type="text"
            className="w-full border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500"
            value={formData.city}
            onChange={({ target }) => setFormData({ ...formData, city: target.value })}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="text-sm text-header font-bold mr-2 antialiased block tracking-wide mb-2">
            Postcode | Zipcode
          </label>
          <Input
            type="text"
            className="w-full border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500"
            value={formData.post_code}
            onChange={({ target }) => setFormData({ ...formData, post_code: target.value })}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="text-sm text-header font-bold mr-2 antialiased block tracking-wide mb-2">
            Country
          </label>
          <Select
            type="text"
            options={map(countries, (country) => ({ label: country.name, value: country.name }))}
            className="w-full border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500"
            value={map(countries, 'name').includes(formData.country) ? formData.country : ''}
            onChange={(country) => setFormData({ ...formData, country: country })}
          />
        </div>
        <Button type="submit" className="mb-4" variant="blue">
          Update details
        </Button>
        {error && <Text className="text-red-500 text-sm mt-2">{error}</Text>}
      </Form>
    </div>
  );
});
