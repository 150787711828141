import { apiService } from '@spotrisk/common';
import { MerchantModel } from '@spotrisk/merchant';
import { OrderListModel, OrderListResponse, OrderModel, OrderResponse, orderService } from '@spotrisk/order';
import { map } from 'lodash';

type QueryOrdersParam = {
  merchant: MerchantModel;
  state?: OrderModel['state'];
};

type GetOrderSummaryParam = {
  merchant: MerchantModel;
  id: OrderModel['id'];
};

type SearchOrdersSummaryParam = {
  merchant: MerchantModel;
  query: string;
};

class OrderResource {
  listOrders = async ({ merchant, state }: QueryOrdersParam): Promise<OrderListModel[]> => {
    const searchParams = new URLSearchParams();
    searchParams.append('sort', 'DESC');

    if (state) {
      searchParams.append('state', state);
    }

    const orders = await apiService.fetch<OrderListResponse[]>(
      `/ecommerce/merchant/${merchant.id}/order/list?${searchParams}&since=${new Date().getTime()}`
    );

    return map(orders, orderService.serialiseListOrder);
  };

  /**
   * @deprecated consider using `listOrders` instead as its more performant
   * */
  queryOrders = async ({ merchant, state }: QueryOrdersParam): Promise<OrderModel[]> => {
    const searchParams = new URLSearchParams();
    searchParams.append('sort', 'DESC');

    if (state) {
      searchParams.append('state', state);
    }

    const orders = await apiService.fetch<OrderResponse[]>(`/ecommerce/merchant/${merchant.id}/order?${searchParams}&since=${new Date().getTime()}`);

    return map(orders, orderService.serialiseOrder);
  };

  getOrder = async ({ merchant, id }: GetOrderSummaryParam): Promise<OrderModel | null> => {
    const order = await apiService.fetch<OrderResponse>(`/ecommerce/merchant/${merchant.id}/order/${id}`);
    if (!order) {
      return null;
    }

    return orderService.serialiseOrder(order);
  };

  searchOrders = async ({ merchant, query }: SearchOrdersSummaryParam): Promise<OrderListModel[]> => {
    const orders = await apiService.fetch<OrderListResponse[]>(`/ecommerce/merchant/${merchant.id}/order_search?term=${encodeURIComponent(query)}`);

    return map(orders, orderService.serialiseListOrder);
  };
}

export const orderResource = new OrderResource();
