import cn from 'classnames';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';

type Props = {
  className?: string;
  children: ReactNode;
  href?: string;
  onClick?: () => void;
  isDisabled?: boolean;
};

const BASE_CLASSES = ['block', 'bg-white', 'shadow-lg', 'rounded-md', 'outline-none', 'w-full', 'p-6', 'mt-2', 'first:mt-0'];

export const ListSummaryCard = ({ className, children, href, isDisabled, onClick }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    'hover:bg-sky-50': href,
    'pointer-event-none opacity-50': isDisabled,
  });

  if (href) {
    return (
      <Link className={classes} to={href} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return <div className={classes}>{children}</div>;
};
