import SpotStandLockImage from '@assets/images/spot/spot-stand-lock.png';
import { Button, ModalContent, ModalFooter, Text, useModal } from '@spotrisk/common';
import { SETTINGS_ROUTES } from '@spotrisk/settings';
import React from 'react';

export const OrderLimitReachedModal = () => {
  const { closeModal } = useModal();

  return (
    <div className="max-w-xl text-center">
      <ModalContent>
        <img className="my-3 mx-auto" src={SpotStandLockImage} alt="Lock" />
        <Text className="text-xl text-header font-semibold mb-3" isBlock>
          Order limit processing has been reached
        </Text>
        <Text className="text-sm text-header mt-5 mb-5" isBlock>
          You&apos;ve hit the limit on the number of orders that Spotrisk processes each month. New orders for the remainder of the month will no
          longer be analyzed for risk.
        </Text>
        <Text className="text-sm text-header mt-5 mb-5" isBlock>
          Read more about how Spotrisk&apos;s Order limits works{' '}
          <a className="tulip" href="https://support.spotriskhq.com/knowledge/using-the-buyer-block-feature" target="_blank" rel="noreferrer">
            here
          </a>{' '}
          and upgrade to a paid plan today!
        </Text>
      </ModalContent>
      <ModalFooter>
        <Button className="mr-2" onClick={closeModal} href={SETTINGS_ROUTES.plans} variant="blue" isLink={true}>
          Upgrade to the paid plan
        </Button>
      </ModalFooter>
    </div>
  );
};
