import React, { memo } from 'react';
import { Page, PageContent } from '@spotrisk/common';

export const FiveHundredPage = memo(() => (
  <Page>
    <PageContent className="min-h-screen" hasHeader={false} isCentered>
      <img src="https://example.com/error-image.png" alt="Error illustration" />
      <h1>We've dropped the ball, but we will win the game</h1>
      <p>Oops! Something went wrong on our end.</p>
      <p>Our support team has been notified.</p>
      <p>If you need immediate assistance, please contact us at:</p>
      <a href="mailto:support@example.com">support@example.com</a>
    </PageContent>
  </Page>
));
