import { CommonReducerState, CommonActionTypes, SET_UI } from './common.types';

const initialState: CommonReducerState = {
  ui: 'loading',
};

export const commonReducer = (state = initialState, action: CommonActionTypes) => {
  switch (action.type) {
    case SET_UI:
      return {
        ...state,
        ui: action.ui,
      };
    default:
      return state;
  }
};
