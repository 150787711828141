import { AppErrorPage, OrderRoute, RedirectMessageModel, SettingsRoute } from '@spotrisk/app';
import { Page, PageContent, spotriskOrdersUrl } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { orderResource, ORDER_ROUTES, OrderModel } from '@spotrisk/order';
import { SETTINGS_ROUTES } from '@spotrisk/settings';
import cn from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';
import Iframe from 'react-iframe';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { billingSelectors } from '@spotrisk/billing';

export const OrderViewPage = memo(() => {
  const history = useHistory();

  const merchant = useSelector(merchantSelectors.getMerchant);
  const billing = useSelector(billingSelectors.getBilling);
  const merchants = useSelector(merchantSelectors.getMerchants);
  const [order, setOrder] = useState<OrderModel | null>(null);
  const { orderId } = useParams<{ orderId: string }>();
  const [isError, setIsError] = useState(false);

  const merchantRef = useRef(merchant);
  useEffect(() => {
    merchantRef.current = merchant;
  }, [merchant]);

  useEffect(() => {
    getOrder();
  }, []);

  const handleMessage = ({ origin, data }: MessageEvent<RedirectMessageModel>) => {
    if (origin !== spotriskOrdersUrl) {
      return;
    }
    switch (data.domain) {
      case 'settings':
        history.push(SETTINGS_ROUTES[data.route as SettingsRoute]);
        break;
      case 'order':
        history.push(ORDER_ROUTES[data.route as OrderRoute]);
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const getOrder = async () => {
    if (!merchantRef.current) {
      setIsError(true);
      return;
    }

    const order = await orderResource.getOrder({ id: orderId, merchant: merchantRef.current });
    if (!order) {
      history.push(ORDER_ROUTES.list);
      return;
    }

    if (!order.token?.token) {
      setIsError(true);
    }

    setOrder(order);
  };

  if (!order || !order.token?.token) {
    return null;
  }

  if (isError) {
    return <AppErrorPage />;
  }

  const classNames = cn('min-h-screen w-full md:pt-24', {
    'pt-24': merchants.length <= 1,
    'pt-36': merchants.length > 1,
  });

  return (
    <Iframe
      className={classNames}
      url={`${spotriskOrdersUrl}/order?token=${order.token.token}&header=false&state=${billing?.subscription?.status}`}
    />
  );
});
