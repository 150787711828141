import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SHOPIFY_PUBLIC_ROUTES } from './shopify.routes';
import { ShopifyAuth, ShopifyOrderView, ShopifyRedirect } from './pages';
import React from 'react';

export const ShopifyLayout = memo(() => (
  <Switch>
    <Route path={SHOPIFY_PUBLIC_ROUTES.redirect} component={ShopifyRedirect} exact />
    <Route path={SHOPIFY_PUBLIC_ROUTES.auth} component={ShopifyAuth} exact />
    <Route path={SHOPIFY_PUBLIC_ROUTES.orderViewAction} component={ShopifyOrderView} exact />
  </Switch>
));
