import React, { memo } from 'react';
import { Page, PageContent } from '@spotrisk/common';
import { CustomerListSummary, CustomerList, CustomerListHeading } from '@spotrisk/customer';

export const CustomerListPage = memo(() => (
  <Page>
    <PageContent className="min-h-screen">
      <div className="flex max-w-screen-xl mx-auto">
        <div className="w-1/2 mr-16">
          <CustomerListHeading />
          <CustomerListSummary />
        </div>
        <CustomerList />
      </div>
    </PageContent>
  </Page>
));
