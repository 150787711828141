import React, { memo, ReactNode } from 'react';
import cn from 'classnames';
import { matchPath, NavLink } from 'react-router-dom';
import { Button, Text } from '@spotrisk/common';

type Props = {
  children: ReactNode;
  className?: string;
  to: string;
  hasActiveState?: boolean;
  activeRoutes?: string[];
  isExternal?: boolean;
  isHidden?: boolean;
};

const BASE_CLASSES = ['flex', 'justify-center', 'whitespace-nowrap', 'items-center'];
const BASE_ACTIVE_CLASSES = ['opacity-100'];

export const HeaderNavLink = memo(({ children, className, to, hasActiveState = true, activeRoutes, isExternal, isHidden }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    'opacity-40': hasActiveState,
  });
  const activeClasses = cn(BASE_ACTIVE_CLASSES);
  const handleIsActive = () => Boolean(matchPath(location.pathname, activeRoutes || []));

  if (isHidden) {
    return <> </>;
  }
  if (isExternal) {
    return (
      <Button className={classes} state="text" variant="light" href={to} isOpenedInNewTab isExternal isLink>
        <Text className="font-header text-header text-xs uppercase">{children}</Text>
      </Button>
    );
  }

  return (
    <NavLink className={classes} activeClassName={hasActiveState ? activeClasses : ''} to={to} isActive={activeRoutes && handleIsActive}>
      <Text className="font-header text-header text-xs uppercase">{children}</Text>
    </NavLink>
  );
});
