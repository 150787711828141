import { Text } from '@spotrisk/common';
import cn from 'classnames';
import React, { memo, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  children: ReactNode;
  className?: string;
  to: string;
  activeRoutes?: string[];
  isActive?: boolean;
};

const BASE_CLASSES = ['opacity-40'];
const BASE_ACTIVE_CLASSES = ['opacity-100'];

export const SideBarNavLink = memo(({ children, className, to, isActive }: Props) => {
  const classes = cn(className, BASE_CLASSES);
  const activeClasses = cn(BASE_ACTIVE_CLASSES);
  const handleIsActive = () => {
    return isActive || window.location.pathname.toLowerCase() === to.toLowerCase();
  };

  return (
    <NavLink className={classes} activeClassName={activeClasses} to={to} isActive={handleIsActive}>
      <Text className="font-header text-header text-s">{children}</Text>
    </NavLink>
  );
});
