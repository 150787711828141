import { TabButton, TabModel, TabsActiveIndicator } from '@spotrisk/common';
import { orderSelectors } from '@spotrisk/order';
import cn from 'classnames';
import { every, find, map } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export type TabContextType<T> = {
  tabs: TabModel<T>[];
  activeTab: TabModel<T>;
  setTabs: (tabs: TabModel<T>[]) => void;
  setActiveTab: (tab: TabModel<T>) => void;
  setActiveTabById: (label: string) => void;
};

const TabsContext = createContext<TabContextType<any> | null>(null);

export type TabsVariant = 'underline';

type Props<T> = {
  className?: string;
  onChange?: (activeTab: TabModel<T>) => void;
  tabs: TabModel<T>[];
  variant?: TabsVariant;
  isDisabled?: boolean;
  isAnimated?: boolean;
};

const BASE_CLASSES = ['relative', 'flex', 'items-center', 'justify-start'];

export function Tabs<T>({ className, variant = 'underline', tabs, onChange, isDisabled, isAnimated = true }: Props<T>) {
  const orderSearch = useSelector(orderSelectors.search);
  const orderState = useSelector(orderSelectors.state);

  const classes = cn(className, BASE_CLASSES);
  const tabButtonRefs = useRef<HTMLButtonElement[]>([]);
  const initialTabById = orderState && find(tabs, ({ id }) => Boolean(id && id === orderState));
  const [tabsToManage, setTabs] = useState<TabModel<T>[]>(tabs);
  const [activeTab, setActiveTab] = useState<TabModel<T> | undefined>(initialTabById || tabs[0]);

  useEffect(() => {
    setTabs(tabs);
    tabButtonRefs.current = [];
  }, [tabs]);

  useEffect(() => {
    setActiveTab(activeTab);
  }, []);

  useEffect(() => {
    if (orderSearch) {
      setActiveTab(undefined);
    }
  }, [orderSearch]);

  const handleSetActiveTab = useCallback((activeTab: TabModel<T>) => {
    setActiveTab(activeTab);

    if (onChange) {
      onChange(activeTab);
    }
  }, []);

  const handleSetActiveTabById = useCallback(
    (idToActivate: T) => {
      const tabToActivate = find(tabs, ({ id }) => Boolean(id && id === idToActivate));

      if (tabToActivate) {
        handleSetActiveTab(tabToActivate);
      }
    },
    [tabs]
  );

  const memoizedContextValue = useMemo(
    () => ({
      activeTab,
      setActiveTab: handleSetActiveTab,
      setActiveTabById: handleSetActiveTabById,
      tabs: tabsToManage,
      setTabs,
    }),
    [activeTab, tabsToManage]
  );

  if (!orderSearch && (!activeTab || !every(tabs, ({ id: idToCheck }) => find(tabsToManage, ({ id }) => id === idToCheck)))) {
    return null;
  }

  return (
    <TabsContext.Provider value={memoizedContextValue as any}>
      <div className={classes}>
        {(variant === 'underline' || variant === 'pill') && (
          <TabsActiveIndicator tabButtonRefs={tabButtonRefs} variant={variant} isAnimated={isAnimated} />
        )}
        {map(tabs, (tab, index) => (
          <TabButton<T> key={index} variant={variant} isDisabled={isDisabled} ref={(ref) => ref && tabButtonRefs.current.push(ref)} {...tab} />
        ))}
      </div>
    </TabsContext.Provider>
  );
}

export const useTabs = () => {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error('This component must be used within a <Tabs> component.');
  }

  return context;
};
