import React from 'react';
import { Page, PageContent } from '@spotrisk/common';

export const AppErrorPage = () => (
  <Page>
    <PageContent className="min-h-screen" isCentered>
      <div style={{ textAlign: 'center' }}>
        <img src="https://cdn.spotriskhq.com/cdn/assets/public/img/illustration-Spot-badge-friendly.png" className="w-15 mb-10 mx-auto" />
        <h1>Uh no, we have had an unexpected error happen!</h1>
        <p style={{ fontSize: 'small' }}>Our support team has been notified</p>
        <a href="mailto:support@spotriskhq.com">support@spotriskhq.com</a>
      </div>
    </PageContent>
  </Page>
);
