import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AppLayout, AppRoute } from '@spotrisk/app';
import { AuthLayout, AUTH_ROUTES } from '@spotrisk/auth';
import { commonActions, COMMON_ROUTE, FiveHundredPage } from '@spotrisk/common';
import { ShopifyLayout, SHOPIFY_PUBLIC_ROUTES, BigCommerceLayout, BIG_COMMERCE_PUBLIC_ROUTES } from '@spotrisk/integration';

export const App = () => {
  const dispatch = useDispatch();
  const route = useRef(location.pathname);
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        history.listen((location: Location, action: string) => {
          window.scroll(0, 0);

          // Only set the page state to loading on a `PUSH` history event, and if the previous route does not match the new path.
          if (action === 'PUSH' && route.current !== location.pathname) {
            dispatch(commonActions.setUi('loading'));
          }

          route.current = location.pathname;
        });
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <FiveHundredPage />;
  }

  return (
    <Switch>
      <Route path={COMMON_ROUTE.error} component={FiveHundredPage} exact />
      {map(AUTH_ROUTES, (route) => (
        <Route key={route} path={route} component={AuthLayout} exact />
      ))}
      {map(SHOPIFY_PUBLIC_ROUTES, (route) => (
        <Route key={route} path={route} component={ShopifyLayout} exact />
      ))}
      {map(BIG_COMMERCE_PUBLIC_ROUTES, (route) => (
        <Route key={route} path={route} component={BigCommerceLayout} exact />
      ))}
      <AppRoute path="/" component={AppLayout} />
    </Switch>
  );
};
