import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import React, { HTMLProps, ReactNode, forwardRef } from 'react';

type HtmlFormAttributes = HTMLProps<HTMLFormElement> & {
  'spotrisk-security-form'?: '2024';
};

type Props = HTMLProps<HTMLFormElement> & {
  children: ReactNode;
};

export const Form = forwardRef(({ className, children, ...rest }: Props, ref: React.ForwardedRef<HTMLFormElement>) => {
  const { pathname } = useLocation();
  const attrs: HtmlFormAttributes = { ...rest };
  const classes = classNames(className);

  return (
    <form className={classes} name={pathname} ref={ref} {...attrs}>
      {children}
    </form>
  );
});
