import { apiService } from '@spotrisk/common';
import { MerchantModel } from '@spotrisk/merchant';
import { UserInvitationModel, UserInvitationResponse, UserModel, UserResponse, UsersModel, UsersResponse, userService } from '@spotrisk/user';

type GetUsersParam = {
  merchant: MerchantModel;
};

type DeleteUserParam = {
  merchant: MerchantModel;
};

type InviteUserParam = {
  merchant: MerchantModel;
};

type ResendInviteUserParam = {
  merchant: MerchantModel;
};

class UserResource {
  getUsers = async ({ merchant }: GetUsersParam): Promise<UsersModel> => {
    const response = await apiService.fetch<UsersResponse>(`/ecommerce/merchant/${merchant.id}/users`);
    if (!response) {
      throw new Error('Failed to fetch users');
    }

    return userService.serialiseUsers(response);
  };

  inviteUser = async (email: string, { merchant }: InviteUserParam): Promise<UserInvitationModel> => {
    const userInvitation = await apiService.fetch<UserInvitationResponse>(`/ecommerce/merchant/${merchant.id}/users/invite`, {
      method: 'POST',
      data: { email },
    });

    if (!userInvitation) {
      throw new Error('Failed to invite user');
    }

    return userService.serialiseUserInvitation(userInvitation);
  };

  resendInvite = async (inviteId: string, { merchant }: ResendInviteUserParam): Promise<void> => {
    await apiService.fetch(`/ecommerce/merchant/${merchant.id}/users/invite/${inviteId}/resend`, { method: 'POST' });
  };

  deleteUser = async (userId: string, { merchant }: DeleteUserParam): Promise<void> => {
    await apiService.fetch(`/ecommerce/merchant/${merchant.id}/users/${userId}`, { method: 'DELETE' });
  };

  getLoginUser = async (): Promise<UserModel> => {
    const response = await apiService.fetch<UserResponse>('/auth/me', {}, false);
    if (!response) {
      throw new Error('Failed to fetch user');
    }

    return userService.serialiseUser(response);
  };
}

export const userResource = new UserResource();
