import { Input, Page, PageContent, Text, Button, Hr, spotriskAppUrl, Loader } from '@spotrisk/common';
import React, { FormEvent, useState } from 'react';
import { memo } from 'react';
import { AUTH_ROUTES, authResource } from '@spotrisk/auth';
import { toLower } from 'lodash';

export const ForgotPasswordPage = memo(() => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const loginUrl = new URL(`${spotriskAppUrl}${AUTH_ROUTES.login}`);

  const [formData, setFormData] = useState({ email: '' });

  if (isLoading) {
    return (
      <div className="flex min-h-screen justify-center items-center">
        <Loader />
      </div>
    );
  }

  const handleForgotPassword = async (event: FormEvent<HTMLFormElement>) => {
    // handle forgot password
    event.preventDefault();
    setIsLoading(true);

    try {
      if (!formData.email) {
        setIsLoading(false);
        setError('Please enter a valid email.');
        return;
      }

      await authResource.resetPassword({ email: formData.email });
      setIsLoading(false);
      setSuccess(true);
    } catch (error) {
      setIsLoading(false);
      setError('An unexpected error occurred');
    }
  };

  const handleLoginRedirect = () => {
    window.location.href = loginUrl.href;
  };

  return (
    <Page>
      <PageContent isCentered>
        <div className="max-w-lg z-10">
          <div className="mb-4">
            <img className="w-20" src="https://cdn.spotriskhq.com/cdn/assets/public/img/logo-long.svg" alt="spotrisk-logo" />
          </div>
          <Hr />
          {success ? (
            <div className=" bg-white p-7 rounded-lg shadow-xl border-gray-300 text-center items-center justify-center">
              <div>
                <Text className="text-2xl font-header text-header">Check your email</Text>
              </div>
              <div className="mt-4">
                <Text className="text ml-2">
                  If you have an Spotrisk account, We have sent an email to <b>{formData.email}</b>. Follow the instructions in the email to reset
                  your password.
                </Text>
                <Hr />
                <div className="mt-8">
                  <Button className="text" state="text" variant="blue" onClick={handleLoginRedirect}>
                    Return to Sign in
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white p-7 rounded-lg shadow-xl border-gray-300">
              <div className="text-left mb-8">
                <div>
                  <Text className="text-2xl font-bold mt-8">Reset your password</Text>
                </div>
                <div className="mt-2">
                  <Text className="text-sm font-thin break-words">
                    Enter the email address associated with your account and we will send you a link to reset your password.
                  </Text>
                </div>
              </div>
              <form onSubmit={handleForgotPassword}>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
                    Email
                  </label>
                  <Input
                    id="email"
                    type="email"
                    onChange={({ target }) => setFormData({ ...formData, email: toLower(target.value) })}
                    value={formData.email}
                    className="h-12 shadow appearance-none border rounded-lg w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                {error && (
                  <div id="error-message" className="text-red text-center mb-5 mt-5">
                    <Text variant="danger">{error}</Text>
                  </div>
                )}
                <div className="text-center">
                  <Button type="submit" className="text-white w-full" variant="blue">
                    Continue
                  </Button>
                  <Hr />
                  <Button className="text-sm " state="text" variant="blue" onClick={handleLoginRedirect}>
                    Return to Sign in
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </PageContent>
      <footer className="fixed bottom-0 left-0 w-full h-10 flex items-center justify-center">
        <img className="w-full" src="https://cdn.spotriskhq.com/cdn/assets/public/img/testimonials-bottom.svg" alt="bottom-banner" />
      </footer>
    </Page>
  );
});
