class CurrencyService {
  getCurrencySymbol = (currencyCode: string) => {
    const currencies = this.getCurrencies();
    currencyCode = currencyCode?.toUpperCase();

    if (!currencyCode) {
      currencyCode = 'USD';
    }

    const currency = currencies.find((obj) => {
      return obj.code === currencyCode;
    });

    if (currency?.symbol) {
      return currency?.symbol;
    }

    return '$';
  };

  getCurrencies = () => {
    return [
      { label: 'Albania Lek', code: 'ALL', symbol: 'Lek' },
      { label: 'Afghanistan Afghani', code: 'AFN', symbol: '؋' },
      { label: 'Argentina Peso', code: 'ARS', symbol: '$' },
      { label: 'Aruba Guilder', code: 'AWG', symbol: 'ƒ' },
      { label: 'Australia Dollar', code: 'AUD', symbol: '$' },
      { label: 'Azerbaijan Manat', code: 'AZN', symbol: '₼' },
      { label: 'Bahamas Dollar', code: 'BSD', symbol: '$' },
      { label: 'Barbados Dollar', code: 'BBD', symbol: '$' },
      { label: 'Belarus Ruble', code: 'BYN', symbol: 'Br' },
      { label: 'Belize Dollar', code: 'BZD', symbol: 'BZ$' },
      { label: 'Bermuda Dollar', code: 'BMD', symbol: '$' },
      { label: 'Bolivia Bolíviano', code: 'BOB', symbol: '$b' },
      { label: 'Bosnia and Herzegovina Convertible Mark', code: 'BAM', symbol: 'KM' },
      { label: 'Botswana Pula', code: 'BWP', symbol: 'P' },
      { label: 'Bulgaria Lev', code: 'BGN', symbol: 'лв' },
      { label: 'Brazil Real', code: 'BRL', symbol: 'R$' },
      { label: 'Brunei Darussalam Dollar', code: 'BND', symbol: '$' },
      { label: 'Cambodia Riel', code: 'KHR', symbol: '៛' },
      { label: 'Canada Dollar', code: 'CAD', symbol: '$' },
      { label: 'Cayman Islands Dollar', code: 'KYD', symbol: '$' },
      { label: 'Chile Peso', code: 'CLP', symbol: '$' },
      { label: 'China Yuan Renminbi', code: 'CNY', symbol: '¥' },
      { label: 'Colombia Peso', code: 'COP', symbol: '$' },
      { label: 'Costa Rica Colon', code: 'CRC', symbol: '₡' },
      { label: 'Croatia Kuna', code: 'HRK', symbol: 'kn' },
      { label: 'Cuba Peso', code: 'CUP', symbol: '₱' },
      { label: 'Czech Republic Koruna', code: 'CZK', symbol: 'Kč' },
      { label: 'Denmark Krone', code: 'DKK', symbol: 'kr' },
      { label: 'Dominican Republic Peso', code: 'DOP', symbol: 'RD$' },
      { label: 'East Caribbean Dollar', code: 'XCD', symbol: '$' },
      { label: 'Egypt Pound', code: 'EGP', symbol: '£' },
      { label: 'El Salvador Colon', code: 'SVC', symbol: '$' },
      { label: 'Euro Member Countries', code: 'EUR', symbol: '€' },
      { label: 'Falkland Islands (Malvinas) Pound', code: 'FKP', symbol: '£' },
      { label: 'Fiji Dollar', code: 'FJD', symbol: '$' },
      { label: 'Ghana Cedi', code: 'GHS', symbol: '¢' },
      { label: 'Gibraltar Pound', code: 'GIP', symbol: '£' },
      { label: 'Guatemala Quetzal', code: 'GTQ', symbol: 'Q' },
      { label: 'Guernsey Pound', code: 'GGP', symbol: '£' },
      { label: 'Guyana Dollar', code: 'GYD', symbol: '$' },
      { label: 'Honduras Lempira', code: 'HNL', symbol: 'L' },
      { label: 'Hong Kong Dollar', code: 'HKD', symbol: '$' },
      { label: 'Hungary Forint', code: 'HUF', symbol: 'Ft' },
      { label: 'Iceland Krona', code: 'ISK', symbol: 'kr' },
      { label: 'India Rupee', code: 'INR', symbol: '₹' },
      { label: 'Indonesia Rupiah', code: 'IDR', symbol: 'Rp' },
      { label: 'Iran Rial', code: 'IRR', symbol: '﷼' },
      { label: 'Isle of Man Pound', code: 'IMP', symbol: '£' },
      { label: 'Israel Shekel', code: 'ILS', symbol: '₪' },
      { label: 'Jamaica Dollar', code: 'JMD', symbol: 'J$' },
      { label: 'Japan Yen', code: 'JPY', symbol: '¥' },
      { label: 'Jersey Pound', code: 'JEP', symbol: '£' },
      { label: 'Kazakhstan Tenge', code: 'KZT', symbol: 'лв' },
      { label: 'Korea (North) Won', code: 'KPW', symbol: '₩' },
      { label: 'Korea (South) Won', code: 'KRW', symbol: '₩' },
      { label: 'Kyrgyzstan Som', code: 'KGS', symbol: 'лв' },
      { label: 'Laos Kip', code: 'LAK', symbol: '₭' },
      { label: 'Lebanon Pound', code: 'LBP', symbol: '£' },
      { label: 'Liberia Dollar', code: 'LRD', symbol: '$' },
      { label: 'Macedonia Denar', code: 'MKD', symbol: 'ден' },
      { label: 'Malaysia Ringgit', code: 'MYR', symbol: 'RM' },
      { label: 'Mauritius Rupee', code: 'MUR', symbol: '₨' },
      { label: 'Mexico Peso', code: 'MXN', symbol: '$' },
      { label: 'Mongolia Tughrik', code: 'MNT', symbol: '₮' },
      { label: 'Moroccan-dirham', code: 'MNT', symbol: 'د.إ' },
      { label: 'Mozambique Metical', code: 'MZN', symbol: 'MT' },
      { label: 'Myanmar kyat', code: 'MMK', symbol: 'K' },
      { label: 'Namibia Dollar', code: 'NAD', symbol: '$' },
      { label: 'Nepal Rupee', code: 'NPR', symbol: '₨' },
      { label: 'Netherlands Antilles Guilder', code: 'ANG', symbol: 'ƒ' },
      { label: 'New Zealand Dollar', code: 'NZD', symbol: '$' },
      { label: 'Nicaragua Cordoba', code: 'NIO', symbol: 'C$' },
      { label: 'Nigeria Naira', code: 'NGN', symbol: '₦' },
      { label: 'Norway Krone', code: 'NOK', symbol: 'kr' },
      { label: 'Oman Rial', code: 'OMR', symbol: '﷼' },
      { label: 'Pakistan Rupee', code: 'PKR', symbol: '₨' },
      { label: 'Panama Balboa', code: 'PAB', symbol: 'B/.' },
      { label: 'Paraguay Guarani', code: 'PYG', symbol: 'Gs' },
      { label: 'Peru Sol', code: 'PEN', symbol: 'S/.' },
      { label: 'Philippines Peso', code: 'PHP', symbol: '₱' },
      { label: 'Poland Zloty', code: 'PLN', symbol: 'zł' },
      { label: 'Qatar Riyal', code: 'QAR', symbol: '﷼' },
      { label: 'Romania Leu', code: 'RON', symbol: 'lei' },
      { label: 'Russia Ruble', code: 'RUB', symbol: '₽' },
      { label: 'Saint Helena Pound', code: 'SHP', symbol: '£' },
      { label: 'Saudi Arabia Riyal', code: 'SAR', symbol: '﷼' },
      { label: 'Serbia Dinar', code: 'RSD', symbol: 'Дин.' },
      { label: 'Seychelles Rupee', code: 'SCR', symbol: '₨' },
      { label: 'Singapore Dollar', code: 'SGD', symbol: '$' },
      { label: 'Solomon Islands Dollar', code: 'SBD', symbol: '$' },
      { label: 'Somalia Shilling', code: 'SOS', symbol: 'S' },
      { label: 'South Korean Won', code: 'KRW', symbol: '₩' },
      { label: 'South Africa Rand', code: 'ZAR', symbol: 'R' },
      { label: 'Sri Lanka Rupee', code: 'LKR', symbol: '₨' },
      { label: 'Sweden Krona', code: 'SEK', symbol: 'kr' },
      { label: 'Switzerland Franc', code: 'CHF', symbol: 'CHF' },
      { label: 'Suriname Dollar', code: 'SRD', symbol: '$' },
      { label: 'Syria Pound', code: 'SYP', symbol: '£' },
      { label: 'Taiwan New Dollar', code: 'TWD', symbol: 'NT$' },
      { label: 'Thailand Baht', code: 'THB', symbol: '฿' },
      { label: 'Trinidad and Tobago Dollar', code: 'TTD', symbol: 'TT$' },
      { label: 'Turkey Lira', code: 'TRY', symbol: '₺' },
      { label: 'Tuvalu Dollar', code: 'TVD', symbol: '$' },
      { label: 'Ukraine Hryvnia', code: 'UAH', symbol: '₴' },
      { label: 'UAE-Dirham', code: 'AED', symbol: 'د.إ' },
      { label: 'United Kingdom Pound', code: 'GBP', symbol: '£' },
      { label: 'United States Dollar', code: 'USD', symbol: '$' },
      { label: 'Uruguay Peso', code: 'UYU', symbol: '$U' },
      { label: 'Uzbekistan Som', code: 'UZS', symbol: 'лв' },
      { label: 'Venezuela Bolívar', code: 'VEF', symbol: 'Bs' },
      { label: 'Vanuatu Vatu', code: 'VUV', symbol: 'VT' },
      { label: 'Viet Nam Dong', code: 'VND', symbol: '₫' },
      { label: 'Yemen Rial', code: 'YER', symbol: '﷼' },
      { label: 'Zimbabwe Dollar', code: 'ZWDcode: ', symbol: 'Z$symbol: ' },
    ];
  };
}

export const currencyService = new CurrencyService();
