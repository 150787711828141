import { CustomRuleListModel, CustomRuleModel } from '@spotrisk/custom-rule';

export type CustomRuleReducerStatus = 'ready' | 'loading' | 'saving' | 'error';

export type CustomRuleReducerState = {
  customRules: CustomRuleModel[];
  customRuleLists: CustomRuleListModel[];
  status: CustomRuleReducerStatus;
};

export const GET_CUSTOM_RULES = 'custom-rule|GET_CUSTOM_RULES' as const;
export const SET_CUSTOM_RULES = 'custom-rule|SET_CUSTOM_RULES' as const;
export const UPDATE_CUSTOM_RULE = 'custom-rule|UPDATE_CUSTOM_RULE' as const;
export const GET_CUSTOM_RULE_LISTS = 'custom-rule|GET_CUSTOM_RULE_LISTS' as const;
export const SET_CUSTOM_RULE_LISTS = 'custom-rule|SET_CUSTOM_RULE_LISTS' as const;
export const UPDATE_CUSTOM_RULE_LIST = 'custom-rule|UPDATE_CUSTOM_RULE_LIST' as const;

type GetCustomRulesAction = {
  type: typeof GET_CUSTOM_RULES;
};

type SetCustomRulesAction = {
  type: typeof SET_CUSTOM_RULES;
  customRules: CustomRuleModel[];
};

type UpdateCustomRuleAction = {
  type: typeof UPDATE_CUSTOM_RULE;
  customRule: CustomRuleModel;
};

type GetCustomRuleListsAction = {
  type: typeof GET_CUSTOM_RULE_LISTS;
};

type SetCustomRuleListsAction = {
  type: typeof SET_CUSTOM_RULE_LISTS;
  customRuleLists: CustomRuleListModel[];
};

type UpdateCustomRuleListAction = {
  type: typeof UPDATE_CUSTOM_RULE_LIST;
  customRuleList: CustomRuleListModel;
};

export type CustomRuleActionTypes =
  | GetCustomRulesAction
  | SetCustomRulesAction
  | UpdateCustomRuleAction
  | GetCustomRuleListsAction
  | SetCustomRuleListsAction
  | UpdateCustomRuleListAction;
