import React, { memo, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { Button, Icon, useToast } from '@spotrisk/common';

type Props = {
  toast: ReactNode;
};

const BASE_CLASSES = [
  'fixed',
  'left-6',
  'bottom-11',
  'flex',
  'items-start',
  'max-w-sm',
  'w-full',
  'bg-white',
  'border',
  'border-gray-200',
  'overflow-hidden',
  'rounded-lg',
  'shadow-md',
  'p-4',
  'z-toast',
];

export const ToastNotification = memo(({ toast }: Props) => {
  const { removeToast } = useToast();
  const classes = cn(BASE_CLASSES);

  return ReactDOM.createPortal(
    <div className={classes}>
      <div className="flex-1">{toast}</div>
      <Button className="ml-4 self-center flex-shrink-0" state="text" onClick={removeToast}>
        <Icon className="text-gray-500" icon="close" />
      </Button>
    </div>,
    document.body
  );
});
