import React, { memo } from 'react';
import cn from 'classnames';

type Props = {
  isDisabled?: boolean;
};

const BASE_CLASSES = [
  'flex',
  'items-center',
  'justify-center',
  'bg-white',
  'text-header',
  'border',
  'border-blue-100',
  'border-dashed',
  'rounded-md',
  'w-full',
  'p-6',
  'mt-2',
];

export const CustomerListSummaryEmptyCard = memo(({ isDisabled }: Props) => {
  const classes = cn(BASE_CLASSES, {
    'opacity-50': isDisabled,
  });

  return <div className={classes}>There are no customers with this status</div>;
});
