import SpotDownImage from '@assets/images/spot/spot-down.png';
import SpotSitImage from '@assets/images/spot/spot-sit.png';
import SpotStandImage from '@assets/images/spot/spot-stand.png';
import TickImage from '@assets/svgs/tick.svg';
import { billingResource, billingSelectors } from '@spotrisk/billing';
import { Button, Loader, Text, useModal, useToast } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { PlanConfirmModal, SETTINGS_ROUTES } from '@spotrisk/settings';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const plans = [
  {
    displayName: 'Detection',
    name: 'detection-plan',
    interval: 'MONTHLY',
    price: '$99',
    id: '55dc91ac-0fa6-4735-a627-10ec12adec81',
    version: '1.0',
    isCurrent: true,
    limits: {
      customerBlocks: 0,
      customerIdentity: 0,
      escalations: 0,
      orderLimit: 250,
    },
  },
  {
    displayName: 'Verification',
    name: 'verification-plan',
    interval: 'MONTHLY',
    price: '$199',
    id: '9af75c9e-a091-4a7c-894b-a5f4ba357fa8',
    version: '1.0',
    isCurrent: true,
    limits: {
      customerBlocks: 5,
      customerIdentity: 5,
      escalations: -1,
      orderLimit: 600,
    },
  },
  {
    displayName: 'Automation',
    name: 'automation-plan',
    interval: 'MONTHLY',
    price: '$299',
    id: 'e76d9af0-f903-499b-8883-ab4f70d501a3',
    version: '1.0',
    isCurrent: true,
    limits: {
      customerBlocks: -1,
      customerIdentity: 20,
      escalations: -1,
      orderLimit: 1500,
    },
  },
];
export const PlansView = memo(() => {
  // TODO: Move this to parent component
  const history = useHistory();
  const { openModal } = useModal();
  const { addToast } = useToast();
  const billing = useSelector(billingSelectors.getBilling);
  const merchant = useSelector(merchantSelectors.getMerchant);

  const [isLoading, setIsLoading] = useState(false);

  const planId = billing?.subscription?.planId;

  const updatePlan = async (selectedPlanId: string) => {
    // TODO  check if not free plan selected
    if (merchant?.platform === 'UNKNOWN' || !merchant?.platform) {
      // Redirect to integration
      history.push(SETTINGS_ROUTES.integration);
      return;
    }

    if (planId === selectedPlanId) {
      openModal(<PlanConfirmModal />);
      return;
    }

    setIsLoading(true);
    try {
      const response = await billingResource.updatePlan({ merchant, plan: { planId: selectedPlanId, test: true } });
      if (response?.redirectUrl) {
        window.open(response?.redirectUrl, '_blank');
      }
    } catch (err) {
      addToast(<Text>Oops, something when wrong. Please try again or contact support@spotriskhq.com</Text>);
    }
    setIsLoading(false);
  };

  const planCTA = (index: number) => {
    // Hiding the cancel plan button if the current plan is free
    if (planId === plans[0].id && index === 0) {
      return;
    }

    const copy = planId === plans[index].id && index !== 0 ? 'Cancel Plan' : 'Choose plan';

    return (
      <Button
        className="mt-3 w-36"
        variant="blue"
        onClick={() => {
          updatePlan(plans[index].id);
        }}
      >
        {isLoading ? <Loader variant="secondary" size="sm" /> : copy}
      </Button>
    );
  };

  const currentPlanCardClass = (plan_id: string) => {
    if (planId === plan_id) {
      return 'card current-plan';
    }

    return 'card';
  };

  const currentPlanTitle = (plan_id: string) => {
    if (planId === plan_id) {
      return <h2 className="block text-2xl font-header text-header mb-2">Your current plan</h2>;
    }
  };
  const renderContent = () => {
    return (
      <div>
        <div className="price-options">
          <div className={currentPlanCardClass(plans[0].id)}>
            <div className="card-content">
              {currentPlanTitle(plans[0].id)}
              <Text className="block text-5xl font-header text-header mb-3">{plans[0].displayName}</Text>
              <Text className="price-monthly text-header block mb-2 font-semibold">
                {plans[0].price} USD <Text className="text-xl">/ Month</Text>
              </Text>
              <Text className="order-count text-header">
                <hr className="mb-3" />
                Perfect for brands who need accurate, real-time fraud detection to assist fulfilment decisions.
              </Text>
              <div className="plan-image mb-5">
                <img className="my-3" src={SpotDownImage} alt="Start Plan" />
              </div>
              <ul className="features-list mb-5">
                <li>
                  <Text className="text-header mb-3 font-semibold">What&apos;s included:</Text>
                </li>
                <li>
                  <Text className="text-header">
                    <TickImage className="tick" />
                    Up to <b>{plans[0].limits.orderLimit}</b> orders analysed per month
                  </Text>
                </li>
                <li>
                  <Text className="text-header">
                    <TickImage className="tick" />
                    All of our real time{' '}
                    <a
                      href="https://www.spotriskhq.com/features/fraud-detection"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-header mb-3 font-semibold underline"
                    >
                      fraud detection features
                    </a>
                    , including on-site behaviour analysis, and protection across the Spotrisk network
                  </Text>
                </li>
                <li>
                  <Text className="text-header">
                    <TickImage className="tick" />
                    Support via email and live chat
                  </Text>
                </li>
              </ul>
              {planCTA(0)}
            </div>
          </div>

          <div className={currentPlanCardClass(plans[1].id)}>
            <div className="card-content">
              {currentPlanTitle(plans[1].id)}
              <Text className="block text-5xl font-header text-header mb-3">{plans[1].displayName}</Text>
              <Text className="price-monthly text-header block mb-2 font-semibold">
                {plans[1].price} USD <Text className="text-xl">/ Month</Text>
              </Text>
              <Text className="order-count text-header">
                <hr className="mb-3" />
                For brands who need a bit more order volume, and the added security of our smart buyer verification solution.
              </Text>
              <div className="plan-image mb-5">
                <img className="my-3" src={SpotSitImage} alt="Standard plan" />
              </div>
              <ul className="features-list mb-5">
                <li>
                  <Text className="text-header mb-3 font-semibold">Everything in Detection, Plus:</Text>
                </li>
                <li>
                  <Text className="text-header">
                    <TickImage className="tick" />
                    Up to <b>{plans[1].limits.orderLimit}</b> orders analysed per month
                  </Text>
                </li>
                <li>
                  <Text className="text-header">
                    <TickImage className="tick" />
                    <a
                      href="https://www.spotriskhq.com/features/buyer-verification"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-header mb-3 font-semibold underline"
                    >
                      Buyer verification
                    </a>
                    , which allows you to confirm whether risky buyers are legit (or not) with a few simple clicks, for peace of mind.
                  </Text>
                </li>
              </ul>

              {planCTA(1)}
            </div>
          </div>

          <div className={currentPlanCardClass(plans[2].id)}>
            <div className="card-content">
              {currentPlanTitle(plans[2].id)}
              <Text className="block text-5xl font-header text-header mb-3">{plans[2].displayName}</Text>
              <Text className="price-monthly text-header block mb-2 font-semibold">
                {plans[2].price} USD <Text className="text-xl">/ Month</Text>
              </Text>
              <Text className="order-count text-header">
                <hr className="mb-3" />
                Full protection for brands who want to automate some - or all - of their fraud prevention efforts.
              </Text>
              <div className="plan-image mb-5">
                <img className="my-3" src={SpotStandImage} alt="Pro plan" />
              </div>
              <ul className="features-list mb-5">
                <li>
                  <Text className="text-header mb-2 font-semibold">Everything in Verification, Plus</Text>
                </li>
                <li>
                  <Text className="text-header">
                    <TickImage className="tick" />
                    Up to <b>{plans[2].limits.orderLimit}</b> orders analysed per month
                  </Text>
                </li>
                <li>
                  <Text className="text-header">
                    <TickImage className="tick" />
                    Powerful{' '}
                    <a
                      href="https://www.spotriskhq.com/features/buyer-verification"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-header mb-3 font-semibold underline"
                    >
                      automated workflows
                    </a>
                    , allowing you to automate your entire fraud detection and prevention flows, all within Spotrisk.
                  </Text>
                </li>
              </ul>
              {planCTA(2)}
            </div>
          </div>
        </div>
        {/* custom plan that spans the bottom */}
      </div>
    );
  };

  return <div>{renderContent()}</div>;
});
