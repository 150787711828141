import { parseISO } from 'date-fns';
import {
  CustomRuleListModel,
  CustomRuleListRequest,
  CustomRuleListResponse,
  CustomRuleModel,
  CustomRuleRequest,
  CustomRuleResponse,
} from '@spotrisk/custom-rule';

class CustomRuleService {
  serialiseCustomRule = (customRule: CustomRuleResponse): CustomRuleModel => ({
    id: customRule.id,
    merchantId: customRule.merchant_id,
    name: customRule.name,
    enabled: customRule.enabled,
    createdAt: parseISO(customRule.created_at),
    updatedAt: parseISO(customRule.updated_at),
    deletedAt: customRule.deleted_at ? parseISO(customRule.deleted_at) : undefined,
  });

  serialiseCustomRuleRequest = (customRule: CustomRuleModel): CustomRuleRequest => ({
    name: customRule.name,
    enabled: customRule.enabled,
  });

  serialiseCustomRuleList = (customRule: CustomRuleListResponse): CustomRuleListModel => ({
    id: customRule.id,
    merchantId: customRule.merchant_id,
    name: customRule.name,
    list: customRule.list ?? [],
    createdAt: parseISO(customRule.created_at),
    updatedAt: parseISO(customRule.updated_at),
    deletedAt: customRule.deleted_at ? parseISO(customRule.deleted_at) : undefined,
  });

  serialiseCustomRuleListRequest = (customRule: CustomRuleListModel): CustomRuleListRequest => ({
    name: customRule.name,
    list: customRule.list,
  });
}

export const customRuleService = new CustomRuleService();
