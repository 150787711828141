import { Gravatar, Dropdown, DropdownContent, Icon, Text } from '@spotrisk/common';
import { UserModel, userService } from '@spotrisk/user';
import React, { memo, useEffect, useRef, useState } from 'react';

export const AppHeaderActions = memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState<UserModel | null>(null);

  const avatarRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    (async () => {
      const user = await userService.getLoginUser();
      setUser(user);
    })();
  }, []);

  if (!user) {
    return null;
  }

  return (
    <section className="relative flex items-center">
      <Gravatar onClick={() => setIsOpen(!isOpen)} ref={avatarRef} email={user.email}>
        {user.name || '–'}
      </Gravatar>
      <Dropdown
        className="w-80 top-12 right-0 overflow-hidden"
        onClickOutside={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        toggleRef={avatarRef}
        isOpen={isOpen}
      >
        <DropdownContent className="hover:bg-gray-100">
          <Text className="font-medium text-gray-550">{user.email}</Text>
        </DropdownContent>
        <DropdownContent
          to="https://intercom.help/spotrisk/en/collections/3712330-spotrisk-articles-merchant"
          isExternal={true}
          isOpenedInNewTab={true}
          className="hover:bg-gray-100"
        >
          <div className="flex items-center">
            <Icon className="text-gray-450 mr-3.5" icon="web" />
            <Text className="font-medium text-gray-550">Help Docs</Text>
          </div>
        </DropdownContent>
        <DropdownContent to="/logout" className="hover:bg-gray-100">
          <div className="flex items-center">
            <Icon className="text-gray-450 mr-3.5" icon="logout" />
            <Text className="font-medium text-gray-550">Log out</Text>
          </div>
        </DropdownContent>
      </Dropdown>
    </section>
  );
});
