import { merchantSelectors } from '@spotrisk/merchant';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { settingsResource } from '../settings.resource';
import { MerchantIntegrationModel } from '../settings.models';
import { IntegrationCard } from './settings-custom-integration-card.component';

export const CustomIntegrationCards = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [integrations, setIntegrations] = useState<MerchantIntegrationModel[]>([]);

  useEffect(() => {
    getIntegrations();
  }, []);

  if (!merchant) {
    return null;
  }

  const getIntegrations = async () => {
    const integrations = await settingsResource.getMerchantIntegrations({ merchantId: merchant.id });
    setIntegrations(integrations);
  };

  const renderContent = integrations.map((integration) => {
    return <IntegrationCard key={integration.id} title={integration.name} subTitle={'Active | ' + integration.createdAt} integration={integration} />;
  });

  return <>{renderContent}</>;
});
