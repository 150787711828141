import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Iframe from 'react-iframe';
import { Loader, spotriskOrdersUrl } from '@spotrisk/common';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import { integrationResource } from '@spotrisk/integration';

export const ShopifyOrderView = () => {
  const apiKey = window.location.hostname.includes('local') ? 'f7148fe0b9482f7bdc6d8d64f5380f0f' : 'a76440fd4b21979854cd351ec0501072';
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get('action');
  const host = queryParams.get('host');
  const id = queryParams.get('id');
  const shop = queryParams.get('shop');
  const hmac = queryParams.get('hmac');

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [orderName, setOrderName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!id || !shop || !hmac || !host) {
        setError('Missing id, shop, or hmac in the URL');
        setIsLoading(false);
        return;
      }

      try {
        const response = await integrationResource.getIntegrationOrderView({
          integrationName: 'shopify',
          orderViewRequest: { id, shop, hmac },
        });
        setIsLoading(false);
        setToken(response.token);
        setOrderName(response.orderName);
      } catch (error) {
        setIsLoading(false);
        setError('Something went wrong. Please try again.');
      }
    };

    fetchData();
  }, [id, shop, hmac]);

  if (isLoading || !token) {
    return (
      <div className="w-full h-full md:pt-24 pt-24">
        <Loader size="lg" />
      </div>
    );
  }

  if (!host) {
    setError('Missing action or host in the URL');
    return <div className="text-red-500">{error}</div>;
  }

  const config = { host, apiKey, forceRedirect: false };

  return (
    <Provider config={config}>
      <TitleBar
        title="Review order"
        primaryAction={{
          content: `View Order ${orderName} in Spotrisk App`,
          url: `${spotriskOrdersUrl}/order?token=${token}&header=false&action=${action}`,
          external: true,
        }}
      />
      <Iframe className="w-full h-full" url={`${spotriskOrdersUrl}/order?token=${token}&header=false&action=${action}`} />
    </Provider>
  );
};
