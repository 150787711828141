import { apiService, Button, Dropdown, DropdownContent, Icon, ModalContent, ModalFooter, Text, useModal, useToast } from '@spotrisk/common';
import { MerchantModel } from '@spotrisk/merchant';
import React, { useState } from 'react';

type Props = {
  merchant: MerchantModel;
};

export type Option = {
  label: string;
  code: string;
};

export const ConnectorDisconnectModal = ({ merchant }: Props) => {
  const { closeModal } = useModal();
  const { addToast } = useToast();
  const [isSaving, setIsSaving] = useState(false);

  const options = [
    {
      code: 'disconnect_nothing',
      label: 'Please select a reason from the list',
    },
    {
      code: 'disconnect_problem_setup',
      label: 'I had a problem setting up Spotrisk',
    },
    {
      code: 'disconnect_feature',
      label: "Spotrisk doesn't have a feature that I am looking for",
    },
    {
      code: 'disconnect_value',
      label: "I don't see the value Spotrisk can offer me",
    },
    {
      code: 'disconnect_langague',
      label: "Spotrisk doesn't support my language",
    },
    {
      code: 'disconnect_other',
      label: 'Other',
    },
  ];
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [reason, setReason] = useState(options[0]);

  const handleIntegrationDisconnect = async () => {
    try {
      setIsSaving(true);
      if (reason.code == 'disconnect_nothing') {
        addToast(<Text>Please select a valid reason from the dropdown list.</Text>);
        setIsSaving(false);
        return;
      }

      const data = {
        reason: reason.code,
        message: reason.label,
      };

      await apiService.fetch(`/ecommerce/merchant/${merchant.id}/connect`, { method: 'DELETE', data: data });

      closeModal();
      setIsSaving(false);
      addToast(<Text>Disconnected from shopify</Text>);
      window.location.reload();
      return;
    } catch {
      addToast(<Text>Unexpected error.</Text>);
      setIsSaving(false);
    }
  };

  const handleSelect = (option: Option) => {
    setReason(option);
    setDropdownIsOpen(false);
  };

  return (
    <div className="max-w-lg">
      <ModalContent>
        <Text className="text-xl text-header text-left font-semibold mb-3" isBlock>
          Why are you disconnecting?
        </Text>
        <Text className="text-sm text-header text-left mt-5 mb-5" isBlock>
          Disconnecting will remove Spotrisk from protecting your store.
        </Text>
        <section className="relative">
          <div
            className="selectDropdown"
            onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setDropdownIsOpen(!dropdownIsOpen);
              }
            }}
            role="button"
            tabIndex={0}
            aria-label="Select dropdown"
          >
            <Text>{reason.label}</Text>
            <Icon icon="dropdown-arrows" />
          </div>
          <Dropdown
            className="w-30 top-5 right-0 overflow-hidden"
            onClickOutside={() => setDropdownIsOpen(false)}
            onClose={() => setDropdownIsOpen(false)}
            isOpen={dropdownIsOpen}
          >
            {options.map((option) => (
              <DropdownContent key={option.code} onClick={() => handleSelect(option)}>
                <Text className="whitespace-nowrap" isBlock>
                  {option.label}
                </Text>
              </DropdownContent>
            ))}
          </Dropdown>
        </section>
        <div className="flex">
          <div className="ml-0"></div>
        </div>
      </ModalContent>
      <ModalFooter className="text-right">
        <Button className="mr-2" onClick={closeModal} variant="gray" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleIntegrationDisconnect()} isDisabled={isSaving}>
          Disconnect
        </Button>
      </ModalFooter>
    </div>
  );
};
