import DropdownArrows from '@assets/svgs/dropdown-arrows.svg';
import AccountIconSvg from '@assets/svgs/icon-account.svg';
import BasketIconSvg from '@assets/svgs/icon-basket.svg';
import BlockCustomerIconSvg from '@assets/svgs/icon-block-customer.svg';
import ChevronRightIconSvg from '@assets/svgs/icon-chevron-right.svg';
import CloseIconSvg from '@assets/svgs/icon-close.svg';
import CreditCardIconSvg from '@assets/svgs/icon-credit-card.svg';
import EmailIconSvg from '@assets/svgs/icon-email.svg';
import ErrorDecagramIconSvg from '@assets/svgs/icon-error-decagram.svg';
import ErrorOctagonIconSvg from '@assets/svgs/icon-error-octagon.svg';
import GridIconSvg from '@assets/svgs/icon-grid.svg';
import LogoutIconSvg from '@assets/svgs/icon-logout.svg';
import MapMarkerIconSvg from '@assets/svgs/icon-map-marker.svg';
import SearchSvg from '@assets/svgs/icon-search.svg';
import VerticalDotsSvg from '@assets/svgs/icon-vertical-dots.svg';
import SpinnerIconSvg from '@assets/svgs/icon-spinner.svg';
import TickCircleIconSvg from '@assets/svgs/icon-tick-circle.svg';
import TruckIconSvg from '@assets/svgs/icon-truck.svg';
import UnblockCustomerIconSvg from '@assets/svgs/icon-unblock-customer.svg';
import WebIconSvg from '@assets/svgs/icon-web.svg';
import cn from 'classnames';
import React, { memo } from 'react';

export type IconType =
  | 'account'
  | 'basket'
  | 'block-customer'
  | 'unblock-customer'
  | 'chevron-right'
  | 'close'
  | 'credit-card'
  | 'email'
  | 'error-decagram'
  | 'error-octagon'
  | 'grid'
  | 'logout'
  | 'map-marker'
  | 'spinner'
  | 'tick-circle'
  | 'truck'
  | 'web'
  | 'search'
  | 'dropdown-arrows'
  | 'vertical-dots';

type Props = {
  icon: IconType;
  className?: string;
};

const BASE_CLASSES = ['inline-block'];

export const Icon = memo(({ className, icon }: Props) => {
  const fillIconClasses = cn(className, BASE_CLASSES, 'fill-current');
  const strokeIconClasses = cn(className, BASE_CLASSES, 'stroke-current');

  switch (icon) {
    case 'account':
      return <AccountIconSvg className={fillIconClasses} />;
    case 'basket':
      return <BasketIconSvg className={fillIconClasses} />;
    case 'block-customer':
      return <BlockCustomerIconSvg className={fillIconClasses} />;
    case 'unblock-customer':
      return <UnblockCustomerIconSvg className={fillIconClasses} />;
    case 'chevron-right':
      return <ChevronRightIconSvg className={strokeIconClasses} />;
    case 'close':
      return <CloseIconSvg className={strokeIconClasses} />;
    case 'credit-card':
      return <CreditCardIconSvg className={fillIconClasses} />;
    case 'error-decagram':
      return <ErrorDecagramIconSvg className={fillIconClasses} />;
    case 'error-octagon':
      return <ErrorOctagonIconSvg className={fillIconClasses} />;
    case 'email':
      return <EmailIconSvg className={fillIconClasses} />;
    case 'grid':
      return <GridIconSvg className={fillIconClasses} />;
    case 'logout':
      return <LogoutIconSvg className={fillIconClasses} />;
    case 'map-marker':
      return <MapMarkerIconSvg className={fillIconClasses} />;
    case 'spinner':
      return <SpinnerIconSvg className={strokeIconClasses} />;
    case 'tick-circle':
      return <TickCircleIconSvg className={fillIconClasses} />;
    case 'truck':
      return <TruckIconSvg className={fillIconClasses} />;
    case 'web':
      return <WebIconSvg className={fillIconClasses} />;
    case 'dropdown-arrows':
      return <DropdownArrows className={fillIconClasses} />;
    case 'search':
      return <SearchSvg className={strokeIconClasses} />;
    case 'vertical-dots':
      return <VerticalDotsSvg className={fillIconClasses} />;
    default:
      return <></>;
  }
});
