import React, { memo } from 'react';
import { CustomerDetailsDrawer, CustomerModel, customerService } from '@spotrisk/customer';
import { ListSummaryCard, Text, useModal, utilService } from '@spotrisk/common';

type Props = {
  customer: CustomerModel;
  isDisabled?: boolean;
};

export const CustomerListSummaryCard = memo(({ customer, isDisabled }: Props) => {
  const { openDrawer } = useModal();
  return (
    <ListSummaryCard onClick={() => openDrawer(<CustomerDetailsDrawer customer={customer} />)} isDisabled={isDisabled}>
      <div className="flex items-center justify-between">
        <Text className="text-lg text-header font-semibold mr-2">{customerService.getFullName(customer)}</Text>
        <img src={customer.gravatarUrl} className="w-8 h-8 rounded-full" alt="ProfilePicture" />
      </div>
      <div className="h-1 w-full border-b border-gray-200 my-2" />
      <div className="flex items-center justify-between">
        <Text className="text-xs text-header opacity-40 font-medium">{customer.email}</Text>
        <Text className="text-xs text-header opacity-40 font-medium">{utilService.getDisplayAddress(customer.addresses[0])}</Text>
      </div>
    </ListSummaryCard>
  );
});
