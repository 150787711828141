import { TenantModel } from '@spotrisk/tenant';
import { TenantActionTypes, SET_TENANT } from './tenant.types';

class TenantActions {
  setTenant = (tenant?: TenantModel): TenantActionTypes => ({
    type: SET_TENANT,
    tenant,
  });
}

export const tenantActions = new TenantActions();
