import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { UserModel } from '@spotrisk/user';

type DefaultFeature = {
  [key in any]: boolean;
};

const DEFAULT_CONFIG: DefaultFeature = {
  'ff-use-mocks': true,
  'ff-application-long-poll': false,
  'custom-rules-confirmation': false,
  'blocky-enabled-globally': true,
};

class FeatureService {
  private _client: LDClient | undefined;

  init = async (user?: UserModel) => {
    return new Promise((resolve) => {
      const apiKey =
        window.location.hostname.includes('localhost') ||
        window.location.hostname.includes('spotriskhq.dev.local') ||
        window.location.hostname.includes('staging')
          ? '6159395c2bd3d80d6862d1d5'
          : '6159395c2bd3d80d6862d1d6';

      if (user) {
        this._client = initialize(apiKey, { key: user.id, name: user.name, email: user.email });
      } else {
        this._client = initialize(apiKey, { anonymous: true });
      }

      this._client.on('ready', () => resolve('ready'));
    });
  };

  isEnabled = (feature: any): boolean => {
    return this._client?.variation(feature, DEFAULT_CONFIG[feature]);
  };
}

export const featureService = new FeatureService();
