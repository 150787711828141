import React, { memo } from 'react';
import { ActivityModel } from '../blocky.model';
import { Icon, ListSummaryCard, Text } from '@spotrisk/common'; // Add your actual imports here

type Props = {
  item: ActivityModel;
};

export const ActivityListCard = memo(({ item }: Props) => {
  const formattedTimestamp = new Date(item.timestamp).toLocaleString();
  let textVariant = 'primary';
  let icon = 'unblock-customer';
  let iconClassName = 'text-gray-500 mr-2';
  switch (item.outcome) {
    case 'allowed':
      textVariant = 'primary';
      icon = 'unblock-customer';
      iconClassName = 'text-gray-500 mr-2';
      break;
    case 'blocked':
      textVariant = 'danger';
      icon = 'block-customer';
      iconClassName = 'text-red-500 mr-2';
      break;
    case 'training':
      textVariant = 'warning';
      iconClassName = 'text-gray-500 mr-2';
      break;
  }

  return (
    <ListSummaryCard>
      <div className="flex items-center justify-between">
        <Text className="text-lg font-semibold mr-2 ml-1" variant={textVariant}>
          <Icon className={iconClassName} icon={icon} />
          {item.action}
        </Text>
        <Text className="text-lg text-header font-semibold">{formattedTimestamp}</Text>
      </div>
      <div className="h-1 w-full border-b border-gray-200 my-2" />
      <div className="flex items-center justify-between">
        <Text className="text-xs opacity-40 font-medium">{item.userAgent.slice(0, 75) + '...'}</Text>
        <Text className="text-xs opacity-40 font-semibold">{item.ipAddress}</Text>
      </div>
    </ListSummaryCard>
  );
});
