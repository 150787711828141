import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { orderReducer } from '@spotrisk/order';
import { tenantReducer } from '@spotrisk/tenant';
import { commonReducer } from './common.reducer';
import { merchantReducer } from '@spotrisk/merchant';
import { customerReducer } from '@spotrisk/customer';
import { customRuleReducer } from '@spotrisk/custom-rule';
import { settingsReducer } from '@spotrisk/settings';
import { billingReducer } from '@spotrisk/billing';
import { assessmentReducer } from '@spotrisk/assessment';
import { UserActionTypes, userReducer } from '@spotrisk/user';

const rootReducer = combineReducers({
  assessmentReducer,
  commonReducer,
  customerReducer,
  customRuleReducer,
  orderReducer,
  merchantReducer,
  billingReducer,
  settingsReducer,
  tenantReducer,
  userReducer,
});

export type ActionTypes = UserActionTypes;
export type IRootState = ReturnType<typeof rootReducer>;
export type AppThunk<R> = ThunkAction<R, IRootState, undefined, ActionTypes>;

const middleware = applyMiddleware(thunkMiddleware);
const enhancer = process.env.NODE_ENV === 'development' ? composeWithDevTools(middleware) : middleware;

export const store = createStore(rootReducer, enhancer);
