import { Page, PageContent } from '@spotrisk/common';
import {
  PlansPage,
  CustomisationPage,
  CustomRulesPage,
  NotificationPage,
  UsersPage,
  SettingsIntegrationPage,
  SettingsMenu,
  SETTINGS_ROUTES,
} from '@spotrisk/settings';
import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';

export const SettingsPage = memo(() => {
  return (
    <Page>
      <PageContent className="min-h-screen" heading="Account Settings">
        <div className="flex max-w-screen-xl mx-auto">
          <SettingsMenu />
          <div className="flex justify-center mb-10 w-full" style={{ minWidth: '1024px' }}>
            <Switch>
              <Route path={SETTINGS_ROUTES.root} component={CustomisationPage} exact />
              <Route path={SETTINGS_ROUTES.integration} component={SettingsIntegrationPage} exact />
              <Route path={SETTINGS_ROUTES.plans} component={PlansPage} exact />
              <Route path={SETTINGS_ROUTES.users} component={UsersPage} exact />
              <Route path={SETTINGS_ROUTES.notification} component={NotificationPage} exact />
              <Route path={SETTINGS_ROUTES.customRules} component={CustomRulesPage} exact />
            </Switch>
          </div>
        </div>
      </PageContent>
    </Page>
  );
});
