import { Button, Input, ModalContent, ModalFooter, ModalHeader, Text, useModal } from '@spotrisk/common';
import { customRuleActions, CustomRuleListModel, customRuleResource } from '@spotrisk/custom-rule';
import { MerchantModel } from '@spotrisk/merchant';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  ruleList: CustomRuleListModel;
  merchant: MerchantModel;
};

export const AddressBlockListModal = ({ ruleList, merchant }: Props) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const [isSaving, setIsSaving] = useState(false);
  const [input, setInput] = useState('');
  const [list, setList] = useState(ruleList.list);

  const handleRemove = (index: number) => {
    const update = [...list];
    update.splice(index, 1);
    setList(update);
  };

  const handleSubmit = async (address1: string) => {
    setIsSaving(true);

    const update = {
      ...ruleList,
      list: list.sort(),
    };

    if (address1 && !list.includes(address1)) {
      update.list = [address1, ...list].sort();
    }

    await customRuleResource.updateCustomRuleList({ merchantId: merchant.id, customRuleList: update });
    dispatch(customRuleActions.updateCustomRuleList(update));

    closeModal();
  };

  return (
    <div className="max-w-lg" style={{ minWidth: '550px' }}>
      <ModalHeader>
        <Text className="text-xl text-header font-semibold mb-3" isBlock>
          Address block list
        </Text>
        <Text className="text-sm text-header" isBlock>
          Please fill in your address information.
        </Text>
      </ModalHeader>
      <ModalContent className="pt-0">
        <div className="flex relative">
          <Input className="bg-gray-100 h-12" value={input} placeholder="Address 1" onChange={({ target }) => setInput(target.value)} autoFocus />
        </div>
        <Input
          className="bg-gray-100 h-12 mt-6"
          placeholder="Address 2"
          onChange={() => {
            /* noop */
          }}
        />
        <div className="flex justify-between">
          <Input
            className="bg-gray-100 h-12 mt-6 mr-2"
            placeholder="City"
            onChange={() => {
              /* noop */
            }}
          />
          <Input
            className="bg-gray-100 h-12 mt-6 ml-2"
            placeholder="State/Province"
            onChange={() => {
              /* noop */
            }}
          />
        </div>
        <div className="flex justify-between">
          <Input
            className="bg-gray-100 h-12 mt-6 mr-2"
            placeholder="Zip/Postal Code"
            onChange={() => {
              /* noop */
            }}
          />
          <Input
            className="bg-gray-100 h-12 mt-6 ml-2"
            placeholder="Country"
            onChange={() => {
              /* noop */
            }}
          />
        </div>
        {list.length ? (
          <div className="mt-6">
            Blocked Addresses
            <div className="mt-6 max-h-40 overflow-y-auto">
              {list.map((ip, index) => (
                <div key={index} className="flex justify-between items-center w-full my-2.5 p-4 block-list-item">
                  <div>{ip}</div>
                  <Button variant="danger" state="text" onClick={() => handleRemove(index)}>
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} variant="gray" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button className="ml-2" variant="blue" type="submit" onClick={() => handleSubmit(input)} isDisabled={isSaving}>
          Update
        </Button>
      </ModalFooter>
    </div>
  );
};
