import { useTabs } from '@spotrisk/common';
import cn from 'classnames';
import { find, indexOf, map } from 'lodash';
import React, { ForwardedRef, forwardRef, ReactNode } from 'react';

type Props<T> = {
  className?: string;
  id: T;
  label: ReactNode;
  variant?: 'underline';
  isDisabled?: boolean;
};

const BASE_CLASSES = ['flex', 'items-center', 'hover:opacity-100', 'focus:outline-none'];
const BASE_UNDERLINE_CLASSES = ['font-medium', 'text-header', 'opacity-40', 'border-transparent', 'mr-6', 'lg:mr-8', 'py-2', 'last:mr-0'];

function TabButtonInner<T>({ className, id, label, variant, isDisabled }: Props<T>, ref: ForwardedRef<HTMLButtonElement>) {
  const { activeTab, setActiveTab, tabs } = useTabs();
  const tab = find(tabs, ({ id: idToCheck }) => idToCheck === id);

  if (!tab) {
    throw new Error(`The '${id}' tab does not exist in the tabs [${map(tabs, 'id').join(', ')}].`);
  }

  let classes = cn(className, BASE_CLASSES, {
    'mr-2': indexOf(tabs, tab) !== tabs.length - 1,
    'opacity-100': activeTab?.id === id,
    'opacity-50 pointer-events-none': isDisabled,
  });

  if (variant === 'underline') {
    classes = cn(classes, BASE_UNDERLINE_CLASSES);
  }

  return (
    <button className={classes} onClick={() => setActiveTab(tab)} type="button" disabled={isDisabled} ref={ref}>
      {/* Wrapping the content in a div, so we can get the exact width of the content without having to hard code the padding offset. */}
      <div>{label}</div>
    </button>
  );
}

export const TabButton = forwardRef(TabButtonInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof TabButtonInner>;
