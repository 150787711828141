export const isDev = window.location.hostname.includes('localhost');
export const isDocker = window.location.hostname.includes('spotriskhq.dev.local');
export const isStaging = window.location.hostname.includes('staging.spotriskhq.com');
export const isLocal = isDev || isDocker;

export const spotriskApiUrl = isLocal ? 'http://0.0.0.0:3014' : 'https://api.spotriskhq.com';
export const spotriskApiVersion = '/2020-06';
export const spotriskAppUrl = isLocal
  ? isDev
    ? 'http://localhost:3000'
    : 'https://app.spotriskhq.dev.local'
  : isStaging
  ? 'https://staging.spotriskhq.com'
  : 'https://app.spotriskhq.com';
export const spotriskAccountsUrl = isLocal
  ? isDev
    ? 'http://localhost:3001'
    : 'https://accounts.spotriskhq.dev.local'
  : 'https://accounts.spotriskhq.com';
export const spotriskOrdersUrl = isLocal
  ? isDev
    ? 'http://localhost:3002'
    : 'https://orders.spotriskhq.dev.local'
  : isStaging
  ? 'https://orders-staging.spotriskhq.com'
  : 'https://orders.spotriskhq.com';
