import React, { forwardRef, ReactNode } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

type Props = {
  className?: string;
  children?: ReactNode;
};

export const Page = forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => {
  const classes = cn(className, 'h-full min-h-screen');

  <AnimatePresence>
    <motion.div
      className={classes}
      ref={ref}
      initial={{ y: -5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 10 }}
      exit={{ y: -5, opacity: 0 }}
    >
      {children}
    </motion.div>
  </AnimatePresence>;

  return <div className={classes}>{children}</div>;
});
