import { Button, ModalContent, ModalFooter, Text, useModal, useToast } from '@spotrisk/common';
import { MerchantWebhookConfigurationModal, WebhookConfigurationEvents } from '../settings.models';
import React, { useState } from 'react';
import { settingsResource } from '../settings.resource';
import { useSelector } from 'react-redux';
import { merchantSelectors } from '@spotrisk/merchant';

type Props = {
  webhookConfig?: MerchantWebhookConfigurationModal;
  integrationId: string;
};

const eventLabels: Record<WebhookConfigurationEvents, string> = {
  ORDER: ' Order created or updated',
  CUSTOMER: ' Customer created or updated',
  ORDER_FLAGGED: ' Order flagged',
  ORDER_SAFE: ' Order safe',
  ORDER_ESCALATED: ' Order escalated',
  ORDER_SAFE_ACTION: ' Order marked safe',
  ORDER_FRAUD_ACTION: ' Order marked fraud',
};

export const IntegrationWebhookModal = ({ webhookConfig, integrationId }: Props) => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const { closeModal } = useModal();
  const { addToast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [enabled, setEnabled] = useState(webhookConfig?.enabled || false);
  const [url, setUrl] = useState(webhookConfig?.url || '');
  const [events, setEvents] = useState<WebhookConfigurationEvents[]>(webhookConfig?.events || []);

  if (!merchant) {
    return null;
  }

  const handleDelete = async () => {
    if (!webhookConfig?.id) {
      return null;
    }

    // Your delete logic here
    // For example:
    await settingsResource.deleteMerchantIntegrationWebhook({ merchantId: merchant.id, integrationId, webhookId: webhookConfig.id });
    closeModal(); // Close the modal after deletion
    addToast(<Text>Webhook configuration deleted successfully!</Text>);
    // wait 3 seconds before reloading the page
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleSave = async () => {
    if (!url || events.length === 0) {
      addToast(<Text>Please provide a URL and select at least one event.</Text>);
      return;
    }

    setIsSaving(true);
    const data = {
      integrationId,
      enabled,
      url,
      events,
    };

    try {
      if (webhookConfig?.id) {
        await settingsResource.putMerchantIntegrationWebhook({
          merchantId: merchant.id,
          integrationId,
          webhookId: webhookConfig.id,
          webhookConfiguration: data,
        });
      } else {
        await settingsResource.postMerchantIntegrationWebhook({ merchantId: merchant.id, integrationId, webhookConfiguration: data });
      }

      closeModal();
      addToast(<Text>Webhook configuration saved successfully!</Text>);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      addToast(<Text>Unexpected error our support team has been notified.</Text>);
      setIsSaving(false);
    }
  };

  const handleToggleEvent = (event: WebhookConfigurationEvents) => {
    setEvents((prevEvents) => (prevEvents.includes(event) ? prevEvents.filter((e) => e !== event) : [...prevEvents, event]));
  };

  return (
    <div className="max-w-lg">
      <ModalContent>
        <Text className="text-xl text-header text-left font-semibold mb-3" isBlock>
          Webhook Configuration
        </Text>
        <Text className="text-sm text-header text-left mt-5 mb-5" isBlock>
          Select which events you would like the webhook URL to be called with when they occur. Each event will be called with a payload containing
          the event type and the data associated with the event (order or customer).
        </Text>
        <Text className="text-sm text-header text-left  mb-5" isBlock>
          Please supply the URL for the webhook to be called:
        </Text>
        <input
          type="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="border border-gray-300 p-2 w-full mb-5"
          placeholder="Webhook URL"
        />
        <div className="mb-5">
          {(
            [
              'ORDER',
              'CUSTOMER',
              'ORDER_FLAGGED',
              'ORDER_SAFE',
              'ORDER_ESCALATED',
              'ORDER_SAFE_ACTION',
              'ORDER_FRAUD_ACTION',
            ] as WebhookConfigurationEvents[]
          ).map((event) => (
            <label key={event} className="block">
              <input type="checkbox" checked={events.includes(event)} onChange={() => handleToggleEvent(event)} />
              {eventLabels[event]}
            </label>
          ))}
        </div>
        <label className="block mb-5">
          <input type="checkbox" checked={enabled} onChange={(e) => setEnabled(e.target.checked)} />
          <span className="mr-1"> Active</span>
        </label>
        <Text className="text-sm text-header text-left mt-5 mb-5" isBlock>
          Note: The webhook URL must be accessible from the internet. If you are running the application locally you can uses{' '}
          <a href="https://ngrok.com/" target="_blank" rel="noopener noreferrer">
            ngrok.
          </a>
        </Text>
      </ModalContent>
      <ModalFooter className="flex justify-between">
        <Button variant="danger" onClick={handleDelete}>
          Delete Webhook
        </Button>
        <div className="text-right">
          <Button className="mr-2" onClick={closeModal} variant="gray">
            Cancel
          </Button>
          <Button variant="blue" onClick={handleSave} isDisabled={isSaving}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </div>
  );
};
