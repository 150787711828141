import { AssessmentModel } from '@spotrisk/assessment';

export type AssessmentReducerState = {
  assessments: AssessmentModel[];
  assessment: AssessmentModel | null;
};

export const SET_ASSESSMENTS = 'assessment|SET_ASSESSMENTS' as const;
export const SET_ASSESSMENT = 'assessment|SET_ASSESSMENT' as const;

type SetAssessmentsAction = {
  type: typeof SET_ASSESSMENTS;
  assessments: AssessmentModel[];
};

type SetAssessmentAction = {
  type: typeof SET_ASSESSMENT;
  assessment: AssessmentModel | null;
};

export type AssessmentActionTypes = SetAssessmentsAction | SetAssessmentAction;
