import {
  GET_ORDERS,
  OrderActionTypes,
  OrderListModel,
  OrderReducerSort,
  OrderReducerState,
  REMOVE_ORDER,
  SET_ORDER,
  SET_ORDERS,
  SET_ORDER_SEARCH,
  SET_ORDER_SORT,
  SET_ORDER_STATE,
} from '@spotrisk/order';
import { filter } from 'lodash';

const initialState: OrderReducerState = {
  order: null,
  orders: [],
  sort: 'latest',
  orderState: 'FLAGGED',
  status: 'ready',
};

const sortOrders = (orders: OrderListModel[], sort: OrderReducerSort) => [
  ...orders.sort((a: OrderListModel, b: OrderListModel) => {
    if (sort === 'latest') return a.orderDate > b.orderDate ? -1 : 1;

    return b.orderDate > a.orderDate ? -1 : 1;
  }),
];

export const orderReducer = (state = initialState, action: OrderActionTypes): OrderReducerState => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: sortOrders(action.orders, state.sort),
        status: 'ready',
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.order,
      };
    case REMOVE_ORDER:
      return {
        ...state,
        orders: filter(state.orders, (order: OrderListModel) => order.id !== action.id),
      };
    case GET_ORDERS:
      return {
        ...state,
        status: 'loading',
      };
    case SET_ORDER_SEARCH:
      return {
        ...state,
        search: action.search,
        orderState: action.search ? undefined : state.orderState,
      };
    case SET_ORDER_SORT:
      return {
        ...state,
        orders: sortOrders(state.orders, action.sort),
        sort: action.sort,
      };
    case SET_ORDER_STATE:
      return {
        ...state,
        orderState: action.orderState,
        search: undefined,
      };
    default:
      return state;
  }
};
