import React, { FormEvent, memo, useEffect, useState } from 'react';
import { authResource, AUTH_ROUTES, authService } from '@spotrisk/auth';
import { Page, PageContent, Text, Input, Button, Loader, Hr, spotriskAppUrl } from '@spotrisk/common';
import { IntegrationName, integrationResource } from '@spotrisk/integration';

type formData = {
  email: string;
  name: string;
  password: string;
};

export const SignupPage = memo(() => {
  const loginUrl = new URL(`${spotriskAppUrl}${AUTH_ROUTES.login}`);

  const urlParams = new URLSearchParams(window.location.search);
  const redirectParam = urlParams.get('redirect');
  const redirect = redirectParam ? decodeURIComponent(redirectParam) : undefined;
  const signature = urlParams.get('signature') ?? undefined;
  const integrationName = urlParams.get('integration') as IntegrationName;

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProvisioning, setIsProvisioning] = useState(false);
  const [isAgreed, setIsAgreed] = useState(urlParams.get('agreed') ? true : false);
  const [formData, setFormData] = useState<formData>({ email: urlParams.get('email') ?? '', name: urlParams.get('email') ?? '', password: '' });

  useEffect(() => {
    authService.logout();
    setIsLoading(false);
  });

  const handleLogin = () => {
    setIsLoading(true);
    if (redirect) {
      loginUrl.searchParams.append('redirect', redirect);
    }
    if (signature) {
      loginUrl.searchParams.append('signature', signature);
    }
    if (integrationName) {
      loginUrl.searchParams.append('integration', integrationName);
    }
    window.location.href = loginUrl.href;
  };

  const handleSignUp = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!formData.email || !formData.password || !formData.name) {
      setError('You are missing fields please check the form and try again.');
      return;
    }

    try {
      setIsProvisioning(true);

      signature
        ? await integrationResource.createUser({
            integrationName: integrationName,
            signature: signature,
            user: {
              email: formData.email,
              name: formData.name,
              password: formData.password,
            },
          })
        : await authResource.provisionUser({
            source: 'WEB',
            token: 'whosyourdaddy?',
            type: 'user',
            user: {
              email: formData.email,
              name: formData.name,
              password: formData.password,
            },
          });

      await authService.login({ email: formData.email, password: formData.password });
      window.location.href = redirect ?? spotriskAppUrl;
    } catch (error) {
      if (error instanceof Error) {
        setError('Please check your email and password, and try again.');
      } else {
        setError('An unexpected error occurred. Please contact support@spotriskhq.com');
      }
      setIsProvisioning(false);
    }
  };

  if (isLoading) {
    return <Loader className="mt-48" size="lg" />;
  }

  return (
    <Page>
      <PageContent isCentered>
        {isProvisioning ? (
          <div className="text-center items-center justify-center">
            <Loader size="lg" />
            <h2 className="block text-5xl font-header text-header">Hang tight, we&apos;re getting Spotrisk ready!</h2>
          </div>
        ) : (
          <div className="max-w-lg z-10">
            <div className="mb-4">
              <img className="w-20" src="https://cdn.spotriskhq.com/cdn/assets/public/img/logo-long.svg" alt="spotrisk-logo" />
            </div>
            <Hr />
            <div className="w-auto">
              <div className="bg-white p-7 rounded-lg shadow-xl border-gray-300">
                <div className="text-left mb-8">
                  <Text className="text-2xl font-bold mt-8">Create your Spotrisk account</Text>
                </div>
                <form onSubmit={handleSignUp}>
                  <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700 text-sm font-semibold mb-2">
                      Name
                    </label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="John Smith"
                      onChange={({ target }) => setFormData({ ...formData, name: target.value })}
                      value={formData.name}
                      className="shadow h-12 appearance-none border rounded-lg w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
                      Email
                    </label>
                    <Input
                      id="email"
                      type="email"
                      placeholder="john.smith@company.com"
                      onChange={({ target }) => setFormData({ ...formData, email: target.value })}
                      value={formData.email}
                      className="shadow h-12 text-lg appearance-none border rounded-lg w-full  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-6">
                    <label htmlFor="password" className="block text-gray-700 text-sm font-semibold mb-2">
                      Password
                    </label>
                    <Input
                      id="password"
                      type="password"
                      placeholder="Password"
                      onChange={({ target }) => setFormData({ ...formData, password: target.value })}
                      value={formData.password}
                      className="shadow rounded-lg h-12 appearance-none border w-full  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="checkbox" className="flex items-center">
                      <input
                        id="checkbox"
                        type="checkbox"
                        checked={isAgreed}
                        onChange={(e) => setIsAgreed(e.target.checked)}
                        className="mr-2"
                        required
                      />
                      <span className="text-gray-700 text-sm font-semibold">
                        I agree to the Spotrisk{' '}
                        <a className="underline" href="https://www.spotriskhq.com/terms" target="_blank" rel="noopener noreferrer">
                          Terms & Conditions
                        </a>{' '}
                        and{' '}
                        <a className="underline" href="https://www.spotriskhq.com/privacy" target="_blank" rel="noopener noreferrer">
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                  {error && (
                    <div id="error-message" className="text-center mb-5 mt-5">
                      <Text variant="danger">{error}</Text>
                    </div>
                  )}
                  <div className="text-center">
                    <Button type="submit" className="text-white w-full" variant="blue" isDisabled={!isAgreed || isProvisioning}>
                      {isProvisioning ? 'Creating your account...' : 'Create account'}
                    </Button>
                    <Hr />
                    <Text className="text-center text-sm mr-1">Already have an account?</Text>
                    <Button state="text" onClick={handleLogin} variant="blue" className="text-sm">
                      Sign in
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </PageContent>
      <footer className="fixed bottom-0 left-0 w-full h-10 flex items-center justify-center">
        <img className="w-full" src="https://cdn.spotriskhq.com/cdn/assets/public/img/testimonials-bottom.svg" alt="bottom-banner" />
      </footer>
    </Page>
  );
});
