import React, { memo } from 'react';
import { Hr, Text, useToast } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { SettingsModel, settingsSelectors, SpotAlertness, settingsActions, settingsResource, Blocky, StoreSettings } from '@spotrisk/settings';
import { useDispatch, useSelector } from 'react-redux';
export const CustomisationPage = memo(() => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const merchant = useSelector(merchantSelectors.getMerchant);
  const settings = useSelector(settingsSelectors.getSettings);

  if (!merchant || !settings) {
    return null;
  }

  const updateSettings = async (settingsUpdate: Partial<SettingsModel>) => {
    try {
      const updatedSettings = await settingsResource.updateSettings({ merchant, settingsUpdate });
      dispatch(settingsActions.setSettings(updatedSettings));
      addToast(<Text className="text">Settings updated</Text>);
    } catch (error) {
      addToast(
        <Text className="text" variant="danger">
          Failed to update settings
        </Text>
      );
    }
  };

  const renderContent = () => {
    return (
      <>
        <div>
          <Text className="text-xl text-header font-bold mr-2 antialiased block tracking-wide">Spot&apos;s Alertness</Text>
          <Text className="text-xs font-header text-header font-light block mt-2 mb-7">
            Train Spot to flag at-risk orders for you to manually review, or automatically approve them for you.
          </Text>
        </div>
        <div className="mb-4">
          <SpotAlertness settings={settings} onUpdate={updateSettings} orderCount={settings.orderCount || 0} />
        </div>
        <div className="mb-4"></div>
        <Hr />
        <div className="mb-4 mt-4 w-1/2">
          <StoreSettings settings={settings} onUpdate={updateSettings} />
          <Hr />
        </div>
        <div className="mb-4 mt-4">
          <Blocky settings={settings} onUpdate={updateSettings} />
        </div>
      </>
    );
  };

  return <div>{renderContent()}</div>;
});
