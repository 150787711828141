import { CustomerModel } from '@spotrisk/customer';
import { CustomerActionTypes, SET_CUSTOMER, SET_CUSTOMERS } from './customer.types';

class CustomerActions {
  setCustomers = (customers: CustomerModel[]): CustomerActionTypes => ({
    type: SET_CUSTOMERS,
    customers,
  });

  setCustomer = (customer: CustomerModel | null): CustomerActionTypes => ({
    type: SET_CUSTOMER,
    customer,
  });
}

export const customerActions = new CustomerActions();
