import React, { memo, useState } from 'react';
import { PlansView } from '@spotrisk/settings';
import { Text, useToast } from '@spotrisk/common';

export const PlansPage = memo(() => {
  const [upgradeSuccess, setUpgradeSuccess] = useState(false);
  const { addToast } = useToast();

  const params = new URLSearchParams(window.location?.search);
  if (params.get('status') === 'confirmed' && !upgradeSuccess) {
    setUpgradeSuccess(true);
    addToast(<Text>Plan upgraded successfully.</Text>);
  }

  return (
    <>
      <div className="w-full">
        <div>
          <Text className="text-2xl text-header font-bold mr-2 antialiased block tracking-wide">Plans & Billing</Text>
          <Text className="text-xs font-header text-header font-light block mt-2 mb-7">Select or adjust a plan that best suits your business.</Text>
        </div>
        <PlansView />
      </div>
    </>
  );
});
