import { AppErrorPage, appResource } from '@spotrisk/app';
import { authService } from '@spotrisk/auth';
import { billingActions } from '@spotrisk/billing';
import { featureService, Loader, localStorageService, STANDARD_ACCESS_ROUTES } from '@spotrisk/common';
import { useAsyncEffect } from '@spotrisk/common/hooks';
import { merchantActions } from '@spotrisk/merchant';
import { SETTINGS_ROUTES, settingsActions, settingsResource } from '@spotrisk/settings';
import { tenantActions } from '@spotrisk/tenant';
import { userActions, userService } from '@spotrisk/user';
import { find } from 'lodash';
import React, { ComponentType, createElement, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router-dom';

type Props = RouteProps & {
  component: ComponentType<any>;
};

const loginUrl = window.location.hostname.includes('local') ? 'http://localhost:3000/login' : 'https://app.spotriskhq.com/login';

export const AppRoute = memo((props: Props) => {
  const dispatch = useDispatch();
  const { component, ...rest } = props;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  useAsyncEffect(async () => {
    try {
      if (!(await userService.isAuthenticated())) {
        authService.logout();
        window.location.href = `${loginUrl}?redirect=${encodeURIComponent(window.location.href)}`;
        return;
      }

      const { merchants, tenant, user, billings } = await appResource.getBootstrap();
      await featureService.init(user);

      // Grab the merchant from local storage, otherwise default to the first merchant in the list.
      const merchantId = localStorageService.getItem('selected-merchant-id');
      const merchant = find(merchants, ({ id }) => id === merchantId) || merchants[0];

      // Store the merchant id if default was used.
      localStorageService.setItem('selected-merchant-id', merchant.id);

      dispatch(merchantActions.setMerchants(merchants));
      dispatch(merchantActions.setMerchant(merchant));
      dispatch(tenantActions.setTenant(tenant));
      dispatch(userActions.setUser(user));

      const billing = find(billings, ({ merchantId }) => merchantId === merchant?.id);
      if (billing) {
        dispatch(billingActions.setBilling(billing));
      }

      dispatch(settingsActions.setSettings(await settingsResource.getSettings({ merchant })));

      history.listen((location: any) => {
        if (billing?.subscription?.status !== 'ACTIVE') {
          if (billing?.subscription?.status === 'TRIAL-EXPIRED' && !STANDARD_ACCESS_ROUTES.includes(location.pathname)) {
            // redirect to billing
            history.push(SETTINGS_ROUTES.plans);
          }

          if (billing?.subscription?.status === 'TRIAL' && merchant?.platform === 'UNKNOWN' && !STANDARD_ACCESS_ROUTES.includes(location.pathname)) {
            // redirect to integration
            history.push(SETTINGS_ROUTES.integration);
          }

          if (merchant?.platform !== 'UNKNOWN' && billing?.subscription?.status === 'TRIAL') {
            // Allow full use of the app
            return;
          }

          // Else redirect to plan
          if (!STANDARD_ACCESS_ROUTES.includes(location.pathname)) {
            history.push(SETTINGS_ROUTES.plans);
          }
        }
      });

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <Loader className="mt-48" size="lg" />;
        }

        if (isError) {
          return <AppErrorPage />;
        }

        return createElement(component, props);
      }}
    />
  );
});
