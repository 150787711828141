import SpotStandLockImage from '@assets/images/spot/spot-stand-lock.png';
import { Button, ModalContent, ModalFooter, Text, useModal } from '@spotrisk/common';
import { SETTINGS_ROUTES } from '@spotrisk/settings';
import React from 'react';

export const CustomRulesFeatureModal = () => {
  const { closeModal } = useModal();

  return (
    <div className="max-w-xl text-center">
      <ModalContent>
        <img className="my-3 mx-auto" src={SpotStandLockImage} alt="Lock" />
        <Text className="text-xl text-header font-semibold mb-3" isBlock>
          Advanced Rules is not available on your current plan
        </Text>
        <Text className="text-sm text-header mt-5 mb-5" isBlock>
          To access Advanced Rules, you will need to upgrade to the pro plan.
        </Text>
        <Text className="text-sm text-header mt-5 mb-5" isBlock>
          Read more about how Spotrisk&apos;s Advanced Rules work{' '}
          <a className="tulip" href="https://intercom.help/spotrisk/en/articles/6783589-custom-rules" target="_blank" rel="noreferrer">
            here
          </a>{' '}
          and upgrade to the pro plan today!
        </Text>
      </ModalContent>
      <ModalFooter>
        <Button className="mr-2" onClick={closeModal} href={SETTINGS_ROUTES.plans} variant="blue" isLink={true}>
          Upgrade your plan
        </Button>
      </ModalFooter>
    </div>
  );
};
