import { map } from 'lodash';
import { MerchantModel } from '@spotrisk/merchant';
import { apiService } from '@spotrisk/common';
import { CustomerModel, CustomerResponse, customerService } from '@spotrisk/customer';
import { OrderModel, orderService } from '@spotrisk/order';

type QueryCustomerParam = {
  merchant: MerchantModel;
  isBlocked?: boolean;
};

type QueryCustomerOrdersParam = {
  merchant: MerchantModel;
  id: string;
};

type GetCustomerParam = {
  merchant: MerchantModel;
  id: string;
};

class CustomerResource {
  queryCustomers = async ({ merchant, isBlocked }: QueryCustomerParam): Promise<CustomerModel[]> => {
    const isBlockedParam = isBlocked ? 'TRUE' : 'FALSE';
    const customers = await apiService.fetch<CustomerResponse[]>(`/ecommerce/merchant/${merchant.id}/customer?count=50&is_blocked=${isBlockedParam}`);

    return map(customers, customerService.serialiseCustomer);
  };

  queryCustomerWithOrders = async ({ merchant, id }: QueryCustomerOrdersParam): Promise<CustomerModel | null> => {
    const searchParams = new URLSearchParams();
    searchParams.append('sort', 'ASC');

    const customer = await apiService.fetch<CustomerResponse>(`/ecommerce/merchant/${merchant.id}/customer/${id}/order?${searchParams}`);

    if (!customer) {
      return null;
    }

    return customerService.serialiseCustomer(customer);
  };

  getCustomer = async ({ merchant, id }: GetCustomerParam): Promise<CustomerModel | null> => {
    const customer = await apiService.fetch<CustomerResponse>(`/ecommerce/merchant/${merchant.id}/customer/${id}`);
    if (!customer) {
      return null;
    }

    return customerService.serialiseCustomer(customer);
  };

  getCustomerOrders = async ({ merchant, id }: GetCustomerParam): Promise<OrderModel[]> => {
    const customer = await apiService.fetch<CustomerResponse>(`/ecommerce/merchant/${merchant.id}/customer/${id}/order`);
    if (!customer) {
      return [];
    }

    return map(customer.orders, orderService.serialiseOrder);
  };

  blockCustomer = async ({ merchant, id }: GetCustomerParam): Promise<void> => {
    await apiService.fetch(`/ecommerce/merchant/${merchant.id}/customer/${id}/block`, { method: 'POST', data: { is_blocked: true } });
  };

  unblockCustomer = async ({ merchant, id }: GetCustomerParam): Promise<void> => {
    await apiService.fetch(`/ecommerce/merchant/${merchant.id}/customer/${id}/block`, { method: 'POST', data: { is_blocked: false } });
  };
}

export const customerResource = new CustomerResource();
