import { SettingsReducerState, SettingsActionTypes, SET_SETTINGS } from './settings.types';

const initialState: SettingsReducerState = {};

export const settingsReducer = (state = initialState, action: SettingsActionTypes) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    default:
      return state;
  }
};
