import React, { useState, useCallback, memo, ReactNode, useContext, useMemo, createContext } from 'react';
import { ToastNotification } from '@spotrisk/common';

export type ToastContext = {
  addToast(toast: ReactNode): void;
  removeToast(): void;
};

const ToastContext = createContext<ToastContext | null>(null);

// Auto dismiss toasts after 3 seconds.
const TOAST_DISMISS_TIMEOUT = 300000;

type Props = {
  children: ReactNode;
};

export const ToastProvider = memo(({ children }: Props) => {
  const [toast, setToast] = useState<ReactNode | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const addToast = useCallback(
    (toast: ReactNode) => {
      clearTimeout(timer as NodeJS.Timeout);

      setToast(toast);

      const newTimer = setTimeout(() => {
        setToast(null);
      }, TOAST_DISMISS_TIMEOUT);

      setTimer(newTimer);
    },
    [timer]
  );

  const removeToast = useCallback(() => {
    clearTimeout(timer as NodeJS.Timeout);
    setToast(null);
  }, [timer]);

  const renderToast = () => {
    if (!toast) {
      return null;
    }

    return <ToastNotification toast={toast} />;
  };

  const memoizedContextValue = useMemo(
    () => ({
      addToast,
      removeToast,
    }),
    [toast]
  );

  return (
    <ToastContext.Provider value={memoizedContextValue}>
      {children}
      {renderToast()}
    </ToastContext.Provider>
  );
});

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('This component must be used within a <ToastProvider> component.');
  }

  return context;
};
