import { SettingsModel } from '@spotrisk/settings';

export type SettingsReducerState = {
  settings?: SettingsModel;
};

export const SET_SETTINGS = 'settings|SET_SETTINGS' as const;

type SetSettingsAction = {
  type: typeof SET_SETTINGS;
  settings: SettingsModel;
};

export type SettingsActionTypes = SetSettingsAction;
