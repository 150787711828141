import { CustomerModel } from '@spotrisk/customer';

export type CustomerReducerState = {
  customers: CustomerModel[];
  customer: CustomerModel | null;
};

export const SET_CUSTOMERS = 'customer|SET_CUSTOMERS' as const;
export const SET_CUSTOMER = 'customer|SET_CUSTOMER' as const;

type SetCustomersAction = {
  type: typeof SET_CUSTOMERS;
  customers: CustomerModel[];
};

type SetCustomerAction = {
  type: typeof SET_CUSTOMER;
  customer: CustomerModel | null;
};

export type CustomerActionTypes = SetCustomersAction | SetCustomerAction;
