import {
  MerchantIntegrationModel,
  MerchantIntegrationResponse,
  MerchantWebhookConfigurationModal,
  MerchantWebhookConfigurationResponse,
  SettingsModel,
  SettingsResponse,
} from '@spotrisk/settings';
import { parseISO } from 'date-fns';

class SettingsService {
  serialiseSettingsResponse = (settings: SettingsResponse): SettingsModel => ({
    id: settings.id,
    merchantId: settings.merchant_id,
    addressLine1: settings.address_line_1,
    addressLine2: settings.address_line_2,
    city: settings.city,
    country: settings.country,
    domain: settings.domain,
    latitude: settings.latitude,
    longitude: settings.longitude,
    postCode: settings.post_code,
    riskThreshold: settings.risk_threshold,
    blockyEnabled: settings.blocky_enabled,
    storeEmail: settings.store_email,
    digestSubscription: settings.digest_subscription,
    createdAt: parseISO(settings.created_at),
    updatedAt: parseISO(settings.updated_at),
    deletedAt: settings.deleted_at ? parseISO(settings.deleted_at) : undefined,
    orderCount: settings.order_count,
  });

  serialiseSettingsRequest = (settings: Partial<SettingsModel>): Partial<SettingsResponse> => ({
    address_line_1: settings.addressLine1 ?? undefined,
    address_line_2: settings.addressLine2 ?? undefined,
    city: settings.city ?? undefined,
    country: settings.country ?? undefined,
    latitude: settings.latitude ?? undefined,
    longitude: settings.longitude ?? undefined,
    post_code: settings.postCode ?? undefined,
    risk_threshold: settings.riskThreshold ?? undefined,
    digest_subscription: settings.digestSubscription ?? undefined,
    store_email: settings.storeEmail ?? undefined,
  });

  serialiseMerchantIntegrationsResponses = (integrations: MerchantIntegrationResponse): MerchantIntegrationModel => ({
    id: integrations.id,
    merchantId: integrations.merchant_id,
    tenantId: integrations.tenant_id,
    name: integrations.name,
    apiKey: integrations.api_key,
    createdAt: integrations.created_at,
    updatedAt: integrations.updated_at,
    deletedAt: integrations.deleted_at,
  });

  serialiseMerchantWebhookConfigurationResponses = (
    webhookConfigurations: MerchantWebhookConfigurationResponse
  ): MerchantWebhookConfigurationModal => ({
    id: webhookConfigurations.id,
    merchantId: webhookConfigurations.merchant_id,
    integrationId: webhookConfigurations.integration_id,
    url: webhookConfigurations.url,
    events: webhookConfigurations.events,
    enabled: webhookConfigurations.enabled,
    createdAt: webhookConfigurations.created_at,
    updatedAt: webhookConfigurations.updated_at,
    deletedAt: webhookConfigurations.deleted_at,
  });
}

export const settingsService = new SettingsService();
