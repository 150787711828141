import React, { useMemo } from 'react';

import { SideBarNavLink } from '@spotrisk/common';
import { SETTINGS_ROUTES } from '@spotrisk/settings';
import { useSelector } from 'react-redux';
import { billingSelectors } from '@spotrisk/billing';
import { merchantSelectors } from '@spotrisk/merchant';

export const SettingsMenu = () => {
  const billing = useSelector(billingSelectors.getBilling);
  const merchant = useSelector(merchantSelectors.getMerchant);

  const isConnected = useMemo(() => {
    if (!merchant) {
      return true;
    }

    if (merchant.platform === 'UNKNOWN') {
      return false;
    }

    return true;
  }, [billing, merchant]);

  return (
    <div>
      <div className="flex max-w-screen-xl" style={{ width: '165px' }}>
        <ul className="w-full">
          <li>
            <SideBarNavLink to={SETTINGS_ROUTES.root}>Customisations</SideBarNavLink>
          </li>
          <li className="mt-3">
            <SideBarNavLink to={SETTINGS_ROUTES.customRules}>Advanced Rules</SideBarNavLink>
          </li>
          <li className="mt-3">
            <SideBarNavLink to={SETTINGS_ROUTES.integration} isActive={!isConnected}>
              Integrations
            </SideBarNavLink>
          </li>
          <li className="mt-3">
            <SideBarNavLink to={SETTINGS_ROUTES.users}>Users</SideBarNavLink>
          </li>
          <li className="mt-3">
            <SideBarNavLink to={SETTINGS_ROUTES.notification}>Notifications</SideBarNavLink>
          </li>
          <li className="mt-3">
            <SideBarNavLink to={SETTINGS_ROUTES.plans} isActive={!isConnected}>
              Plans
            </SideBarNavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettingsMenu;
