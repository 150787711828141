import { Button, Hr, Input, Loader, Page, PageContent, spotriskAppUrl, Text } from '@spotrisk/common';
import React, { FormEvent, memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AUTH_ROUTES, authResource } from '@spotrisk/auth';

export const ResetPasswordPage = memo(() => {
  const { token } = useParams<{ token: string }>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState({ token: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const loginUrl = new URL(`${spotriskAppUrl}${AUTH_ROUTES.login}`);

  useEffect(() => {
    if (!token) {
      setError('Invalid token');
      return;
    }
    setFormData({ ...formData, token });
  }, [token]);

  if (isLoading) {
    return (
      <div className="flex min-h-screen justify-center items-center">
        <Loader />
      </div>
    );
  }

  const handleResetPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (!formData.password || !formData.token) {
        setIsLoading(false);
        setError('Please enter a valid email.');
        return;
      }

      await authResource.changePassword({ password: formData.password, token: formData.token });
      setIsLoading(false);
      setSuccess(true);
    } catch (error) {
      setIsLoading(false);
      setError('An unexpected error occurred. Contact support on support@spotriskhq.com');
    }
  };

  const handleLoginRedirect = () => {
    setIsLoading(true);
    window.location.href = loginUrl.href;
  };

  return (
    <Page>
      <PageContent isCentered>
        <div className="max-w-lg z-10">
          <div className="mb-4">
            <img className="w-20" src="https://cdn.spotriskhq.com/cdn/assets/public/img/logo-long.svg" alt="spotrisk-logo" />
          </div>
          <Hr />
          {success ? (
            <div className=" bg-white p-7 rounded-lg shadow-xl border-gray-300 text-center items-center justify-center">
              <div>
                <Text className="text-2xl font-header text-header">Password has been changed</Text>
              </div>
              <div className="mt-4">
                <Text className="text ml-2">
                  You have successfully change your password. You can now return to the sign in to sign in with your new password.
                </Text>
                <Hr />
                <div className="mt-8">
                  <Button className="text" state="text" variant="blue" onClick={handleLoginRedirect}>
                    Return to Sign in
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white p-7 rounded-lg shadow-xl border-gray-300">
              <div className="text-left mb-8">
                <div>
                  <Text className="text-2xl font-bold mt-8">Change your password</Text>
                </div>
                <div className="mt-2">
                  <Text className="text-sm font-thin break-words">
                    Enter your new password below, once update you will be redirected to the login page to sign in.
                  </Text>
                </div>
              </div>
              <form onSubmit={handleResetPassword}>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
                    New Password
                  </label>
                  <Input
                    id="password"
                    type="password"
                    autoComplete="new-password"
                    onChange={({ target }) => setFormData({ ...formData, password: target.value })}
                    value={formData.password}
                    className="h-12 shadow appearance-none border rounded-lg w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                {error && (
                  <div id="error-message" className="text-red text-center mb-5 mt-5">
                    <Text variant="danger">{error}</Text>
                  </div>
                )}
                <div className="text-center">
                  <Button type="submit" className="text-white w-full" variant="blue">
                    Continue
                  </Button>
                  <Hr />
                  <Button className="text-sm" state="text" variant="blue" onClick={handleLoginRedirect}>
                    Return to Sign in
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </PageContent>
      <footer className="fixed bottom-0 left-0 w-full h-10 flex items-center justify-center">
        <img className="w-full" src="https://cdn.spotriskhq.com/cdn/assets/public/img/testimonials-bottom.svg" alt="bottom-banner" />
      </footer>
    </Page>
  );
});
