import { Text, TextSkeleton } from '@spotrisk/common';
import { merchantSelectors } from '@spotrisk/merchant';
import { OrderListModel, OrderModel, orderResource } from '@spotrisk/order';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const OrderListHeading = memo(() => {
  const merchant = useSelector(merchantSelectors.getMerchant);
  const [isLoading, setIsLoading] = useState(true);
  const [flaggedOrders, setFlaggedOrders] = useState<OrderListModel[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (!merchant) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const flaggedOrders = await orderResource.listOrders({ merchant, state: 'FLAGGED' });

        setFlaggedOrders(flaggedOrders);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, [merchant]);

  return (
    <>
      <Text className="text-5xl font-header mt-2" variant="primary" isBlock>
        Spot sniffed out
      </Text>

      <Text className="text-5xl font-header mt-2" variant="danger" isBlock>
        {isLoading ? <TextSkeleton className="h-10 w-8" variant="danger" /> : flaggedOrders.length} suspicious orders
      </Text>

      <Text className="text-5xl font-header mt-2" variant="primary" isBlock>
        while you were away
      </Text>
    </>
  );
});
