import { apiService } from '@spotrisk/common';
import { ActivityLogRequest, ActivityLogs, ActivityResponse } from './blocky.model';
import { blockyService } from './blocky.service';

class BlockyResource {
  ActivityLog = async ({ merchantId }: ActivityLogRequest): Promise<ActivityLogs> => {
    const response = await apiService.fetch<ActivityResponse[]>(`/ecommerce/merchant/${merchantId}/blocky/activity`, {
      method: 'GET',
    });

    if (!response) {
      return { logs: [] };
    }

    return blockyService.serialiseActivityLogRequest(response);
  };

  ActivityLogBlockCount = async ({ merchantId }: ActivityLogRequest): Promise<number> => {
    const response = await apiService.fetch<ActivityResponse[]>(`/ecommerce/merchant/${merchantId}/blocky/activity`, {
      method: 'GET',
    });

    if (!response) {
      return 0;
    }

    return response.filter((log) => log.outcome === 'blocked').length;
  };
}

export const blockyResource = new BlockyResource();
