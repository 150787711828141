import { parseISO } from 'date-fns';
import { TenantModel, TenantResponse } from '@spotrisk/tenant';

class TenantService {
  serialiseTenant = (tenant: TenantResponse): TenantModel => ({
    id: tenant.id,
    type: tenant.type,
    source: tenant.source,
    createdAt: parseISO(tenant.created_at),
    updatedAt: parseISO(tenant.updated_at),
    deletedAt: tenant.deleted_at ? parseISO(tenant.deleted_at) : undefined,
  });
}

export const tenantService = new TenantService();
