import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { spotriskApiUrl, spotriskApiVersion } from './config.service';
import { localStorageService } from './local-storage.service';
import { isAxiosError } from '@spotrisk/util/axios';
import { authResource, authService } from '@spotrisk/auth';

const loginUrl = window.location.hostname.includes('local') ? 'http://localhost:3000/login' : 'https://app.spotriskhq.com/login';

export type FetchRequest = {
  path: string;
  config?: AxiosRequestConfig;
  apiVersion?: boolean;
};

class ApiService {
  private _client: AxiosInstance;

  constructor() {
    this._client = axios.create({
      baseURL: spotriskApiUrl,
    });
  }

  async fetch<T>(path: string, config: AxiosRequestConfig = {}, apiVersion = true) {
    try {
      const { data } = await this._client(apiVersion ? spotriskApiVersion + path : path, {
        ...config,
        method: config.method || 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorageService.getItem('spotrisk-access-token')}`,
        },
      });

      return data as T;
    } catch (error: any) {
      // If 401, logout
      if (isAxiosError(error) && error.response?.status === 401) {
        try {
          const refreshToken = localStorageService.getItem('spotrisk-refresh-token');
          if (refreshToken) {
            await authService.refresh({ refreshToken });
          }

          // try again
          const { data } = await this._client(apiVersion ? spotriskApiVersion + path : path, {
            ...config,
            method: config.method || 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorageService.getItem('spotrisk-access-token')}`,
            },
          });

          return data as T;
        } catch (error) {
          authService.logout();
          window.location.href = loginUrl;
          return;
        }
      }

      throw error;
    }
  }

  async fetchPublic<T>(path: string, config: AxiosRequestConfig = {}) {
    const { data } = await this._client(path, {
      ...config,
      method: config.method || 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data as T;
  }
}

export const apiService = new ApiService();
