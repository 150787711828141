import { ActivityLogs, ActivityResponse } from './blocky.model';

class BlockyService {
  serialiseActivityLogRequest = (logs: ActivityResponse[]): ActivityLogs => ({
    logs: logs.map((log: ActivityResponse) => ({
      outcome: log.outcome,
      ipAddress: log.ip_address,
      fingerprint: log.fingerprint,
      userAgent: log.user_agent,
      merchantId: log.merchant_id,
      action: log.action,
      timestamp: log.timestamp,
    })),
  });
}

export const blockyService = new BlockyService();
