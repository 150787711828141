import React, { memo } from 'react';
import { Page, PageContent } from '@spotrisk/common';
import { OrderList, OrderListFooter, OrderListHeading, OrderListSummary } from '@spotrisk/order';
import { useSelector } from 'react-redux';
import { merchantSelectors } from '@spotrisk/merchant';
import cn from 'classnames';

export const OrderListPage = memo(() => {
  const merchants = useSelector(merchantSelectors.getMerchants);

  const classNames = cn('min-h-screen', {
    'pt-48': merchants.length > 1,
  });

  return (
    <Page>
      <PageContent className={classNames}>
        <div className="flex max-w-screen-xl mx-auto mb-12">
          <div className="hidden md:block md:w-1/2 md:pr-16">
            <OrderListHeading />
            <OrderListSummary />
            <OrderListFooter />
          </div>
          <div className="w-full md:w-1/2">
            <OrderList />
          </div>
        </div>
      </PageContent>
    </Page>
  );
});
