import { OrderListModel, OrderModel, OrderState } from '@spotrisk/order';

export type OrderReducerStatus = 'ready' | 'loading' | 'saving' | 'error';
export type OrderReducerSort = 'latest' | 'oldest';

export type OrderReducerState = {
  order: OrderListModel | null;
  orders: OrderListModel[];
  search?: string;
  sort: OrderReducerSort;
  orderState?: OrderState;
  status: OrderReducerStatus;
};

export const GET_ORDERS = 'order|GET_ORDERS' as const;
export const SET_ORDER = 'order|SET_ORDER' as const;
export const SET_ORDERS = 'order|SET_ORDERS' as const;
export const REMOVE_ORDER = 'order|REMOVE_ORDER' as const;
export const SET_ORDER_SEARCH = 'order|SET_ORDER_SEARCH' as const;
export const SET_ORDER_SORT = 'order|SET_ORDER_SORT' as const;
export const SET_ORDER_STATE = 'order|SET_ORDER_STATE' as const;

type SetOrderAction = {
  type: typeof SET_ORDER;
  order: OrderListModel | null;
};

type SetOrdersAction = {
  type: typeof SET_ORDERS;
  orders: OrderListModel[];
};

type RemoveOrderAction = {
  type: typeof REMOVE_ORDER;
  id: string;
};

type GetOrders = {
  type: typeof GET_ORDERS;
};

type SetSearchAction = {
  type: typeof SET_ORDER_SEARCH;
  search?: string;
};

type SetSortAction = {
  type: typeof SET_ORDER_SORT;
  sort: OrderReducerSort;
};

type SetStateAction = {
  type: typeof SET_ORDER_STATE;
  orderState?: OrderState;
};

export type OrderActionTypes = SetOrderAction | SetOrdersAction | RemoveOrderAction | GetOrders | SetSearchAction | SetSortAction | SetStateAction;
