import { MerchantModel } from '@spotrisk/merchant';
import { MerchantActionTypes, SET_MERCHANT, SET_MERCHANTS } from './merchant.types';

class MerchantActions {
  setMerchants = (merchants: MerchantModel[]): MerchantActionTypes => ({
    type: SET_MERCHANTS,
    merchants,
  });

  setMerchant = (merchant: MerchantModel | null): MerchantActionTypes => ({
    type: SET_MERCHANT,
    merchant,
  });
}

export const merchantActions = new MerchantActions();
